import React from 'react';

interface Props {
  date: Date;
  addTime?: boolean;
}

export default class TimeText extends React.Component<Props> {
  render() {
    var newLocal = {
      hour: '2-digit',
      minute: '2-digit'
    };
    if (!this.props.date) return <span />;
    var dateFormatter = new Intl.DateTimeFormat('en-GB', newLocal);
    var date: any = new Date(this.props.date);
    date = dateFormatter.format(date);
    return <span className="date">{date}</span>;
  }
}
