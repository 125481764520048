import * as React from 'react';
import SiteWrapper from '../../components/SiteWrapper';
import { Page, Button, Dimmer, Alert, Header } from 'tabler-react';
import CampaignsList from './CampaignsList';
import gql from '../../../node_modules/graphql-tag';
import ApiService from '../../core/ApiService';
import { Campaign } from './CampaignTypes';
import { ReactRouterProps } from '../../components/DefaultTypes';

interface Props extends ReactRouterProps {}

type State = {
  campaigns: Array<any>,
  isLoading: boolean,
  error: string
};

const GET_CAMPAIGN = gql`
  {
    campaigns {
      query {
        items {
          id
          name
          size
          linked
          channel
          updateDate
        }
      }
    }
  }
`;

const DELETE_CAMPAIGN = gql`
  mutation campaignsDelete($campaignId: String!) {
    campaigns {
      delete(id: $campaignId) {
        id
      }
    }
  }
`;

class CampaignsPage extends React.Component<Props, State> {
  apiService: ApiService;
  state = {
    campaigns: [],
    isLoading: true,
    error: ''
  };
  queryResult: ZenObservable.Subscription;

  constructor(props) {
    super(props);
    this.apiService = new ApiService();
  }

  componentDidMount() {
    this.refreshData();
  }

  componentWillUnmount() {
    this.unSubscribeFromQuery();
  }

  unSubscribeFromQuery() {
    if (this.queryResult) {
      this.queryResult.unsubscribe();
      this.queryResult = null;
    }
  }

  refreshData() {
    this.unSubscribeFromQuery();
    this.queryResult = this.apiService.watchQuery(GET_CAMPAIGN).subscribe((response: any) => {
      if (response.errors) {
        this.setState({
          isLoading: false,
          error: 'Failed to read campaigns from service.'
        });
      } else {
        if (response.data) {
          this.setState({
            campaigns: response.data.campaigns.query.items,
            isLoading: false,
            error: ''
          });
        }
      }
    });
  }

  add() {
    this.props.history.push('/campaign/add');
  }

  update(campaign: Campaign) {
    this.props.history.push(`/campaign/${campaign.id}`);
  }

  remove(campaign: Campaign, callback: any) {
    this.apiService
      .mutate(DELETE_CAMPAIGN, { campaignId: campaign.id })
      .then(response => {
        this.refreshData();
        callback();
      })
      .catch(response =>
        this.setState({
          error: `Failed to remove campaign '${campaign.name}' from service.`
        })
      );
  }

  render() {
    var { campaigns } = this.state;
    return (
      <SiteWrapper>
        <Page.Content>
          <Header.H1>
            Campaigns
            <span style={{ marginLeft: '15px' }}>
              {' '}
              <Button onClick={() => this.add()} color="secondary" icon="plus" />
            </span>
          </Header.H1>
          {this.state.isLoading ? (
            <Dimmer active loader />
          ) : (
            <CampaignsList
              campaigns={campaigns}
              update={(m, c) => this.update(m)}
              remove={(m, c) => this.remove(m, c)}
            />
          )}
          {this.state.error && <Alert type="danger">{this.state.error}</Alert>}
        </Page.Content>
      </SiteWrapper>
    );
  }
}

export default CampaignsPage;
