import AppSettings from './AppSettings';
import ApiService from './ApiService';
import DeviceStorage from 'react-device-storage';
import gql from 'graphql-tag';
import Oidc from 'oidc-client';

export class Token {
  access_token: string;
  email: string;
  expires: Date;
  id: string;
  name: string;
  roles: string[];
  activities: string[];

  isValid() {
    const expDate = new Date(this.expires);
    let loggedIn = this.access_token ? expDate > new Date() : false;
    return loggedIn;
  }
  hasAccess(activity: string) {
    const isAllowed = this.activities.some(x => x === activity);
    return isAllowed;
  }
}

export const loadToken = (): Token => {
  return Object.assign(new Token(), storage.read('authServiceToken')) || new Token();
};

const storage = new DeviceStorage({
  cookieFallback: true,
  cookie: {
    secure: true
  }
}).localStorage();

const GET_USER_ME = gql`
  {
    users {
      me {
        name
        email
        roles
        id
        activities
      }
    }
  }
`;
var userManager: Oidc.UserManager = null;

function getSingleInstanceOfUserManager() {
  if (userManager == null) {
    let url = AppSettings.ApiEndPoint();
    let appUrl = new URL(window.location.href).origin;
    userManager = new Oidc.UserManager({
      redirect_uri: `${appUrl}/callback`,
      metadataUrl: `${url}/.well-known/openid-configuration`,

      authority: url,
      client_id: 'kin.api.admin',
      response_type: 'id_token token',
      scope: 'openid email profile api',
      post_logout_redirect_uri: `${appUrl}/logout`,
      loadUserInfo: true,
      automaticSilentRenew: true,
      revokeAccessTokenOnSignout: true
    });
  }
  return userManager;
}

export default class AuthService {
  url: string = '';
  urlToken: string = '';
  config: any;
  token: Token = new Token();
  storage: DeviceStorage;
  apiService: ApiService;
  userManager: Oidc.UserManager;
  appUrl: string;

  constructor() {
    this.url = AppSettings.ApiEndPoint();
    this.urlToken = AppSettings.ApiEndPoint() + '/connect/token';
    this.storage = storage;
    this.apiService = new ApiService();
    this.token = loadToken();
    this.appUrl = new URL(window.location.href).origin;
    this.userManager = getSingleInstanceOfUserManager();
  }

  isLoggedIn() {
    return this.token.isValid();
  }

  currentToken(): Token {
    return this.token;
  }

  loginCallback(onSuccessFn: () => void, onFailureFn: (exc: any) => void) {
    this.userManager
      .getUser()
      .then(user => {
        if (!user) return this.userManager.signinRedirectCallback(null).then(() => this.userManager.getUser());

        return Promise.resolve(user);
      })
      .then(user => {
        const token = new Token();
        token.access_token = user.access_token;
        token.expires = new Date(new Date().getTime() + user.expires_at * 1000);

        this.storeToken(token);

        const userQueryPromise = this.apiService.query(GET_USER_ME);
        const userTokenPromise = Promise.resolve(user);
        const saveUserPromise = this.userManager.storeUser(user);

        return Promise.all([userTokenPromise, userQueryPromise, saveUserPromise]);
      })
      .then(result => {
        const tokenResult = result[0];
        const userQueryResult = result[1];

        if (userQueryResult.errors) {
          throw Error('Failed to authenticate against the Kin API');
        }

        const me = userQueryResult.data.users.me;

        const token = new Token();

        token.access_token = tokenResult.access_token;
        token.expires = new Date(new Date().getTime() + tokenResult.expires_in * 1000);

        token.name = me.name;
        token.email = me.email;
        token.roles = me.roles;
        token.activities = me.activities;
        token.id = me.id;

        this.storeToken(token);

        onSuccessFn();
      })
      .catch(exc => {
        onFailureFn(exc);
      });
  }

  loginRedirect() {
    this.userManager.signinRedirect();
  }

  logout() {
    this.userManager.getUser().then(user => {
      console.log('logging out');
      this.clearToken();

      if (user) this.userManager.signoutRedirect({ id_token_hint: user.id_token });
      else document.location.href = '/logout';
    });
  }

  clearToken() {
    const token = new Token();
    token.access_token = null;
    token.expires = new Date();
    token.name = '';
    token.email = '';
    token.roles = [];
    token.activities = [];
    token.id = '';
    this.storeToken(token);
  }

  storeToken(token: any) {
    this.token = token;
    this.storage.save('authServiceToken', this.token);
  }
}
