import React from 'react';
import { Card, Text, Table, Form, Button, Icon, Dropdown } from 'tabler-react';
import AmountText from '../../components/AmountText';
import TimeText from '../../components/TimeText';
import ShortId from '../../components/ShortId';
import DateText from '../../components/DateText';
import UserAvatar, { UserAvatarFormat } from '../../components/UserAvatar';

interface Props {
  transactionInAppSettlements: Array<any>;
  toggle(transactionInAppSettlement: any): void;
  refreshOne(transactionInAppSettlement: any): void;
  pay(): void;
  requestRefund(transactionInAppSettlement: any): void;
}

export default class TransactionInAppSettlementsList extends React.Component<Props> {
  render() {
    const { transactionInAppSettlements } = this.props;
    var totalCents = transactionInAppSettlements.length
      ? transactionInAppSettlements
          .filter(x => x.isInBatch || x.isInRefundBatch)
          .reduce((x, settle) => x + settle.amount.cents, 0)
      : 0;
    var total = { cents: totalCents, currencyIso: 'ZAR' };

    const payVisible = transactionInAppSettlements.some(x => x.isInBatch);
    return (
      <Card>
        <Table responsive highlightRowOnHover hasOutline verticalAlign="center" cards className="text-nowrap">
          <Table.Header>
            <Table.Row>
              <Table.ColHeader className="w-1">Id</Table.ColHeader>
              <Table.ColHeader>State</Table.ColHeader>
              <Table.ColHeader>Date</Table.ColHeader>
              <Table.ColHeader>From-To</Table.ColHeader>
              <Table.ColHeader>Reference</Table.ColHeader>
              <Table.ColHeader>Sort Code</Table.ColHeader>
              <Table.ColHeader>Account</Table.ColHeader>
              <Table.ColHeader>Amount</Table.ColHeader>
              <Table.ColHeader>BatchId</Table.ColHeader>
              <Table.ColHeader />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {transactionInAppSettlements.map(transactionInAppSettlement => (
              <React.Fragment key={transactionInAppSettlement.id}>
                <Table.Row>
                  <Table.Col>
                    <Text.Small muted>
                      <ShortId id={transactionInAppSettlement.id} />
                    </Text.Small>
                  </Table.Col>

                  <Table.Col>
                    <span title={transactionInAppSettlement.status.description}>
                      {transactionInAppSettlement.status.state === 'SUCCESS' && (
                        <Icon
                          name="check"
                          className="text-green"
                          title={
                            transactionInAppSettlement.status.state +
                            ':' +
                            transactionInAppSettlement.status.description
                          }
                        />
                      )}
                      {transactionInAppSettlement.status.state === 'FAILED' && (
                        <Icon
                          name="alert-triangle"
                          className="text-red"
                          title={
                            transactionInAppSettlement.status.state +
                            ':' +
                            transactionInAppSettlement.status.description
                          }
                        />
                      )}
                      {transactionInAppSettlement.status.state === 'REFUNDED' && (
                        <Icon
                          name="rotate-ccw"
                          className="text-green"
                          title={
                            transactionInAppSettlement.status.state +
                            ':' +
                            transactionInAppSettlement.status.description
                          }
                        />
                      )}
                      {transactionInAppSettlement.status.state !== 'SUCCESS' &&
                        transactionInAppSettlement.status.state !== 'FAILED' &&
                        transactionInAppSettlement.status.state !== 'REFUNDED' && (
                          <span>
                            <Button
                              icon="clock"
                              onClick={() => this.props.refreshOne(transactionInAppSettlement)}
                              color="warning"
                              title="refresh"
                            />
                          </span>
                        )}
                    </span>
                    <ShortId id={transactionInAppSettlement.ppReferenceId} />
                  </Table.Col>
                  <Table.Col>
                    <div>
                      <DateText date={transactionInAppSettlement.createDate} />
                    </div>
                    <Text.Small muted>
                      <TimeText date={transactionInAppSettlement.createDate} />
                    </Text.Small>
                  </Table.Col>
                  <Table.Col>
                    <UserAvatar size="sm" format={UserAvatarFormat.Name} user={transactionInAppSettlement.payor} />
                    <Text.Small muted> pays </Text.Small>
                    <UserAvatar size="sm" format={UserAvatarFormat.Name} user={transactionInAppSettlement.payee} />
                  </Table.Col>
                  <Table.Col>
                    <Text>
                      <Text.Small muted>Theirs: </Text.Small>
                      {transactionInAppSettlement.eftSent.theirReference}
                    </Text>
                    <Text>
                      <Text.Small muted>Ours: </Text.Small>
                      {transactionInAppSettlement.eftSent.ourReference}
                    </Text>
                  </Table.Col>
                  <Table.Col>
                    <Text>{transactionInAppSettlement.bankDetail.sortCode}</Text>
                    <Text.Small muted>{transactionInAppSettlement.bankDetail.sortCodeDetail.description}</Text.Small>
                  </Table.Col>
                  <Table.Col>
                    <Text> {transactionInAppSettlement.bankDetail.accountNumber}</Text>
                    <Text.Small muted>{transactionInAppSettlement.bankDetail.accountName}</Text.Small>
                  </Table.Col>
                  <Table.Col alignContent="right">
                    <AmountText amount={transactionInAppSettlement.amount} />
                  </Table.Col>
                  <Table.Col>
                    {transactionInAppSettlement.eftSent.batchId}
                    {transactionInAppSettlement.isAllowedInBatch && (
                      <Form.Checkbox
                        label="Pay"
                        name={`pay-${transactionInAppSettlement.id}`}
                        checked={transactionInAppSettlement.isInBatch}
                        onChange={() => this.props.toggle(transactionInAppSettlement)}
                        value="yes"
                      />
                    )}
                  </Table.Col>
                  <Table.Col>
                    {transactionInAppSettlement.isAllowedInRefundBatch && (
                      <Dropdown
                        toggle={false}
                        arrow
                        icon="more-vertical"
                        triggerContent=""
                        itemsObject={[
                          {
                            value: 'Refund',
                            onClick: () => this.props.requestRefund(transactionInAppSettlement)
                          }
                        ]}
                      />
                    )}
                  </Table.Col>
                </Table.Row>
              </React.Fragment>
            ))}
            {total.cents !== 0 && (
              <Table.Row>
                <Table.Col />
                <Table.Col />
                <Table.Col />
                <Table.Col />
                <Table.Col />
                <Table.Col />
                <Table.Col />
                <Table.Col alignContent="right">
                  <AmountText amount={total} />
                </Table.Col>
                <Table.Col>
                  {payVisible && (
                    <Button icon="link" color="success" className="settlement-button" onClick={() => this.props.pay()}>
                      Pay
                    </Button>
                  )}
                </Table.Col>
                <Table.Col />
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Card>
    );
  }
}
