import { Amount } from '../../components/AmountText';

class MemberToMemberBalance {
  fromMemberId: string;
  toMemberId: string;
  amount: Amount;
}

class MemberSummary {
  balance: Amount;
  totalSpent: Amount;
  totalOwe: Amount;
  totalIAmOwed: Amount;
}

export class GroupMembers {
  id: string;
  name: string;
  userId: string;
  status: string;
  image: string;
  hasReceivingAccount: boolean;
  summary: MemberSummary;
}

class Summary {
  totalEstimate: Amount;
  totalSpent: Amount;
}

export class Group {
  id: string;
  name: string;
  purpose: string;
  image: string;
  updateDate: string;
  hasTransactions: boolean;
  hasSimplifiedBalances: boolean;
  summary: Summary;
  memberToMemberBalance: MemberToMemberBalance[];
  members: GroupMembers[];
}

export class GroupCreateUpdate {
  code: string;
  description: string;
}

export class Portions {
  memberId: string;
  size: number;
  amount: Amount;
  responsible: Amount;
}

export class Split {
  type: string;
  portions: Portions[];
}

export class Transactions {
  id: string;
  date: string;
  type: string;
  description: string;
  isInAppSettlement: boolean;
  createdByUser :  {
    id : string
    name : string
  }
  amount: Amount;
  plannedAmount: string;
  responsibleMemberId: string;
  updateDate: string;
  split: Split;
}
