import * as React from 'react';
import AuthService from '../../core/AuthService';
import { Dimmer } from 'tabler-react';

interface Props {}

interface State {
  message: string;
}

class CallbackPage extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      message: 'Loading...'
    };
  }

  processLoginCallback() {
    new AuthService().loginCallback(
      () => (window.location.href = '/dashboard'),
      exc => {
        console.log(exc);
        window.location.href = '/login?logout=1&error=1';
      }
    );
  }

  componentDidMount() {
    this.processLoginCallback();
  }

  render() {
    return (
      <div className="centered">
        <Dimmer active loader />
      </div>
    );
  }
}

export default CallbackPage;
