
class Currency {
  country: string;
  symbol: string;
  isoCode: string;
  constructor(isoCode:string,symbol:string,country:string) {
    this.isoCode = isoCode;
    this.symbol = symbol;
    this.country = country;
  }
  
  
}

export default class CurrencyService {
  

  private static instance: CurrencyService;
  Zar: Currency;
  All: Currency[];

  constructor() {
    this.All = [
      this.Zar = new Currency("ZAR", "R", "Rand"),
      new Currency("EUR", "€", "Euro"),
      new Currency("GBP", "£", "Pound Sterling"),
      new Currency("USD", "$", "US Dollar"),
      new Currency("CAD", "$", "Canadian Dollar"),
      new Currency("INR", "₹", "Indian Rupee")
    ];
  }
  

  public static getInstance(): CurrencyService {
      if (!CurrencyService.instance) {
          CurrencyService.instance = new CurrencyService();
      }
      return CurrencyService.instance;
  }

  getSymbol(currencyIso: string) {
    return this.All.filter(x=>x.isoCode === currencyIso.toUpperCase())[0].symbol;
  }
}
