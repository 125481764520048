import * as React from 'react';
import { Card, Grid, Text } from 'tabler-react';
import AmountText from '../../components/AmountText';
import UserAvatar, { UserAvatarFormat } from '../../components/UserAvatar';

interface Props {
  member: any;
}

interface State {}

export default class GroupMemberCard extends React.Component<Props, State> {
  state = {};

  render() {
    var { member } = this.props;
    return (
      <Card>
        <Card.Body>
          <ul className="list-unstyled list-separated">
            <li className="list-separated-item">
              <Grid.Row className="align-items-center">
                <Grid.Col auto>
                  <UserAvatar member={member} format={UserAvatarFormat.ImageOnly} />
                </Grid.Col>
                <Grid.Col>
                  <div>{member.name}</div>
                  <Text.Small muted className="d-block item-except h-1x">
                    {member.status}
                    {!member.hasReceivingAccount || (
                      <i
                        className="fe fe-check m-l-xs"
                        style={{ marginLeft: '10px', color: 'green' }}
                        title="Has bank account setup"
                      />
                    )}
                  </Text.Small>
                </Grid.Col>

                <Grid.Col>
                  {member.summary.totalIAmOwed.cents ? (
                    <Text.Small muted className="d-block item-except h-1x">
                      I am owed {member.summary.totalIAmOwed.formatted}
                    </Text.Small>
                  ) : (
                    <></>
                  )}

                  <Text.Small muted className="d-block item-except h-1x">
                    I owe {member.summary.totalOwe.formatted}
                  </Text.Small>
                  <Text.Small muted className="d-block item-except h-1x">
                    Spent {member.summary.totalSpent.formatted}
                  </Text.Small>
                </Grid.Col>
                <Grid.Col>
                  <AmountText amount={member.summary.balance} />
                  <Text.Small muted className="d-block item-except h-1x">
                    Balance
                  </Text.Small>
                </Grid.Col>
              </Grid.Row>
            </li>
          </ul>
        </Card.Body>
      </Card>
    );
  }
}
