import * as React from 'react';
import SiteWrapper from '../../components/SiteWrapper';
import { Page, Card, Dimmer, Alert, Header, Grid, Table, Text, Button } from 'tabler-react';
import gql from '../../../node_modules/graphql-tag';
import ApiService from '../../core/ApiService';
import { ReactRouterProps } from '../../components/DefaultTypes';
import { Amount } from '../../components/AmountText';
import { Route } from 'react-router-dom';
import ShortId from '../../components/ShortId';
import truncateWithEllipses from '../../components/TruncateWithEllipses';
import TimeAgo from 'react-timeago';

interface Props extends ReactRouterProps {}

interface State {
  users: UserBlocked[];
  isLoading: boolean;
  error: string;
}

const GET_USER = gql`
  {
    users {
      blockedFromInAppPayments {
        id
        email
        name
        inAppPayments {
          totalPaymentsThisMonth {
            cents
            currencyIso
          }
          totalPaymentsToday {
            cents
            currencyIso
          }
          isBlocked
          blockedReason
          blockedDate
        }
      }
    }
  }
`;

const UNBLOCK_USER = gql`
  mutation($userId: String!) {
    users {
      unblockInAppPayments(userId: $userId) {
        id
      }
    }
  }
`;

class UserBlocked {
  id: string;
  name: string;
  email: string;
  isRemoving: boolean;

  inAppPayments: {
    totalPaymentsThisMonth: Amount,
    totalPaymentsToday: Amount,
    isBlocked: boolean,
    blockedReason: string,
    blockedDate: string
  };
}

class UsersBlockedPage extends React.Component<Props, State> {
  apiService: ApiService;
  state = {
    users: [],
    isLoading: true,
    error: ''
  };
  queryResult: ZenObservable.Subscription;

  constructor(props) {
    super(props);
    this.apiService = new ApiService();
  }

  componentDidMount() {
    this.refreshData();
  }

  componentWillUnmount() {
    this.unSubscribeFromQuery();
  }

  unSubscribeFromQuery() {
    if (this.queryResult) {
      this.queryResult.unsubscribe();
      this.queryResult = null;
    }
  }
  refreshData() {
    this.unSubscribeFromQuery();
    this.queryResult = this.apiService.watchQuery(GET_USER).subscribe((response: any) => {
      if (response.errors) {
        this.setState({
          isLoading: false,
          error: 'Failed to read sortCodes from service.'
        });
      } else {
        if (response.data) {
          this.setState({
            users: response.data.users.blockedFromInAppPayments,
            isLoading: false,
            error: ''
          });
        }
      }
    });
  }

  unblock(user: UserBlocked) {
    user.isRemoving = true;

    this.setState({ users: this.state.users });
    this.apiService
      .mutate(UNBLOCK_USER, { userId: user.id })
      .then(_ => {
        this.setState({ users: this.state.users });
        this.refreshData();
      })
      .catch(_ => {
        this.setState({
          users: this.state.users,
          error: `Failed to remove user '${user.name}' from service.`
        });
      });
  }

  renderUsers(users: UserBlocked[]) {
    return (
      <Grid.Row cards deck>
        <Grid.Col width={12}>
          <Card>
            <Table responsive highlightRowOnHover hasOutline verticalAlign="center" cards className="text-nowrap">
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader className="w-1">Id</Table.ColHeader>
                  <Table.ColHeader>Name</Table.ColHeader>
                  <Table.ColHeader>Email</Table.ColHeader>
                  <Table.ColHeader>Blocked</Table.ColHeader>
                  <Table.ColHeader>Date</Table.ColHeader>
                  <Table.ColHeader alignContent="center" className="w-1" />
                </Table.Row>
              </Table.Header>
              <Route
                render={({ history }) => (
                  <Table.Body>
                    {users.map(user => (
                      <Table.Row key={user.id}>
                        <Table.Col>
                          <Text.Small muted>
                            <ShortId id={user.id} />
                          </Text.Small>
                        </Table.Col>
                        <Table.Col>
                          {' '}
                          <a href={`/user/${user.id}`}> {truncateWithEllipses(user.name, 25)}</a>
                        </Table.Col>
                        <Table.Col>{truncateWithEllipses(user.email, 30)}</Table.Col>
                        <Table.Col>{user.inAppPayments.blockedReason}</Table.Col>
                        <Table.Col>
                          <TimeAgo date={user.inAppPayments.blockedDate} />
                        </Table.Col>
                        <Table.Col>
                          <Button
                            color="danger"
                            icon="unlock"
                            onClick={x => this.unblock(user)}
                            loading={user.isRemoving}
                          ></Button>
                        </Table.Col>
                      </Table.Row>
                    ))}
                  </Table.Body>
                )}
              />
            </Table>
          </Card>
        </Grid.Col>
      </Grid.Row>
    );
  }

  render() {
    var { users } = this.state;
    return (
      <SiteWrapper>
        <Page.Content>
          <Header.H1>Users blocked from in app payments</Header.H1>
          {this.state.isLoading ? <Dimmer active loader /> : this.renderUsers(users)}
          {this.state.error && <Alert type="danger">{this.state.error}</Alert>}
        </Page.Content>
      </SiteWrapper>
    );
  }
}

export default UsersBlockedPage;
