export interface Action {
  type: string;
  payload?: any;
}

export interface Reducer<TState> {
  reduce(action, state: TState): TState;
}

export default class ViewStateHandler<TState> {
  _updateView: (state: TState, callback: any) => void;
  _reducer: Reducer<TState>;
  _state: TState;
  _shouldLog: boolean;

  constructor(component: React.Component<any, TState>, reducer: Reducer<TState>, shouldLogActions: boolean = false) {
    this._updateView = (state: TState, callback: any) => component.setState(state, callback);
    this._state = component.state;
    this._reducer = reducer;
    this._shouldLog = shouldLogActions;
  }

  dispatch(type: string, payload?: any) {
    const previousState = this._state;

    this._state = this._reducer.reduce({ type: type, payload: payload }, this._state);
    this.store(this._state, () => this.log({ type, payload, previousState, newState: this._state }));
  }

  store(newState: TState, callback?: any) {
    this._updateView(newState, callback);
  }

  log(data: any) {
    if (this._shouldLog) {
      console.log(data);
    }
  }
}
