import * as React from 'react';
import SiteWrapper from '../../components/SiteWrapper';
import { Page, Dimmer, Alert, Header, Table, Card } from 'tabler-react';
import ApiService from '../../core/ApiService';
import { ReactRouterProps } from '../../components/DefaultTypes';
import { getReportByName } from './ReportsPage';

interface Props extends ReactRouterProps {}

interface State {
  isLoading: boolean;
  error: string;
  name: string;
  data: TableData;
}

export interface TableData {
  associatedReportName: string;
  completedDate: Date;
  columns: string[];
  rows: string[][];
}

export default class ReportTablePage extends React.Component<Props, State> {
  state = {
    isLoading: true,
    name: '',
    error: undefined,
    data: null
  };
  componentDidMount() {
    this.setState({ isLoading: true, name: this.props.match.params.name });

    getReportByName(new ApiService(), this.props.match.params.name)
      .then(result => {
        this.setState({
          data: result.data.reports.data,
          isLoading: false
        });
      })
      .catch(exc => {
        this.setState({ isLoading: false, error: 'Failed to download' });
      });
  }
  renderReport() {
    var { columns, rows } = this.state.data;
    return (
      <Card>
        <Table responsive highlightRowOnHover hasOutline verticalAlign="center" cards className="text-nowrap">
          <Table.Header>
            <Table.Row>
              {columns.map(col => (
                <Table.ColHeader key={col}>{col}</Table.ColHeader>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {rows.map((row, i) => (
              <Table.Row key={i}>
                {row.map((col, i) => (
                  <Table.Col key={i}>{col}</Table.Col>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card>
    );
  }
  render() {
    return (
      <SiteWrapper>
        <Page.Content>
          <Header.H1>{this.state.name}</Header.H1>
          {this.state.isLoading ? <Dimmer active loader /> : this.renderReport()}
          {this.state.error && <Alert type="danger">{this.state.error}</Alert>}
        </Page.Content>
      </SiteWrapper>
    );
  }
}
