import React from 'react';
import { Form, Card, Button } from 'tabler-react';

interface Props {
  visible: boolean;
  children: any;
  title: string;
  onCloseClick: (evt: any) => any;
  onConfirmClick?: (evt: any) => any;
  ConfirmText?: string;
  CancelText?: string;
  isEnabled?: boolean;
}

const Dialog = ({
  visible,
  children,
  title,
  onCloseClick,
  onConfirmClick,
  ConfirmText = 'Ok',
  CancelText = 'Cancel',
  isEnabled = true
}: Props) => {
  return (
    visible && (
      <div className="dialog">
        <div>
          <Card>
            <Card.Header>
              <Card.Title>{title}</Card.Title>
              <Card.Options>
                <div className="hoverable">
                  <Card.OptionsItem type="close" onClick={evt => isEnabled && onCloseClick(evt)} />
                </div>
              </Card.Options>
            </Card.Header>
            <Card.Body>{children}</Card.Body>
            <Card.Footer>
              <div className="right-align-items">
                {onConfirmClick && (
                  <Button
                    color="success"
                    className="settlement-button"
                    onClick={evt => onConfirmClick(evt)}
                    disabled={!isEnabled}
                  >
                    {ConfirmText}
                  </Button>
                )}

                <Button
                  color="error"
                  className="settlement-button"
                  onClick={evt => onCloseClick(evt)}
                  disabled={!isEnabled}
                >
                  {CancelText}
                </Button>
              </div>
            </Card.Footer>
          </Card>
        </div>
      </div>
    )
  );
};

export function StandardFormFooter(isSubmitting: boolean, onCancel) {
  return (
    <Form.Footer>
      <Button.List>
        <Button
          type="button"
          onClick={e => {
            e.preventDefault();
            return onCancel(e);
          }}
          outline
          color="secondary"
        >
          Cancel
        </Button>
        <Button type="submit" color="primary" loading={isSubmitting} disabled={isSubmitting}>
          Save
        </Button>
      </Button.List>
    </Form.Footer>
  );
}

export default Dialog;
