import * as React from 'react';
import cn from 'classnames';

import { Card, Text, Header } from 'tabler-react';

import C3Chart from 'react-c3js';

interface Props {
  className?: string;
  total: string;
  label: string;
  color: string;
  data?: Array<any>;
  columns?: Array<any>;
}

export default class SimpleStatsCard extends React.Component<Props> {
  render() {
    const classes = cn(this.props.className);
    const column = ['data1', ...this.props.data];
    const chart = (
      <C3Chart
        style={{ height: '100%' }}
        padding={{
          bottom: -10,
          left: -1,
          right: -1
        }}
        data={{
          names: {
            data1: this.props.label
          },
          columns: [column],
          type: 'area'
        }}
        legend={{
          show: false
        }}
        transition={{
          duration: 12
        }}
        point={{
          show: true
        }}
        tooltip={{
          format: {
            title: x => {
              return this.props.columns[x];
            }
          }
        }}
        axis={{
          y: {
            padding: {
              bottom: 0
            },
            show: false,
            tick: {
              outer: false
            }
          },
          x: {
            padding: {
              left: 0,
              right: 0
            },
            show: false
          }
        }}
        color={{
          pattern: [this.props.color]
        }}
      />
    );
    return (
      <Card className={classes}>
        <Card.Body>
          <Text muted>{this.props.label}</Text>
          <Header.H3 className="mt-1">{this.props.total}</Header.H3>
        </Card.Body>
        {chart && <div className="card-chart-bg">{chart}</div>}
      </Card>
    );
  }
}
