import * as React from 'react';
import { Page, Grid, Dimmer, StampCard, Alert, StatsCard, Text } from 'tabler-react';
import gql from '../../../node_modules/graphql-tag';
import SiteWrapper from '../../components/SiteWrapper';
import ApiService from '../../core/ApiService';
import AuthService from '../../core/AuthService';
import { Token } from '../../core/AuthService';
import SimpleStatsCard from '../../components/SimpleStatsCard';
import shortNumberFormat from '../../components/ShortNumberFormat';
import percentChange from '../../components/PercentChange';

interface Props {}

interface State {
  isLoading: boolean;
  error: string;
  sortCodesCount: number;
  transactionInAppSettlementsCount: number;
  groupsCount: number;
  peachPaymentCallLogsCount: number;
  usersCount: number;
  transactionInAppSettlementsToBeProcessedCount: number;
  usersExternalCount: number;
  stats: Array<any>;
  summary: Summary;
}

export class Summary {
  lastMonth: Report;
  monthBeforeTo: Report;
}

export class Report {
  dashboardSummary: DashboardSummary;
}

export class DashboardSummary {
  groupsCreated: number;
  membersPerGroup: number;
  usersPerGroup: number;
  valuePaid: number;
  valuePlanned: number;
  valueInAppSettlements: number;
  valueExternalSettlements: number;
}

const GET_COUNTS_USER = `
  users  { 
    query { count }
    notInternal : query (excludeSegment:"Internal") { count }
    createStats(from:$from, to: $to) { ...oneDimensionData }
    invitesCreatedStats(from:$from, to: $to) { ...oneDimensionData }
    invitesUsedStats(from:$from, to: $to) { ...oneDimensionData }
  }
`;

const GET_COUNTS_GROUPS = `
  groups { query { count } }
`;

const GET_COUNTS_TRANSACTIONINAPPSETTLEMENTS = `
  transactionInAppSettlements { query { count } 
   toBeProcessed: query(hasNoBatchId:true, state:"Success") { count}
  }
`;

const GET_COUNTS_SORTCODES = `
  sortCodes { query { count } }
`;
const GET_COUNTS_PEACHPAYMENTLOGS = `
  peachPaymentCallLogs { query { count } }
`;
const GET_COUNTS_TRANSACTIONRECORDS = `
  transactionRecords { 
    transactionsStats (from:$from, to: $to) { ...oneDimensionData } 
    inAppSettlementsStats (from:$from, to: $to) { ...oneDimensionData } 
    externalSettlementsStats (from:$from, to: $to) { ...oneDimensionData } 
    }
`;
const GET_DASHBOARD_SUMMARY = `

  lastMonth : reports {
    dashboardSummary(filterFrom: $lastMonthFrom, filterTo: $lastMonthTo) { ...dashboardSummary   }
  }
  
  monthBeforeTo : reports {
    dashboardSummary(filterFrom: $monthBeforeFrom, filterTo: $monthBeforeTo) { ...dashboardSummary   }
  }
`;

const GET_DASHBOARD_SUMMARY_FRAGMENT = `fragment dashboardSummary on DashboardSummary {
  groupsCreated
  usersJoined
  valuePaid
  valuePlanned
  valueInAppSettlements
  valueExternalSettlements
  membersPerGroup
  usersPerGroup
}`;

const ONEDIMENSIONDATA_FRAGMENT = `fragment oneDimensionData on OneDimensionData {
  label
  total
  data
  columns
}`;

const CHART_BG_COLORS = ['#eb7474', '#e98f70', '#e7cf6c', '#7ee268', '#64b5db', '#c25fd1'];

class DashboardPage extends React.Component<Props, State> {
  apiService: ApiService;
  state = {
    sortCodesCount: 0,
    transactionInAppSettlementsCount: 0,
    groupsCount: 0,
    peachPaymentCallLogsCount: 0,
    usersCount: 0,
    isLoading: true,
    stats: [],
    error: '',
    transactionInAppSettlementsToBeProcessedCount: 0,
    usersExternalCount: 0,
    summary: new Summary()
  };
  token: Token;

  constructor(props) {
    super(props);
    this.apiService = new ApiService();
    const authService = new AuthService();
    this.token = authService.currentToken();
  }

  componentDidMount() {
    this.refreshData();
  }

  dateOffSet(add = 0) {
    var d = new Date();
    d.setDate(d.getDate() + add);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    return d;
  }

  refreshData() {
    const GET_COUNTS = gql`
    ${this.token.hasAccess('DashboardReports') ? GET_DASHBOARD_SUMMARY_FRAGMENT : ''}
    ${ONEDIMENSIONDATA_FRAGMENT}
    query ($from: Date!, $to: Date!,$lastMonthFrom: DateTime!, $lastMonthTo: DateTime!,$monthBeforeFrom: DateTime!, $monthBeforeTo: DateTime!) {
        
        ${this.token.hasAccess('ReadGroup') ? GET_COUNTS_GROUPS : ''}
        ${this.token.hasAccess('ReadTransactionInAppSettlement') ? GET_COUNTS_TRANSACTIONINAPPSETTLEMENTS : ''}
        ${this.token.hasAccess('ReadSortCode') ? GET_COUNTS_SORTCODES : ''}
        ${this.token.hasAccess('ReadUsers') ? GET_COUNTS_USER : ''}
        ${this.token.hasAccess('ReadPeachPaymentCallLog') ? GET_COUNTS_PEACHPAYMENTLOGS : ''}
        ${this.token.hasAccess('ReadTransactionRecord') ? GET_COUNTS_TRANSACTIONRECORDS : ''}
        ${this.token.hasAccess('DashboardReports') ? GET_DASHBOARD_SUMMARY : ''}
        
        
      }`;
    this.apiService
      .query(GET_COUNTS, {
        from: this.dateOffSet(-7),
        to: this.dateOffSet(0),
        lastMonthFrom: this.dateOffSet(-30),
        lastMonthTo: this.dateOffSet(0),
        monthBeforeFrom: this.dateOffSet(-60),
        monthBeforeTo: this.dateOffSet(-31)
      })
      .then(response => {
        const allStats = [];

        if (response.data.users) {
          allStats.push(response.data.users.createStats);
          allStats.push(response.data.users.invitesCreatedStats);
          allStats.push(response.data.users.invitesUsedStats);
        }

        if (response.data.transactionRecords) {
          allStats.push(response.data.transactionRecords.transactionsStats);
          allStats.push(response.data.transactionRecords.inAppSettlementsStats);
          allStats.push(response.data.transactionRecords.externalSettlementsStats);
        }
        return this.setState({
          groupsCount: response.data.groups ? response.data.groups.query.count : 0,
          transactionInAppSettlementsCount: response.data.transactionInAppSettlements
            ? response.data.transactionInAppSettlements.query.count
            : 0,
          transactionInAppSettlementsToBeProcessedCount: response.data.transactionInAppSettlements
            ? response.data.transactionInAppSettlements.toBeProcessed.count
            : 0,
          sortCodesCount: response.data.sortCodes ? response.data.sortCodes.query.count : 0,
          usersCount: response.data.users ? response.data.users.query.count : 0,
          usersExternalCount: response.data.users ? response.data.users.notInternal.count : 0,
          peachPaymentCallLogsCount: response.data.peachPaymentCallLogs
            ? response.data.peachPaymentCallLogs.query.count
            : 0,
          isLoading: false,
          stats: allStats,
          summary: {
            lastMonth: response.data.lastMonth,
            monthBeforeTo: response.data.monthBeforeTo
          },
          error: ''
        });
      })
      .catch(response =>
        this.setState({
          isLoading: false,
          error: 'Failed to query date from server. Please try again later.'
        })
      );
  }

  render() {
    return (
      <SiteWrapper>
        <Page.Content title="Dashboard">
          {this.state.isLoading ? (
            <Dimmer active loader />
          ) : (
            <div>
              <Grid.Row cards={true}>
                <Grid.Col width={6} sm={12}>
                  <Text.Small muted>Over last 30 days</Text.Small>
                </Grid.Col>
              </Grid.Row>
              {this.state.summary.monthBeforeTo !==null && (
                <Grid.Row cards={true}>
                  <Grid.Col width={6} sm={4} lg={2}>
                    <StatsCard
                      layout={1}
                      movement={percentChange(
                        this.state.summary.monthBeforeTo.dashboardSummary.usersPerGroup,
                        this.state.summary.lastMonth.dashboardSummary.usersPerGroup
                      )}
                      total={shortNumberFormat(this.state.summary.lastMonth.dashboardSummary.usersPerGroup)}
                      label="User per group"
                    />
                  </Grid.Col>
                  <Grid.Col width={6} sm={4} lg={2}>
                    <StatsCard
                      layout={1}
                      movement={percentChange(
                        this.state.summary.monthBeforeTo.dashboardSummary.membersPerGroup,
                        this.state.summary.lastMonth.dashboardSummary.membersPerGroup
                      )}
                      total={shortNumberFormat(this.state.summary.lastMonth.dashboardSummary.membersPerGroup)}
                      label="Members per group"
                    />
                  </Grid.Col>
                  <Grid.Col width={6} sm={4} lg={2}>
                    <StatsCard
                      layout={1}
                      movement={percentChange(
                        this.state.summary.monthBeforeTo.dashboardSummary.valuePaid,
                        this.state.summary.lastMonth.dashboardSummary.valuePaid
                      )}
                      total={shortNumberFormat(this.state.summary.lastMonth.dashboardSummary.valuePaid / 100)}
                      label="Paid"
                    />
                  </Grid.Col>
                  <Grid.Col width={6} sm={4} lg={2}>
                    <StatsCard
                      layout={1}
                      movement={percentChange(
                        this.state.summary.monthBeforeTo.dashboardSummary.valuePlanned,
                        this.state.summary.lastMonth.dashboardSummary.valuePlanned
                      )}
                      total={shortNumberFormat(this.state.summary.lastMonth.dashboardSummary.valuePlanned / 100)}
                      label="Planned"
                    />
                  </Grid.Col>
                  <Grid.Col width={6} sm={4} lg={2}>
                    <StatsCard
                      layout={1}
                      movement={percentChange(
                        this.state.summary.monthBeforeTo.dashboardSummary.valueInAppSettlements,
                        this.state.summary.lastMonth.dashboardSummary.valueInAppSettlements
                      )}
                      total={shortNumberFormat(
                        this.state.summary.lastMonth.dashboardSummary.valueInAppSettlements / 100
                      )}
                      label="In app settments"
                    />
                  </Grid.Col>
                  <Grid.Col width={6} sm={4} lg={2}>
                    <StatsCard
                      layout={1}
                      movement={percentChange(
                        this.state.summary.monthBeforeTo.dashboardSummary.valueExternalSettlements,
                        this.state.summary.lastMonth.dashboardSummary.valueExternalSettlements
                      )}
                      total={shortNumberFormat(
                        this.state.summary.lastMonth.dashboardSummary.valueExternalSettlements / 100
                      )}
                      label="External"
                    />
                  </Grid.Col>
                </Grid.Row>
              )}
              <Grid.Row cards={true}>
                {this.state.stats.map((stat, i) => (
                  <Grid.Col key={i} sm={6} lg={2}>
                    <SimpleStatsCard
                      total={stat.total}
                      label={stat.label}
                      color={CHART_BG_COLORS[i]}
                      data={stat.data}
                      columns={stat.columns}
                    />
                  </Grid.Col>
                ))}
              </Grid.Row>
              <Grid.Row cards={true}>
                {this.token.hasAccess('ReadUsers') && (
                  <Grid.Col sm={6} lg={3}>
                    <StampCard
                      color="green"
                      icon="user"
                      footer={`${this.state.usersExternalCount} external`}
                      header={
                        <a href="/users">
                          {this.state.usersCount} <small>Users</small>
                        </a>
                      }
                    />
                  </Grid.Col>
                )}
                {this.token.hasAccess('ReadPeachPaymentCallLog') && (
                  <Grid.Col sm={6} lg={3}>
                    <StampCard
                      color="yellow"
                      icon="message-square"
                      header={
                        <a href="/peachPaymentCallLogs">
                          {this.state.peachPaymentCallLogsCount} <small>Peach call Logs</small>
                        </a>
                      }
                    />
                  </Grid.Col>
                )}
                {this.token.hasAccess('ReadGroup') && (
                  <Grid.Col sm={6} lg={3}>
                    <StampCard
                      color="blue"
                      icon="box"
                      header={
                        <a href="/groups">
                          {this.state.groupsCount} <small>Groups</small>
                        </a>
                      }
                    />
                  </Grid.Col>
                )}
                {this.token.hasAccess('ReadTransactionInAppSettlement') && (
                  <Grid.Col sm={6} lg={3}>
                    <StampCard
                      color="green"
                      icon="shopping-cart"
                      footer={`${this.state.transactionInAppSettlementsToBeProcessedCount} to be processed`}
                      header={
                        <a href="/transactionInAppSettlements">
                          {this.state.transactionInAppSettlementsCount} <small>Settlement requests</small>
                        </a>
                      }
                    />
                  </Grid.Col>
                )}
              </Grid.Row>
            </div>
          )}
          {this.state.error && <Alert type="danger">{this.state.error}</Alert>}
        </Page.Content>
      </SiteWrapper>
    );
  }
}

export default DashboardPage;
