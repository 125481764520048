import * as React from 'react';
import AuthService from '../../core/AuthService';
import QueryString from 'query-string';
import DeviceStorage from 'react-device-storage';

import { Dimmer } from 'tabler-react';

interface Props {
  location: Location;
}

interface LoginData {}

new DeviceStorage({
  cookieFallback: true,
  cookie: {
    secure: true
  }
}).localStorage();

class LoginPage extends React.Component<Props> {
  authService: AuthService;
  constructor(props) {
    super(props);
    this.authService = new AuthService();
  }

  componentDidMount() {
    var isLogoutRequested = QueryString.parse(this.props.location.search).logout || false;

    if (isLogoutRequested) {
      this.authService.logout();
    } else {
      this.authService.loginRedirect();
    }
  }

  render() {
    return (
      <div className="centered">
        <Dimmer active loader />
      </div>
    );
  }
}

export default LoginPage;
