let _storedUrl: string;

export default class AppSettings {
  static ApiEndPoint() {
    if (!_storedUrl) {
      _storedUrl = process.env.REACT_APP_API_URL;
      console.log('ApiUrl:', _storedUrl);
    }
    return _storedUrl;
  }

  static BuildUrl(url: string): string {
    return AppSettings.CombineUrl(AppSettings.ApiEndPoint(), url);
  }

  static CombineUrl(base: string, path: string): string {
    return (base + '/' + path).replace(/([^:])\/\//g, '$1/');
  }
}
