import * as React from 'react';
import SiteWrapper from '../../components/SiteWrapper';
import { Page, Button, Dimmer, Alert, Header } from 'tabler-react';
import GroupsList from './GroupsList';
import gql from '../../../node_modules/graphql-tag';
import ApiService from '../../core/ApiService';
import { ReactRouterProps } from '../../components/DefaultTypes';
import OnVisible from '../../components/OnVisible';
import { GraphQlPageHolder } from '../../components/OnVisible';

interface Props extends ReactRouterProps {}

interface State {
  groups: Array<any>;
  isLoading: boolean;
  isPaging: boolean;
  filter?: string;
  error: string;
}

const GET_GROUP = gql`
  query($search: String, $first: Int) {
    groups {
      query(first: $first, search: $search) {
        items {
          id
          name
          image
          updateDate
          hasTransactions
          members {
            id
            userId
            name
            image
            status
          }
        }
        endCursor
        hasNextPage
      }
    }
  }
`;

const DELETE_GROUP = gql`
  mutation groupsDelete($groupId: String!) {
    groups {
      delete(id: $groupId) {
        id
      }
    }
  }
`;

class GroupsPage extends React.Component<Props, State> {
  apiService: ApiService;
  state = {
    groups: [],
    isLoading: true,
    isPaging: true,
    filter: null,
    error: ''
  };
  queryResult: ZenObservable.Subscription;
  graphQlPager: GraphQlPageHolder;

  constructor(props) {
    super(props);
    this.apiService = new ApiService();
    this.graphQlPager = new GraphQlPageHolder(200, this.props.history, () => this.refreshData());
  }

  componentDidMount() {
    this.refreshData();
  }

  componentWillUnmount() {
    this.unSubscribeFromQuery();
  }

  unSubscribeFromQuery() {
    if (this.queryResult) {
      this.queryResult.unsubscribe();
      this.queryResult = null;
    }
  }

  refreshData() {
    this.setState({ isPaging: true });
    this.unSubscribeFromQuery();
    this.queryResult = this.apiService
      .watchQuery(GET_GROUP, this.graphQlPager.getPageOptions({ search: this.state.filter }))
      .subscribe((response: any) => {
        if (response.errors) {
          this.setState({
            isLoading: false,
            isPaging: false,
            error: 'Failed to read groups from service.'
          });
        } else {
          if (response.data) {
            this.graphQlPager.setPageResult(response.data.groups.query);
            this.setState({
              groups: response.data.groups.query.items,
              isLoading: false,
              isPaging: false,
              error: ''
            });
          }
        }
      });
  }

  add() {
    this.props.history.push('/group/add');
  }

  update(group: any) {
    this.props.history.push(`/group/${group.id}`);
  }

  remove(group: any, callback: any) {
    this.apiService
      .mutate(DELETE_GROUP, { groupId: group.id })
      .then(response => {
        this.refreshData();
        callback();
      })
      .catch(response =>
        this.setState({
          error: `Failed to remove group '${group.description}' from service.`
        })
      );
  }

  render() {
    var { groups } = this.state;
    return (
      <SiteWrapper>
        <Page.Content>
          <Header.H1>
            Groups
            <span style={{ marginLeft: '15px' }}>
              {' '}
              <Button onClick={() => this.add()} color="secondary" icon="plus" />
            </span>
          </Header.H1>
          {this.state.isLoading ? (
            <Dimmer active loader />
          ) : (
            <>
              <GroupsList
                history={this.props.history}
                onFilterChange={f => this.setState({ filter: f }, () => this.refreshData())}
                groups={groups}
                update={(m, c) => this.update(m)}
                remove={(m, c) => this.remove(m, c)}
              />
              <OnVisible offset={30} isVisible={b => this.graphQlPager.isVisible(b)} />
              {this.state.isPaging ? <Dimmer active loader /> : <span />}
            </>
          )}
          {this.state.error && <Alert type="danger">{this.state.error}</Alert>}
        </Page.Content>
      </SiteWrapper>
    );
  }
}

export default GroupsPage;
