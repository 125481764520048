import * as React from 'react';
import SiteWrapper from '../../components/SiteWrapper';
import { Page, Header, Dimmer, Alert, Form, Button } from 'tabler-react';
import ApiService from '../../core/ApiService';
import gql from 'graphql-tag';

export interface ISettingsPageProps {}

export interface ISettingsPageState {
  settingValues?: any;
  touched: any;
  submitting: any;
  isLoading: boolean;
  error?: string;
}

export interface ISettingValue {
  key: string;
  value: string;
  updateDate: Date;
}

const GET_SETTINGS = gql`
  query {
    settingValues {
      query {
        items {
          key
          value
          updateDate
        }
      }
    }
  }
`;

const SET_VALUE = gql`
  mutation($key: String!, $value: String) {
    settingValues {
      set(key: $key, value: $value) {
        id
      }
    }
  }
`;

export default class SettingsPage extends React.Component<ISettingsPageProps, ISettingsPageState> {
  apiService: ApiService;
  constructor(props: ISettingsPageProps) {
    super(props);
    this.state = {
      isLoading: true,
      submitting: {},
      touched: {},
      error: null
    };
    this.apiService = new ApiService();
  }
  componentDidMount() {
    this.refreshData();
  }

  async refreshData() {
    try {
      var response = await this.apiService.queryWithCatch(GET_SETTINGS);
      let result = {};
      response.data.settingValues.query.items.forEach(element => {
        result[element.key] = element.value;
      });

      this.setState({
        settingValues: result,
        isLoading: false,
        error: null
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        error: `Failed to read setting values from service. [${error}]`
      });
    }
  }

  validate(values: any) {
    let errors: any = {};
    return errors;
  }

  cleanName(text: string) {
    var result = text
      .replace('_', ' - ')
      .replace(/([A-Z])/g, ' $1')
      .replace('  ', ' ');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  onSave(key: string) {
    var { touched, submitting, settingValues } = this.state;

    submitting[key] = true;
    touched[key] = true;
    this.setState({
      submitting: submitting,
      touched: touched,
      error: ''
    });

    this.apiService
      .mutate(SET_VALUE, {
        key: key,
        value: settingValues[key]
      })
      .then(() => {
        submitting[key] = false;
        touched[key] = false;
        this.setState({
          submitting: submitting,
          touched: touched,
          error: ''
        });
      })
      .catch(ex => {
        submitting[key] = false;
        touched[key] = false;
        const message = this.apiService.cleanErrorMessage(ex);
        this.setState({ submitting: submitting, touched: touched, error: `Failed update setting [${message}]` });
      });
  }

  renderForm() {
    var { touched, submitting, settingValues } = this.state;

    return (
      <Form>
        {Object.keys(settingValues).map((key, i) => (
          <Form.Group key={key} label={this.cleanName(key)}>
            <Form.InputGroup>
              <Form.Input
                name={key}
                value={settingValues[key]}
                onChange={event => {
                  settingValues[key] = event.target.value;
                  touched[key] = true;
                  this.setState({
                    settingValues: settingValues,
                    touched: touched
                  });
                }}
                feedback={touched[key]}
              />
              <Form.InputGroupAppend>
                <Button
                  color="primary"
                  loading={submitting[key]}
                  icon="save"
                  alt="Update"
                  disabled={submitting[key] || !touched[key]}
                  onClick={c => {
                    c.preventDefault();
                    this.onSave(key);
                  }}
                ></Button>
              </Form.InputGroupAppend>
            </Form.InputGroup>
          </Form.Group>
        ))}
      </Form>
    );
  }

  render() {
    return (
      <SiteWrapper>
        <Page.Content>
          <Header.H1>Settings</Header.H1>
          {this.state.isLoading ? <Dimmer active loader /> : this.renderForm()}
          {this.state.error && <Alert type="danger">{this.state.error}</Alert>}
        </Page.Content>
      </SiteWrapper>
    );
  }
}
