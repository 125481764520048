import * as React from 'react';
import { Card, Avatar, Table, Text } from 'tabler-react';
import TimeAgo from 'react-timeago';
import { Route } from 'react-router-dom';
import { Icon } from 'tabler-react';
import truncateWithEllipses from '../../components/TruncateWithEllipses';

const updateObjectByKeyImmutable = (arr, key, value) => {
  return Object.assign({}, arr, { [key]: value });
};

interface ExpandButtonProps {
  isVisible: boolean;
  isExpanded: boolean;
  onClick: () => void;
}

const ExpandButton = ({ isVisible, isExpanded, onClick }: ExpandButtonProps) =>
  isVisible && <Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} onClick={onClick} />;

interface Props {
  activities: any;
}

interface State {
  activityIdExpanded: any;
}

class UserActivityList extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      activityIdExpanded: {}
    };
  }

  toggleActivityPropertiesExpanded(activityId: string) {
    const updatedList = updateObjectByKeyImmutable(
      this.state.activityIdExpanded,
      activityId,
      !this.state.activityIdExpanded[activityId]
    );
    this.setState({ activityIdExpanded: updatedList });
  }

  componentDidMount() {}

  render() {
    var activities = this.props.activities;

    return (
      <Card>
        <Card.Body>
          <Table responsive highlightRowOnHover hasOutline verticalAlign="center" cards className="text-nowrap">
            <Table.Header>
              <Table.Row>
                <Table.ColHeader className="w-1">Id</Table.ColHeader>
                <Table.ColHeader>Description</Table.ColHeader>
                <Table.ColHeader className="w-1">Updated</Table.ColHeader>
                <Table.ColHeader className="w-1">More</Table.ColHeader>
              </Table.Row>
            </Table.Header>
            <Route
              render={({ history }) => (
                <Table.Body>
                  {activities.map(activity => (
                    <React.Fragment key={activity.id}>
                      <Table.Row>
                        <Table.Col className="w-1">
                          {activity.group ? (
                            <a
                              href={`/group/${activity.group.id}`}
                              title={activity.group.name}
                              onClick={e => {
                                history.push(`/group/${activity.group.id}`);
                                e.preventDefault();
                              }}
                            >
                              <Avatar imageURL={activity.group.image} />{' '}
                            </a>
                          ) : (
                            <div />
                          )}
                        </Table.Col>
                        <Table.Col className="casd">
                          <Text>{truncateWithEllipses(activity.message, 100)}</Text>
                        </Table.Col>
                        <Table.Col className="w-1">
                          <TimeAgo date={activity.createDate} />
                        </Table.Col>
                        <Table.Col className="w-1">
                          <ExpandButton
                            isVisible={activity.properties && activity.properties.length > 0}
                            isExpanded={this.state.activityIdExpanded[activity.id]}
                            onClick={() => this.toggleActivityPropertiesExpanded(activity.id)}
                          />
                        </Table.Col>
                      </Table.Row>
                      {this.state.activityIdExpanded[activity.id] && (
                        <Table.Row className="expandable">
                          <Table.Col colSpan="4">
                            <Table
                              responsive
                              highlightRowOnHover
                              hasOutline
                              verticalAlign="center"
                              cards
                              className="text-nowrap"
                            >
                              <Table.Header>
                                <Table.Row>
                                  <Table.ColHeader className="w-1">Key</Table.ColHeader>
                                  <Table.ColHeader>Value</Table.ColHeader>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                {activity.properties &&
                                  activity.properties.map(property => (
                                    <Table.Row key={`${activity.id}-${property.key}`}>
                                      <Table.Col>{property.key}</Table.Col>
                                      <Table.Col>{property.value}</Table.Col>
                                    </Table.Row>
                                  ))}
                              </Table.Body>
                            </Table>
                          </Table.Col>
                        </Table.Row>
                      )}
                    </React.Fragment>
                  ))}
                </Table.Body>
              )}
            />
          </Table>
        </Card.Body>
      </Card>
    );
  }
}

export default UserActivityList;
