import React from 'react';
import TimeAgo from 'react-timeago';
import { Grid, Card, Text, Table, Avatar, Form } from 'tabler-react';
import EditDelete from '../../components/EditDelete';
import ShortId from '../../components/ShortId';
import { Route } from 'react-router-dom';
import UserAvatar, { UserAvatarFormat } from '../../components/UserAvatar';
import truncateWithEllipses from '../../components/TruncateWithEllipses';

type OnFilterChange = (msg: string) => void;

interface Props {
  groups: Array<any>;
  history?: any;
  onFilterChange?: OnFilterChange;

  update(group: any, callback?: any): void;
  remove(group: any, callback: any): void;
}

interface State {
  filterStr: string;
}

export default class GroupsList extends React.Component<Props, State> {
  bufferTimeout: NodeJS.Timeout;

  constructor(props) {
    super(props);
    this.state = {
      filterStr: ''
    };
  }

  setFilter(searchString: string) {
    const hasChange = this.props.onFilterChange !== null;
    if (this.bufferTimeout) clearTimeout(this.bufferTimeout);
    this.bufferTimeout = setTimeout(() => {
      if (hasChange) {
        this.props.onFilterChange(searchString.trim());
      } else {
        this.setState({ filterStr: searchString });
      }
    }, 500);
  }

  applyFilter(item) {
    const filter = this.state.filterStr.toLowerCase();
    return (
      item.name.toLowerCase().indexOf(filter) >= 0 ||
      item.members.some(m => m.name.toLowerCase().indexOf(filter) >= 0 || item.id.indexOf(filter) >= 0)
    );
  }

  render() {
    const { groups } = this.props;

    return (
      <Grid.Row cards deck>
        <Grid.Col width={12}>
          <Form.Group>
            <Form.InputGroup>
              <Form.Input placeholder="Search for..." onChange={e => this.setFilter(e.target.value)} />
            </Form.InputGroup>
          </Form.Group>
          <Route
            render={({ history }) => (
              <Card>
                <Table responsive highlightRowOnHover hasOutline verticalAlign="center" cards className="text-nowrap">
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader className="w-1">Id</Table.ColHeader>
                      <Table.ColHeader className="w-1">Image</Table.ColHeader>
                      <Table.ColHeader>Name</Table.ColHeader>
                      <Table.ColHeader>Transactions</Table.ColHeader>
                      <Table.ColHeader>Members</Table.ColHeader>
                      <Table.ColHeader>Updated</Table.ColHeader>
                      <Table.ColHeader alignContent="center" className="w-1" />
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {groups
                      .filter(el => this.applyFilter(el))
                      .map(group => (
                        <Table.Row key={group.id}>
                          <Table.Col>
                            <Text.Small muted>
                              <ShortId id={group.id} />
                            </Text.Small>
                          </Table.Col>
                          <Table.Col>
                            <a
                              href={`/group/${group.id}`}
                              title={group.name}
                              onClick={e => {
                                history.push(`/group/${group.id}`);
                                e.preventDefault();
                              }}
                            >
                              <Avatar imageURL={group.image} />
                            </a>
                          </Table.Col>
                          <Table.Col>{truncateWithEllipses(group.name, 40)}</Table.Col>
                          <Table.Col>{!group.hasTransactions || <i className="fe fe-check" />}</Table.Col>
                          <Table.Col>
                            {group.members
                              .filter(m => m.status !== 'InActive')
                              .slice(0, 10)
                              .map(member => (
                                <span style={{ float: 'left' }} key={member.id}>
                                  <UserAvatar size="sm" member={member} format={UserAvatarFormat.ImageOnly} />
                                </span>
                              ))}
                          </Table.Col>
                          <Table.Col>
                            <TimeAgo date={group.updateDate} />
                          </Table.Col>
                          <Table.Col alignContent="center">
                            <EditDelete model={group} update={this.props.update} remove={this.props.remove} />
                          </Table.Col>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </Card>
            )}
          />
        </Grid.Col>
      </Grid.Row>
    );
  }
}
