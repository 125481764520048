import React from 'react';
import { Page, Form, Card, Dimmer } from 'tabler-react';
import ApiService from '../../core/ApiService';
import SiteWrapper from '../../components/SiteWrapper';
import gql from '../../../node_modules/graphql-tag';
import { Formik } from 'formik';
import { TransactionInAppSettlementCreateUpdate } from './TransactionInAppSettlementTypes';
import { ReactRouterProps } from '../../components/DefaultTypes';
import { StandardFormFooter } from '../../components/Dialog';

const GET_TRANSACTIONINAPPSETTLEMENT = gql`
  query getTransactionInAppSettlement($id: String!) {
    transactionInAppSettlements {
      byId(id: $id) {
        id
        code
        description
        updateDate
      }
    }
  }
`;

const INSERT_TRANSACTIONINAPPSETTLEMENT = gql`
  mutation insertTransactionInAppSettlement($code: String!, $description: String!) {
    transactionInAppSettlements {
      insert(transactionInAppSettlement: { code: $code, description: $description }) {
        id
      }
    }
  }
`;

const UPDATE_TRANSACTIONINAPPSETTLEMENT = gql`
  mutation updateTransactionInAppSettlement($id: String!, $code: String!, $description: String!) {
    transactionInAppSettlements {
      update(id: $id, transactionInAppSettlement: { code: $code, description: $description }) {
        id
      }
    }
  }
`;

interface Props extends ReactRouterProps {}

type State = {
  transactionInAppSettlement: any,
  isLoading: boolean,
  error: string
};

export default class TransactionInAppSettlementUpdatePage extends React.Component<Props, State> {
  routeId: string;
  apiService: ApiService;
  state = {
    transactionInAppSettlement: null,
    isLoading: false,
    error: ''
  };

  constructor(props) {
    super(props);
    this.apiService = new ApiService();
  }

  componentDidMount() {
    this.routeId = this.props.match.params.id;
    if (!this.isAdd()) this.loadData(this.routeId);
  }

  isAdd() {
    return this.routeId === 'add';
  }

  loadData(id: string) {
    this.setState({ isLoading: true });
    this.apiService
      .query(GET_TRANSACTIONINAPPSETTLEMENT, { id: id })
      .then(response =>
        this.setState({
          transactionInAppSettlement: response.data.transactionInAppSettlements.byId,
          error:
            response.data.transactionInAppSettlements.byId === null
              ? `Failed could not load transactionInAppSettlement with id '${id}'.`
              : '',
          isLoading: false
        })
      )
      .catch(() =>
        this.setState({
          isLoading: false,
          error: `Failed could not load transactionInAppSettlement with id '${id}'.`
        })
      );
  }

  onCancel() {
    this.props.history.goBack();
  }

  onSave(props: any, result: any) {
    console.log({
      id: props.id,
      code: props.code,
      description: props.description
    });
    this.setState({ error: '' });

    this.apiService
      .mutate(this.isAdd() ? INSERT_TRANSACTIONINAPPSETTLEMENT : UPDATE_TRANSACTIONINAPPSETTLEMENT, {
        id: props.id,
        code: props.code,
        description: props.description
      })
      .then(() => {
        result.setSubmitting(false);
        this.setState({ error: '' });
        this.props.history.goBack();
      })
      .catch(ex => {
        result.setSubmitting(false);
        const message = this.apiService.cleanErrorMessage(ex);
        this.isAdd()
          ? this.setState({ error: `Failed to add transactionInAppSettlement: ${message}` })
          : this.setState({ error: `Failed to update transactionInAppSettlement. ${message}` });
      });
  }

  validate(values: TransactionInAppSettlementCreateUpdate) {
    let errors: any = {};
    if (!values.code) {
      errors.code = 'Code is required.';
    }
    if (!values.description) {
      errors.description = 'Description is required.';
    }
    return errors;
  }

  render() {
    const mainForm = (
      <Formik
        initialValues={this.state.transactionInAppSettlement}
        validate={this.validate}
        onSubmit={(props, result) => this.onSave(props, result)}
        render={({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Code</Form.Label>
              <Form.Input
                name="code"
                value={values.code}
                onChange={handleChange}
                invalid={touched.code && errors.code}
                feedback={touched.code && errors.code}
              />
              {/* invalid placeholder="Is Invalid" */}
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Input
                name="description"
                value={values.description}
                onChange={handleChange}
                invalid={touched.description && errors.description}
                feedback={touched.description && errors.description}
              />
              {/* invalid placeholder="Is Invalid" */}
            </Form.Group>
            {StandardFormFooter(isSubmitting, () => this.onCancel())}
          </Form>
        )}
      />
    );
    return (
      <SiteWrapper>
        <Page.Content title="TransactionInAppSettlement">
          <Card>
            <Card.Body>{this.state.isLoading ? <Dimmer active loader /> : mainForm}</Card.Body>
            {this.state.error && (
              <Card.Alert color="danger">
                <strong>Whoops!</strong> {this.state.error}
              </Card.Alert>
            )}
          </Card>
        </Page.Content>
      </SiteWrapper>
    );
  }
}
