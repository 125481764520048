import React from 'react';
import TimeAgo from 'react-timeago';
import { Page, Card, Dimmer, Grid, Table, Button, Icon, Text, Avatar } from 'tabler-react';
import ApiService from '../../core/ApiService';
import SiteWrapper from '../../components/SiteWrapper';
import firebase from 'firebase/app';
import '@firebase/messaging';
import Pusher from 'pusher-js';
import UserActivityList from './UserActivityList';
import gql from '../../../node_modules/graphql-tag';
import ShortId from '../../components/ShortId';
import { ReactRouterProps } from '../../components/DefaultTypes';

interface Props extends ReactRouterProps {}

interface State {
  userId: string;
  user: any;
  tokenMessage?: string;
  filterGroupId: string;
  error: string;
  isLoading: boolean;
  activity?: Array<any>;
  pusherData: Array<any>;
}

var registed = false;

const GET_USER = gql`
  query getUser($id: String!) {
    users {
      activity(userId: $id, first: 300) {
        items {
          id
          group {
            id
            name
            image
          }
          message
          properties {
            key
            value
          }
          createDate
        }
      }
      byId(id: $id) {
        id
        email
        name
        channelId
        pushTokens {
          token
          expireDate
          lastFailure
          lastSuccess
          messagesSent
          platform
        }
      }
    }
  }
`;

const SET_USER_PUSHTOKEN = gql`
  mutation($userId: String!, $token: String!, $expireDate: DateTime!, $platform: Platforms!) {
    users {
      setPushToken(userId: $userId, token: $token, expireDate: $expireDate, platform: $platform) {
        id
      }
    }
  }
`;

export const initializeFirebase = () => {
  if (!registed) {
    registed = true;
    console.log('initializeFirebase...........');
    firebase.initializeApp({
      messagingSenderId: '903409056684'
    });
  }
};
const pusherId = process.env.REACT_APP_PUSHER_ID;
console.log('Connecting to pusher', pusherId);
var pusher = new Pusher(pusherId, {
  cluster: 'eu',
  forceTLS: true
});

export default class UserFeedPage extends React.Component<Props, State> {
  apiService: ApiService;
  channels: [];
  isRegistered: boolean = false;
  state = {
    userId: '',
    user: {},
    activity: [],
    pusherData: [],
    filterGroupId: null,
    isLoading: false,
    error: ''
  };

  constructor(props) {
    super(props);
    this.apiService = new ApiService();
    this.channels = [];
  }

  componentDidMount() {
    this.setState({ userId: this.props.match.params.id });
    this.setState({ isLoading: true });
    this.loadData(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.channels.forEach(channel => {
      // @ts-ignore
      channel.unbind_all();
    });
  }

  loadData(id: string) {
    this.apiService
      .query(GET_USER, { id: id })
      .then(response => {
        this.setState({
          user: response.data.users.byId,
          activity: response.data.users.activity.items,
          error: response.data.users.byId === null ? `Failed could not load user with id '${id}'.` : '',
          isLoading: false
        });
        this.registerChannels(response);
      })
      .catch(response => {
        console.error(response);
        this.setState({
          isLoading: false,
          error: `Failed could not load user with id '${id}'.`
        });
      });
  }

  private registerChannels(response) {
    if (!this.isRegistered) {
      this.isRegistered = true;
      this.registerChannel(`${response.data.users.byId.channelId}_group`, ['created', 'memberadded', 'acceptgroupinvite', 'updated', 'invitedexistingmember', 'removed', 'memberleft'], () => { });
      this.registerChannel(`${response.data.users.byId.channelId}_user`, [
        'updated',
        'bankdetailremoved',
        'bankdetailupdateorcreated',
        'cardremoved',
        'invitecreated',
        'pushtokenwasset',
        'signedup'
      ], () => { });
      this.registerChannel(`${response.data.users.byId.channelId}_useractivity`, ['created'], e => this.loadData(e));
      this.registerChannel(`${response.data.users.byId.channelId}_transactionrecord`, ['created', 'updated', 'removed'], () => { });
    }
  }

  registerChannel(channel, events, call) {
    var channelSubscription = pusher.subscribe(channel);
    events.forEach(event => {
      channelSubscription.bind(event, data => {
        call(this.state.userId);
        this.setState({
          pusherData: [{ channel: channel, event: event, data: data }, ...this.state.pusherData]
        });
      });
    });

    // @ts-ignore
    this.channels.push(channelSubscription);
  }

  registerUserToFcm() {
    //this.setState({ isLoading: true });
    initializeFirebase();

    const messaging = firebase.messaging();
    messaging.requestPermission().then(() => {
      messaging
        .getToken()
        .then(currentToken => {
          if (currentToken) {
            this.setState({ tokenMessage: 'Token has been connected' });
            var newDateObj = new Date(new Date().getTime() + 30 * 60000);
            this.apiService
              .mutate(SET_USER_PUSHTOKEN, {
                userId: this.state.userId,
                platform: 'Web',
                token: currentToken,
                expireDate: newDateObj
              })
              .then(response => {
                this.loadData(this.props.match.params.id);
                this.setState({
                  error:
                    response.data.users.byId === null ? `Failed could not load user with '${this.state.userId}'.` : '',
                  isLoading: false
                });
              })
              .catch(response => {
                this.setState({
                  isLoading: false,
                  error: `${response.graphQLErrors[0].message}.`
                });
              });
          } else {
            this.setState({ error: 'No Instance ID token available. Request permission to generate one.' });
          }
        })
        .catch(err => {
          console.log('An error occurred while retrieving token. ', err);
          this.setState({ error: 'An error occurred while retrieving token.' });
        });
    });

    messaging.onMessage(function(payload) {
      console.log('Message received. ', payload);
      // ...
    });
  }

  onCancel() {
    this.props.history.goBack();
  }
  renderSwitch(param) {
    switch (param) {
      case 'Android':
        return <Icon prefix="fa" name="android" />;
      case 'Ios':
        return <Icon prefix="fa" name="mobile" />;
      case 'Web':
        return <Icon prefix="fa" name="columns" />;
      default:
        return param;
    }
  }
  groupBy(list, props) {
    return list.reduce((a, b) => {
      (a[b[props]] = a[b[props]] || []).push(b);
      return a;
    }, {});
  }
  buildDistinctGroupList() {
    var grouped = this.groupBy(this.state.activity.map(x => x.group).filter(x => x !==null), 'id');
    return Object.keys(grouped).map(x => {
      return grouped[x][0];
    });
  }
  activityList() {
    let groups = this.buildDistinctGroupList();

    return (
      <div>
        {!this.state.activity || (
          <div>
            <h3>Activity</h3>
            <Grid.Row cards>
              {groups.map(group => (
                <div key={group.id}>
                  <Grid.Col>
                    <Card>
                      <Card.Body>
                        <a
                          href={`/group/${group.id}`}
                          onClick={e => {
                            this.setState({
                              filterGroupId: group.id
                            });
                            e.preventDefault();
                          }}
                        >
                          <Avatar imageURL={group.image} />
                          &nbsp;
                          {group.name}
                        </a>
                      </Card.Body>
                    </Card>
                  </Grid.Col>
                </div>
              ))}
            </Grid.Row>
            <UserActivityList
              activities={
                this.state.filterGroupId
                  ? this.state.activity.filter(x => x.group !== null && x.group.id === this.state.filterGroupId)
                  : this.state.activity
              }
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    // @ts-ignore
    var { pushTokens } = this.state.user;
    // @ts-ignore
    var name = this.state.user.name;
    // @ts-ignore
    var tokenMessage = this.state.tokenMessage;
    const mainForm = (
      <Card>
        <Card.Body>
          {name}
          <div>
            Link to push notification for 10 minutes.
            <Button icon="link" color="info" onClick={() => this.registerUserToFcm()}>
              Link
            </Button>
            {!pushTokens || (
              <Table responsive highlightRowOnHover hasOutline verticalAlign="center" cards className="text-nowrap">
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Token</Table.ColHeader>
                    <Table.ColHeader>Expires</Table.ColHeader>
                    <Table.ColHeader>Last Activity</Table.ColHeader>
                    <Table.ColHeader>Messages Sent</Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {pushTokens.map((tokens) => (
                    <Table.Row key={tokens.token}>
                      <Table.Col>
                        <span style={{ marginRight: '10px' }}>{this.renderSwitch(tokens.platform)}</span>
                        <ShortId id={tokens.token} />
                      </Table.Col>
                      <Table.Col>
                        <TimeAgo date={tokens.expireDate} />
                      </Table.Col>
                      <Table.Col>
                        {!tokens.lastSuccess || (
                          <Text color="green">
                            <TimeAgo date={tokens.lastSuccess} />
                          </Text>
                        )}

                        {!tokens.lastFailure || (
                          <Text color="red">
                            <TimeAgo date={tokens.lastFailure} />
                          </Text>
                        )}
                      </Table.Col>
                      <Table.Col>{tokens.messagesSent}</Table.Col>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            )}
          </div>
        </Card.Body>
        {!tokenMessage ? (
          <div />
        ) : (
          <Card.Alert color="success">
            <strong>Sweet!</strong> {tokenMessage}
          </Card.Alert>
        )}
      </Card>
    );

    const listFromPusher = (
      <Grid.Row cards deck>
        <Grid.Col width={12}>
          <Card>
            <Table responsive highlightRowOnHover hasOutline verticalAlign="center" cards className="text-nowrap">
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>Channel</Table.ColHeader>
                  <Table.ColHeader>Event</Table.ColHeader>
                  <Table.ColHeader>Data</Table.ColHeader>
                  <Table.ColHeader>Date</Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.pusherData.map((message, i) => (
                  <Table.Row key={i}>
                    <Table.Col>
                      {message.channel} + {i}
                    </Table.Col>
                    <Table.Col>{message.event}</Table.Col>
                    <Table.Col>{JSON.stringify(message.data)}</Table.Col>
                    <Table.Col>
                      <TimeAgo date={message.data.sentAt} />
                    </Table.Col>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Card>
        </Grid.Col>
      </Grid.Row>
    );
    return (
      <SiteWrapper>
        <Page.Content title="User">
          <Grid.Row cards deck>
            <Grid.Col>
              {this.state.isLoading ? (
                <Card>
                  <Card.Body>
                    <Dimmer active loader />
                  </Card.Body>
                </Card>
              ) : (
                mainForm
              )}
            </Grid.Col>
          </Grid.Row>
          {this.state.error && (
            <Card.Alert color="danger">
              <strong>Whoops!</strong> {this.state.error}
            </Card.Alert>
          )}
          {this.state.isLoading ? <div /> : this.activityList()}
          {this.state.isLoading ? <div /> : listFromPusher}
        </Page.Content>
      </SiteWrapper>
    );
  }
}
