import React, { Component } from 'react';
import { Icon, Button } from 'tabler-react';
import { WithId } from './DefaultTypes';

interface Props {
  model: WithId;
  update(sortCode: WithId): void;
  remove(sortCode: WithId, callback: any): void;
}

interface State {
  markedForRemoval: any;
  markedLoading: any;
}

export default class EditDelete extends Component<Props, State> {
  state = {
    markedForRemoval: {},
    markedLoading: {}
  };

  markedForRemoval(model: any, isMarked: boolean) {
    var update = {};
    update[model.id] = isMarked;
    this.setState({ markedForRemoval: Object.assign(this.state.markedForRemoval, update) });
  }

  markedLoading(model: any, isMarked: boolean) {
    var update = {};
    update[model.id] = isMarked;
    this.setState({ markedLoading: Object.assign(this.state.markedLoading, update) });
  }

  renderDefaultView() {
    return (
      <span>
        <Icon prefix="fe" name="edit-2" onClick={() => this.props.update(this.props.model)} />
        {this.props.remove && (
          <span>
            <Icon prefix="fe" name="trash" onClick={() => this.markedForRemoval(this.props.model, true)} />
          </span>
        )}
      </span>
    );
  }

  renderConfirmDeleteView() {
    return (
      <React.Fragment>
        <Icon prefix="fe" name="x" onClick={() => this.markedForRemoval(this.props.model, false)} />
        <Icon
          prefix="fe"
          name="trash-2"
          onClick={() => {
            this.markedLoading(this.props.model, true);
            this.props.remove(this.props.model, () => {
              this.markedForRemoval(this.props.model, false);
              this.markedLoading(this.props.model, false);
            });
          }}
        />
      </React.Fragment>
    );
  }

  renderDeletingView() {
    return (
      <React.Fragment>
        <Button loading color="secondary" size="sm">
          Button text
        </Button>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="editDelete">
        {this.state.markedForRemoval[this.props.model.id] || this.renderDefaultView()}
        {this.state.markedForRemoval[this.props.model.id] && (
          <span>
            {this.state.markedLoading[this.props.model.id] === true
              ? this.renderDeletingView()
              : this.renderConfirmDeleteView()}
          </span>
        )}
      </div>
    );
  }
}
