import React from 'react';
import TimeAgo from 'react-timeago';
import { Grid, Card, Text, Table, Form } from 'tabler-react';
import EditDelete from '../../components/EditDelete';
import { Campaign } from './CampaignTypes';
import ShortId from '../../components/ShortId';

interface Props {
  campaigns: Array<Campaign>;
  update(campaign: Campaign, callback?: any): void;
  remove(campaign: Campaign, callback?: any): void;
}

interface State {
  filterStr: string;
}

export default class CampaignsList extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      filterStr: ''
    };
  }

  setFilter(searchString: string) {
    setTimeout(() => {
      this.setState({ filterStr: searchString });
    }, 500);
  }

  applyFilter(item) {
    const filter = this.state.filterStr.toLowerCase();
    return (
      item.channel.toLowerCase().indexOf(filter) >= 0 || item.id.indexOf(filter) >= 0 || item.name.indexOf(filter) >= 0
    );
  }

  render() {
    const { campaigns } = this.props;

    return (
      <Grid.Row cards deck>
        <Grid.Col width={12}>
          <Form.Group>
            <Form.InputGroup>
              <Form.Input placeholder="Search for..." onChange={e => this.setFilter(e.target.value)} />
            </Form.InputGroup>
          </Form.Group>
          <Card>
            <Table responsive highlightRowOnHover hasOutline verticalAlign="center" cards className="text-nowrap">
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader className="w-1">Id</Table.ColHeader>
                  <Table.ColHeader>Code</Table.ColHeader>
                  <Table.ColHeader>Description</Table.ColHeader>
                  <Table.ColHeader>Size</Table.ColHeader>
                  <Table.ColHeader>Linked</Table.ColHeader>
                  <Table.ColHeader>Updated</Table.ColHeader>
                  <Table.ColHeader alignContent="center" className="w-1" />
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {campaigns
                  .filter(el => this.applyFilter(el))
                  .map(campaign => (
                    <Table.Row key={campaign.id}>
                      <Table.Col>
                        <Text.Small muted>
                          <ShortId id={campaign.id} />
                        </Text.Small>
                      </Table.Col>
                      <Table.Col>{campaign.name}</Table.Col>
                      <Table.Col>{campaign.channel}</Table.Col>
                      <Table.Col>{campaign.size}</Table.Col>
                      <Table.Col>{campaign.linked}</Table.Col>
                      <Table.Col>
                        <TimeAgo date={campaign.updateDate} />
                      </Table.Col>
                      <Table.Col alignContent="center">
                        <EditDelete model={campaign} update={this.props.update} remove={this.props.remove} />
                      </Table.Col>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Card>
        </Grid.Col>
      </Grid.Row>
    );
  }
}
