import React from 'react';
import { Page, Card, Dimmer, Table, Avatar, Grid } from 'tabler-react';
import ApiService from '../../core/ApiService';
import SiteWrapper from '../../components/SiteWrapper';
import gql from '../../../node_modules/graphql-tag';
import AmountText from '../../components/AmountText';
import TimeAgo from 'react-timeago';
import { ReactRouterProps } from '../../components/DefaultTypes';
import { buildTransactionType } from '../groups/GroupTransactionRecordsCard';

const GET_TRANSACTIONRECORD = gql`
  fragment amountData on Amount {
    cents
    currencyIso
    formatted
  }
  query getTransactionRecord($id: String!) {
    groups {
      byTransactionId(id: $id) {
        members {
          id
          name
          userId
          status
          image
          hasReceivingAccount
          summary {
            balance {
              ...amountData
            }
            totalSpent {
              ...amountData
            }
            totalOwe {
              ...amountData
            }
            totalIAmOwed {
              ...amountData
            }
          }
        }
      }
    }
    transactionRecords {
      byId(id: $id) {
        id
        date
        type
        description
        amount {
          ...amountData
        }
        plannedAmount {
          ...amountData
        }
        isInAppSettlement
        responsibleMemberId
        createdByUser {
          id
          name
        }
        updateDate
        images {
          key
          thumbnailUrl
          url
        }
        split {
          type
          portions {
            memberId
            size
            amount {
              ...amountData
            }
            responsible {
              ...amountData
            }
          }
        }
      }
    }
  }
`;

interface Props extends ReactRouterProps {}

type State = {
  transactionRecord: any,
  members: Array<any>,
  isLoading: boolean,
  error: string
};

export default class TransactionRecordUpdatePage extends React.Component<Props, State> {
  routeId: string;
  apiService: ApiService;
  state = {
    transactionRecord: null,
    members: [],
    isLoading: true,
    error: ''
  };

  constructor(props) {
    super(props);
    this.apiService = new ApiService();
  }

  componentDidMount() {
    this.routeId = this.props.match.params.id;
    if (!this.isAdd()) this.loadData(this.routeId);
  }
  isAdd() {
    return this.routeId === 'add';
  }

  loadData(id: string) {
    this.setState({ isLoading: true });
    this.apiService
      .query(GET_TRANSACTIONRECORD, { id: id })
      .then(response =>
        this.setState({
          transactionRecord: response.data.transactionRecords.byId,
          members: response.data.groups.byTransactionId.members,
          error:
            response.data.transactionRecords.byId === null
              ? `Failed could not load transactionRecord with id '${id}'.`
              : '',
          isLoading: false
        })
      )
      .catch(response =>
        this.setState({
          isLoading: false,
          error: `Failed could not load transactionRecord with id '${id}'.`
        })
      );
  }

  lookupMember(memberId) {
    return (
      this.state.members.filter(item => {
        return item.id === memberId;
      })[0] || {}
    );
  }

  onCancel(props: any, result?: any) {
    props.preventDefault();
    this.props.history.goBack();
  }

  render() {
    const mainForm = transactionRecord => (
      <div>
        <div>
          <h3>{transactionRecord.description}</h3>
        </div>
        <div>
          <AmountText amount={transactionRecord.amount} invert={true} />
        </div>
        <div>
          <strong>date:</strong> {transactionRecord.date}
        </div>
        <div>
          <strong>type:</strong> {buildTransactionType(transactionRecord)}
        </div>
        <div>
          <strong>responsible:</strong> {this.lookupMember(transactionRecord.responsibleMemberId).name}
        </div>
        <div>
          <strong>created by:</strong> {transactionRecord.createdByUser.name}
        </div>
        <div>
          <strong>updateDate:</strong> <TimeAgo date={transactionRecord.updateDate} />
        </div>
        {!transactionRecord.split || (
          <div>
            <strong>split:</strong> {transactionRecord.split.type}
            <Table responsive highlightRowOnHover hasOutline verticalAlign="center" cards className="text-nowrap">
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader className="w-1" />
                  <Table.ColHeader>Member</Table.ColHeader>
                  <Table.ColHeader>Split</Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {transactionRecord.split.portions.map(portion => (
                  <Table.Row key={portion.id}>
                    <Table.Col>
                      <Avatar
                        size="md"
                        className="d-block margin-middle"
                        imageURL={this.lookupMember(portion.memberId).image}
                      />
                    </Table.Col>
                    <Table.Col>{this.lookupMember(portion.memberId).name}</Table.Col>
                    <Table.Col>
                      <AmountText amount={portion.amount} invert={true} />
                    </Table.Col>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        )}
        {!transactionRecord.images || (
          <Grid.Row>
            {transactionRecord.images.map(image => (
              <a key={image.key} href={image.url} title={image.key} target="_image">
                <img style={{ margin: '5px', borderRadius: '10px' }} alt={image.key} src={image.thumbnailUrl} />{' '}
              </a>
            ))}
          </Grid.Row>
        )}
      </div>
    );

    return (
      <SiteWrapper>
        <Page.Content title="Transaction record">
          <Card>
            <Card.Body>
              {this.state.isLoading && this.state.transactionRecord === null ? (
                <Dimmer active loader />
              ) : (
                mainForm(this.state.transactionRecord)
              )}
            </Card.Body>
            {this.state.error && (
              <Card.Alert color="danger">
                <strong>Whoops!</strong> {this.state.error}
              </Card.Alert>
            )}
          </Card>
        </Page.Content>
      </SiteWrapper>
    );
  }
}
