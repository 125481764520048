import React from 'react';
import { Page, Form, Card, Dimmer, Table, Icon, Text } from 'tabler-react';
import ApiService from '../../core/ApiService';
import SiteWrapper from '../../components/SiteWrapper';
import gql from '../../../node_modules/graphql-tag';
import { Formik } from 'formik';
import { Campaign, CampaignCreateUpdate } from './CampaignTypes';
import { ReactRouterProps } from '../../components/DefaultTypes';
import TimeAgo from 'react-timeago';
import { CSVLink } from 'react-csv';
import { StandardFormFooter } from '../../components/Dialog';

const GET_CAMPAIGN = gql`
  query getCampaign($id: String!) {
    campaigns {
      byId(id: $id) {
        id
        name
        channel
        entries {
          externalId
          url
          isLinked
          updateDate
        }
        updateDate
      }
    }
  }
`;

const INSERT_CAMPAIGN = gql`
  mutation insertCampaign($name: String!, $channel: String!, $data: String!) {
    campaigns {
      insert(name: $name, channel: $channel, data: $data) {
        id
      }
    }
  }
`;

const UPDATE_CAMPAIGN = gql`
  mutation updateCampaign($id: String!, $name: String!, $channel: String!) {
    campaigns {
      update(id: $id, name: $name, channel: $channel) {
        id
      }
    }
  }
`;

interface Props extends ReactRouterProps {
  match: {
    params: any
  };
}

interface State {
  campaign: Campaign;
  isLoading: boolean;
  isAdded: boolean;
  error: string;
}

export default class CampaignUpdatePage extends React.Component<Props, State> {
  routeId: string;
  apiService: ApiService;
  state = {
    campaign: null,
    isAdded: true,
    isLoading: true,
    error: ''
  };

  constructor(props) {
    super(props);
    this.apiService = new ApiService();
  }

  componentDidMount() {
    this.routeId = this.props.match.params.id;
    if (!this.isAdd()) {
      this.loadData(this.routeId);
    } else {
      this.setState({ isLoading: false, isAdded: true });
    }
  }

  isAdd() {
    return this.routeId === 'add';
  }

  loadData(id: string) {
    this.setState({ isLoading: true, isAdded: false });
    this.apiService
      .query(GET_CAMPAIGN, { id: id })
      .then(response =>
        this.setState({
          campaign: response.data.campaigns.byId,
          error: response.data.campaigns.byId === null ? `Failed could not load campaign with id '${id}'.` : '',
          isLoading: false
        })
      )
      .catch(response =>
        this.setState({
          isLoading: false,
          error: `Failed could not load campaign with id '${id}'.`
        })
      );
  }

  onCancel() {
    this.props.history.goBack();
  }

  onSave(props: any, result: any) {
    this.setState({ error: '' });

    this.apiService
      .mutate(this.isAdd() ? INSERT_CAMPAIGN : UPDATE_CAMPAIGN, {
        id: props.id,
        name: props.name,
        channel: props.channel,
        data: btoa(props.data)
      })
      .then(response => {
        result.setSubmitting(false);
        this.props.history.goBack();
      })
      .catch(ex => {
        result.setSubmitting(false);
        const message = this.apiService.cleanErrorMessage(ex);
        this.isAdd()
          ? this.setState({ error: `Failed to add campaign: ${message}` })
          : this.setState({ error: `Failed to update campaign. ${message}` });
      });
  }

  validate(values: CampaignCreateUpdate) {
    let errors: any = {};
    if (!values.name) {
      errors.name = 'Name is required.';
    }
    if (!values.channel) {
      errors.channel = 'Channel is required.';
    }
    return errors;
  }
  renderEntries() {
    return (
      <Table responsive highlightRowOnHover hasOutline verticalAlign="center" cards className="text-nowrap">
        <Table.Header>
          <Table.Row>
            <Table.ColHeader>Id</Table.ColHeader>
            <Table.ColHeader>Url</Table.ColHeader>
            <Table.ColHeader>Linked</Table.ColHeader>
            <Table.ColHeader className="w-1">Updated</Table.ColHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.state.campaign.entries
            .sort((a, b) => b.isLinked - a.isLinked)
            .map(entry => (
              <Table.Row key={entry.externalId}>
                <Table.Col>{entry.externalId}</Table.Col>
                <Table.Col>
                  <a href={entry.url}>Link</a>
                </Table.Col>
                <Table.Col>
                  {entry.isLinked ? (
                    <Text color="green">
                      <Icon prefix="fe" name="check-circle" />
                    </Text>
                  ) : (
                    <Text muted>
                      <Icon prefix="fe" name="check-circle" />
                    </Text>
                  )}
                </Table.Col>
                <Table.Col>
                  <TimeAgo date={entry.updateDate} />
                </Table.Col>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    );
  }

  getCsvData() {
    return this.state.campaign.entries.map(r => {
      const result = {
        externalId: r.externalId,
        url: r.url,
        isLinked: r.isLinked,
        updateDate: r.updateDate
      };

      return result;
    });
  }

  renderCsvDownloadLink() {
    var formattedDate = new Date().toISOString().split('T')[0];
    var csvData = this.getCsvData();
    return (
      <CSVLink
        className="float-right btn btn-outline-primary btn-icon"
        data={csvData}
        filename={this.state.campaign.name + '_' + this.state.campaign.channel + '_' + formattedDate + '.csv'}
        target="_blank"
      >
        <Icon name="download" />
        <span style={{ marginLeft: '5px' }}>Dowload</span>
      </CSVLink>
    );
  }

  render() {
    var { isAdded } = this.state;
    console.log(isAdded, 'isAdded');
    const mainForm = (
      <Formik
        initialValues={this.state.campaign}
        validate={this.validate}
        onSubmit={(props, result) => this.onSave(props, result)}
        render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Input
                name="name"
                value={values.name}
                onChange={handleChange}
                invalid={touched.name && errors.name}
                feedback={touched.name && errors.name}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Channel</Form.Label>
              <Form.Input
                name="channel"
                value={values.channel}
                onChange={handleChange}
                invalid={touched.channel && errors.channel}
                feedback={touched.channel && errors.channel}
              />
            </Form.Group>
            {isAdded && (
              <Form.Group>
                <Form.Label>Data</Form.Label>
                <Form.Textarea
                  name="data"
                  value={values.data}
                  rows={6}
                  onChange={handleChange}
                  invalid={touched.data && errors.data}
                  feedback={touched.data && errors.data}
                />
              </Form.Group>
            )}

            {StandardFormFooter(isSubmitting, () => this.onCancel())}
          </Form>
        )}
      />
    );
    return (
      <SiteWrapper>
        <Page.Content title="Campaign">
          <div>
            {this.state.isLoading ? (
              <Dimmer active loader />
            ) : (
              <div>
                <Card>
                  <Card.Body>{mainForm}</Card.Body>
                </Card>
                {!isAdded && (
                  <Card>
                    <Card.Body>
                      {this.renderCsvDownloadLink()}
                      {this.renderEntries()}
                    </Card.Body>
                  </Card>
                )}
              </div>
            )}
          </div>
          {this.state.error && (
            <Card.Alert color="danger">
              <strong>Whoops!</strong> {this.state.error}
            </Card.Alert>
          )}
        </Page.Content>
      </SiteWrapper>
    );
  }
}
