import React from 'react';
import TimeAgo from 'react-timeago';
import { Grid, Card, Text, Table } from 'tabler-react';
import ShortId from '../../components/ShortId';

interface Props {
  peachPaymentCallLogs: Array<any>;
  update(peachPaymentCallLog: any, callback?: any): void;
  remove(peachPaymentCallLog: any, callback: any): void;
}

export default class PeachPaymentCallLogsList extends React.Component<Props> {
  render() {
    const { peachPaymentCallLogs } = this.props;

    return (
      <Grid.Row cards deck>
        <Grid.Col width={12}>
          <Card>
            <Table responsive highlightRowOnHover hasOutline verticalAlign="center" cards className="text-nowrap">
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader className="w-1">Reference Code</Table.ColHeader>
                  <Table.ColHeader>Status code</Table.ColHeader>
                  <Table.ColHeader>Path</Table.ColHeader>
                  <Table.ColHeader>Duration</Table.ColHeader>
                  <Table.ColHeader>Updated</Table.ColHeader>
                  <Table.ColHeader alignContent="center" className="w-1" />
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {peachPaymentCallLogs.map(peachPaymentCallLog => (
                  <Table.Row key={peachPaymentCallLog.id}>
                    <Table.Col>
                      <Text.Small muted>
                        <ShortId id={peachPaymentCallLog.id} />
                      </Text.Small>
                    </Table.Col>
                    <Table.Col>
                      <span>
                        <span
                          title={peachPaymentCallLog.result}
                          className={
                            'status-icon ' + (peachPaymentCallLog.statusCode === 200 ? 'bg-success' : 'bg-warning')
                          }
                        />
                        {peachPaymentCallLog.statusCode}
                      </span>
                    </Table.Col>
                    <Table.Col>{peachPaymentCallLog.localPath}</Table.Col>

                    <Table.Col>{peachPaymentCallLog.durationSeconds}</Table.Col>
                    <Table.Col>
                      <TimeAgo date={peachPaymentCallLog.updateDate} />
                    </Table.Col>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Card>
        </Grid.Col>
      </Grid.Row>
    );
  }
}
