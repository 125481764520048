import * as React from 'react';
import SiteWrapper from '../../components/SiteWrapper';
import { Page, Button, Dimmer, Alert, Header } from 'tabler-react';
import UsersList from './UsersList';
import gql from '../../../node_modules/graphql-tag';
import ApiService from '../../core/ApiService';
import { User } from './UserTypes';
import { ReactRouterProps } from '../../components/DefaultTypes';
import OnVisible from '../../components/OnVisible';
import { GraphQlPageHolder } from '../../components/OnVisible';

interface Props extends ReactRouterProps {}

interface State {
  users: User[];
  isLoading: boolean;
  isPaging: boolean;
  error: string;
  userFilter?: string;
}

const GET_USER = gql`
  query($search: String, $first: Int) {
    users {
      query(orderBy: "name", first: $first, search: $search) {
        items {
          id
          name
          image
          email
          roles
          updateDate
        }
        endCursor
        hasNextPage
      }
    }
  }
`;

const DELETE_USER = gql`
  mutation usersDelete($userId: String!) {
    users {
      delete(id: $userId) {
        id
      }
    }
  }
`;

class UsersPage extends React.Component<Props, State> {
  apiService: ApiService;
  state = {
    users: [],
    isLoading: true,
    isPaging: true,
    error: '',
    userFilter: null
  };
  queryResult: ZenObservable.Subscription;
  graphQlPager: GraphQlPageHolder;

  constructor(props) {
    super(props);
    this.apiService = new ApiService();
    this.graphQlPager = new GraphQlPageHolder(200, this.props.history, () => this.refreshData());
  }

  componentDidMount() {
    this.refreshData();
  }

  componentWillUnmount() {
    this.unSubscribeFromQuery();
  }

  unSubscribeFromQuery() {
    if (this.queryResult) {
      this.queryResult.unsubscribe();
      this.queryResult = null;
    }
  }
  refreshData() {
    this.setState({ isPaging: true });
    this.unSubscribeFromQuery();

    this.queryResult = this.apiService
      .watchQuery(GET_USER, this.graphQlPager.getPageOptions({ search: this.state.userFilter }))
      .subscribe((response: any) => {
        if (response.errors) {
          this.setState({
            isLoading: false,
            isPaging: false,
            error: 'Failed to read users from service.'
          });
        } else {
          if (response.data) {
            this.graphQlPager.setPageResult(response.data.users.query);
            this.setState({
              users: response.data.users.query.items,
              isLoading: false,
              isPaging: false,
              error: ''
            });
          }
        }
      });
  }

  add() {
    this.props.history.push('/user/add');
  }

  update(user: User) {
    this.props.history.push(`/user/${user.id}`);
  }

  remove(user: User, callback: any) {
    this.apiService
      .mutate(DELETE_USER, { userId: user.id })
      .then(() => {
        this.refreshData();
        callback();
      })
      .catch(() => {
        this.setState({
          error: `Failed to remove user '${user.name}' from service.`
        });
        callback();
      });
  }

  render() {
    var { users } = this.state;
    return (
      <SiteWrapper>
        <Page.Content>
          <Header.H1>
            Users
            <span style={{ marginLeft: '15px' }}>
              {' '}
              <Button onClick={() => this.add()} color="secondary" icon="plus" />
            </span>
          </Header.H1>
          {this.state.isLoading ? (
            <Dimmer active loader />
          ) : (
            <>
              <UsersList
                history={this.props.history}
                onFilterChange={filter => this.setState({ userFilter: filter }, () => this.refreshData())}
                users={users}
                update={m => this.update(m)}
                remove={(m, c) => this.remove(m, c)}
              />
              <OnVisible offset={30} isVisible={b => this.graphQlPager.isVisible(b)} />
              {this.state.isPaging ? <Dimmer active loader /> : <span />}
            </>
          )}
          {this.state.error && <Alert type="danger">{this.state.error}</Alert>}
        </Page.Content>
      </SiteWrapper>
    );
  }
}

export default UsersPage;
