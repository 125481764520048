import React from 'react';
import { Page, Form, Card, Dimmer } from 'tabler-react';
import ApiService from '../../core/ApiService';
import SiteWrapper from '../../components/SiteWrapper';
import gql from '../../../node_modules/graphql-tag';
import { Formik } from 'formik';
import { SortCode, SortCodeCreateUpdate } from './SortCodeTypes';
import { ReactRouterProps } from '../../components/DefaultTypes';
import { StandardFormFooter } from '../../components/Dialog';

const GET_SORTCODE = gql`
  query getSortCode($id: String!) {
    sortCodes {
      byId(id: $id) {
        id
        code
        description
        updateDate
      }
    }
  }
`;

const INSERT_SORTCODE = gql`
  mutation insertSortCode($code: String!, $description: String!) {
    sortCodes {
      insert(sortCode: { code: $code, description: $description }) {
        id
      }
    }
  }
`;

const UPDATE_SORTCODE = gql`
  mutation updateSortCode($id: String!, $code: String!, $description: String!) {
    sortCodes {
      update(id: $id, sortCode: { code: $code, description: $description }) {
        id
      }
    }
  }
`;

interface Props extends ReactRouterProps {}

interface State {
  sortCode: SortCode;
  isLoading: boolean;
  error: string;
}

export default class SortCodeUpdatePage extends React.Component<Props, State> {
  routeId: string;
  apiService: ApiService;
  state = {
    sortCode: null,
    isLoading: false,
    error: ''
  };

  constructor(props) {
    super(props);
    this.apiService = new ApiService();
  }

  componentDidMount() {
    this.routeId = this.props.match.params.id;
    if (!this.isAdd()) this.loadData(this.routeId);
  }

  isAdd() {
    return this.routeId === 'add';
  }

  loadData(id: string) {
    this.setState({ isLoading: true });
    this.apiService
      .query(GET_SORTCODE, { id: id })
      .then(response =>
        this.setState({
          sortCode: response.data.sortCodes.byId,
          error: response.data.sortCodes.byId === null ? `Failed could not load sortCode with id '${id}'.` : '',
          isLoading: false
        })
      )
      .catch(() =>
        this.setState({
          isLoading: false,
          error: `Failed could not load sortCode with id '${id}'.`
        })
      );
  }

  onCancel() {
    this.props.history.goBack();
  }

  onSave(props: any, result: any) {
    this.setState({ error: '' });

    this.apiService
      .mutate(this.isAdd() ? INSERT_SORTCODE : UPDATE_SORTCODE, {
        id: props.id,
        code: props.code,
        description: props.description
      })
      .then(() => {
        result.setSubmitting(false);
        this.props.history.goBack();
      })
      .catch(ex => {
        result.setSubmitting(false);
        const message = this.apiService.cleanErrorMessage(ex);
        this.isAdd()
          ? this.setState({ error: `Failed to add sortCode: ${message}` })
          : this.setState({ error: `Failed to update sortCode. ${message}` });
      });
  }

  validate(values: SortCodeCreateUpdate) {
    let errors: any = {};
    if (values.code && values.code.length !== 6) {
      errors.code = 'Code has to be 6 characted.';
    }
    if (!values.description) {
      errors.description = 'Description is required.';
    }
    return errors;
  }

  render() {
    const mainForm = (
      <Formik
        initialValues={this.state.sortCode}
        validate={this.validate}
        onSubmit={(props, result) => this.onSave(props, result)}
        render={({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Code</Form.Label>
              <Form.Input
                name="code"
                value={values.code}
                onChange={handleChange}
                invalid={touched.code && errors.code}
                feedback={touched.code && errors.code}
              />
              {/* invalid placeholder="Is Invalid" */}
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Input
                name="description"
                value={values.description}
                onChange={handleChange}
                invalid={touched.description && errors.description}
                feedback={touched.description && errors.description}
              />
              {/* invalid placeholder="Is Invalid" */}
            </Form.Group>
            {StandardFormFooter(isSubmitting, () => this.onCancel())}
          </Form>
        )}
      />
    );
    return (
      <SiteWrapper>
        <Page.Content title="SortCode">
          <Card>
            <Card.Body>{this.state.isLoading ? <Dimmer active loader /> : mainForm}</Card.Body>
            {this.state.error && (
              <Card.Alert color="danger">
                <strong>Whoops!</strong> {this.state.error}
              </Card.Alert>
            )}
          </Card>
        </Page.Content>
      </SiteWrapper>
    );
  }
}

/* scaffolding [
    {
      "FileName": "src\\App.tsx",
      "Indexline": "Route component",
      "InsertAbove": true,
      "InsertInline": false,
      "Lines": [
        "<Route path=\"/sortCode/:id\"  render={(routeProps) => ( this.isLoggedIn() ? (<SortCodeUpdatePage  {...routeProps}/>):(<Redirect to=\"/login\"/>) )}/>"
      ]
    },
    {
      "FileName": "src\\App.tsx",
      "Indexline": "SortCodeUpdatePage,",
      "InsertAbove": false,
      "InsertInline": false,
      "Lines": [
        "SortCodeUpdatePage,"
      ]
    },
    {
      "FileName": "pages\\index.js",
      "Indexline": "import Error404",
      "InsertAbove": true,
      "InsertInline": false,
      "Lines": [
        "import SortCodeUpdatePage from \"./sortCodes/SortCodeUpdatePage\";"
      ]
    },
    {
      "FileName": "pages\\index.js",
      "Indexline": "Error404,",
      "InsertAbove": true,
      "InsertInline": false,
      "Lines": [
        "SortCodeUpdatePage,"
      ]
    },
] scaffolding */
