import React from 'react';
import { Page, Form, Button, Card, Dimmer, Grid, Avatar } from 'tabler-react';
import ApiService from '../../core/ApiService';
import SiteWrapper from '../../components/SiteWrapper';
import gql from '../../../node_modules/graphql-tag';
import { Formik } from 'formik';
import { UserCreateUpdate } from './UserTypes';
import UserActivityList from './UserActivityList';
import { Route } from 'react-router-dom';
import { ReactRouterProps } from '../../components/DefaultTypes';
import UserAvatar, { UserAvatarFormat } from '../../components/UserAvatar';
import { StandardFormFooter } from '../../components/Dialog';
import AmountText, { Amount } from '../../components/AmountText';
import { StampCard } from 'tabler-react';

const GET_USER = gql`
  query getUser($id: String!) {
    groups {
      byUserId(userId: $id) {
        id
        name
        image
      }
    }

    users {
      activity(userId: $id, first: 10) {
        items {
          id
          group {
            id
            name
            image
          }
          message
          properties {
            key
            value
          }
          createDate
        }
      }
      byId(id: $id) {
        id
        email
        roles
        name
        activities
        segment
        defaultCurrency {
          currencySymbol
          isoCode
          name
        }
        receivingAccounts {
          id
          sortCode
          accountName
          accountNumber
          sortCodeDetail {
            description
            id
          }
        }
        cards {
          token
          brand
          last4Digits
          holder
          expiryMonth
          expiryYear
        }
        connections {
          id
          name
          status
          user {
            id
            name
            image
          }
        }
        inAppPayments {
          totalPaymentsThisMonth {
            cents
            currencyIso
          }
          totalPaymentsToday {
            cents
            currencyIso
          }
          isBlocked
        }

        createDate
        updateDate
      }
    }
  }
`;

const INSERT_USER = gql`
  mutation insertUser(
    $userName: String!
    $userEmail: String!
    $password: String
    $segment: String
    $userRoles: [String!]
  ) {
    users {
      insert(user: { name: $userName, email: $userEmail, password: $password, segment: $segment, roles: $userRoles }) {
        id
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser(
    $id: String!
    $userName: String!
    $userEmail: String!
    $password: String
    $segment: String
    $userRoles: [String!]
  ) {
    users {
      update(
        id: $id
        user: { name: $userName, email: $userEmail, password: $password, segment: $segment, roles: $userRoles }
      ) {
        id
      }
    }
  }
`;

class UserType {
  id: string;
  name: string;
  email: string;
  image: string;
  password: string;
  roles: string[];
  updateDate: Date;
  connections: Array<any>;
  receivingAccounts: Array<any>;
  inAppPayments: {
    totalPaymentsThisMonth: Amount,
    totalPaymentsToday: Amount,
    isBlocked: boolean,
    blockedReason: string
  };

  cards: Array<any>;
}

interface Props extends ReactRouterProps {}

interface State {
  user?: UserType;
  isLoading: boolean;
  error: string;
  activity: Array<any>;
  groups: Array<any>;
}

export default class UserUpdatePage extends React.Component<Props, State> {
  routeId: string;
  apiService: ApiService;
  state = {
    user: new UserType(),
    activity: [],
    groups: [],
    isLoading: false,
    error: ''
  };

  constructor(props) {
    super(props);
    this.apiService = new ApiService();
  }

  componentDidMount() {
    this.routeId = this.props.match.params.id;
    if (!this.isAdd()) this.loadData(this.routeId);
  }

  componentDidUpdate() {
    if (this.routeId !== this.props.match.params.id) {
      this.componentDidMount();
    }
  }

  isAdd() {
    return this.routeId === 'add';
  }

  loadData(id: string) {
    this.setState({ isLoading: true });
    this.apiService
      .query(GET_USER, { id: id })
      .then(response =>
        this.setState({
          user: response.data.users.byId,
          groups: response.data.groups.byUserId,
          activity: response.data.users.activity.items,
          error: response.data.users.byId === null ? `Failed could not load user with id '${id}'.` : '',
          isLoading: false
        })
      )
      .catch(response =>
        this.setState({
          isLoading: false,
          error: `Failed could not load user with id '${id}'. [${response.message}]`
        })
      );
  }

  onCancel() {
    this.props.history.goBack();
  }

  onSave(props: any, result: any) {
    this.setState({ error: '' });
    this.apiService
      .mutate(this.isAdd() ? INSERT_USER : UPDATE_USER, {
        id: props.id,
        userName: props.name,
        userEmail: props.email,
        userRoles: props.roles,
        password: props.password,
        segment: props.segment
      })
      .then(response => {
        result.setSubmitting(false);
        this.setState({ error: '' });
        this.props.history.goBack();
      })
      .catch(ex => {
        result.setSubmitting(false);
        const message = this.apiService.cleanErrorMessage(ex);
        console.error(ex);
        this.isAdd()
          ? this.setState({ error: `Failed to add user: ${message}` })
          : this.setState({ error: `Failed to update user. ${message}` });
      });
  }

  validate(values: UserCreateUpdate) {
    let errors: any = {};
    if (!values.name) {
      errors.name = 'Name is required.';
    }
    if (!values.email) {
      errors.email = 'Email is required.';
    }
    return errors;
  }

  updateCheckBoxes(update: any) {
    console.log('update', update);
  }
  renderConnection(connection) {
    return (
      <Card>
        <Card.Header>
          <Card.Title>
            <UserAvatar size="sm" format={UserAvatarFormat.ImageName} user={connection.user} />
          </Card.Title>
        </Card.Header>
      </Card>
    );
  }

  renderAccount(account) {
    return (
      <Card key={account.id}>
        <Card.Body>
          <div className="sortCodeDetail_description " style={{ fontSize: 'larger' }}>
            {account.sortCodeDetail.description}
          </div>
          <div className="accountName">{account.accountName}</div>
          <div className="accountNumber">{account.accountNumber}</div>
          <div className="sortCode">{account.sortCode}</div>
        </Card.Body>
      </Card>
    );
  }
  renderCard(card) {
    return (
      <Card>
        <Card.Body>
          <div className="last4Digits" style={{ fontSize: 'larger' }}>
            <i className={'payment ' + (card.brand !== 'VISA' ? 'payment-mastercard' : 'payment-visa')} />
          </div>
          <div className="last4Digits" style={{ fontSize: 'larger' }}>
            XXXX XXXX XXXX {card.last4Digits}
          </div>
          <div className="holder small text-muted">{card.holder}</div>
          <div className="expiryMonth small text-muted">
            {card.expiryMonth}/{card.expiryYear}
          </div>
        </Card.Body>
      </Card>
    );
  }
  render() {
    const roles = ['Admin', 'Guest'];
    const mainForm = (
      <Formik
        initialValues={this.state.user}
        validate={this.validate}
        onSubmit={(props, result) => this.onSave(props, result)}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }: any) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Input
                name="name"
                value={values.name}
                onChange={handleChange}
                invalid={touched.name && errors.name}
                feedback={touched.name && errors.name}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Input
                name="email"
                value={values.email}
                onChange={handleChange}
                invalid={touched.email && errors.email}
                feedback={touched.email && errors.email}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Segment</Form.Label>
              <Form.Input
                name="segment"
                type="segment"
                value={values.segment || ''}
                onChange={handleChange}
                invalid={touched.segment && errors.segment}
                feedback={touched.segment && errors.segment}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Input
                name="password"
                type="password"
                value={values.password}
                onChange={handleChange}
                invalid={touched.password && errors.password}
                feedback={touched.password && errors.password}
              />
              <Form.Group>
                <Form.Label>Currency</Form.Label>
                <div>
                  <Form.Input
                    name="currency"
                    type="text"
                    readonly
                    value={`${values.defaultCurrency?.currencySymbol} ${values.defaultCurrency?.name}`}
                  />
                </div>
              </Form.Group>
            </Form.Group>

            <Form.Group label="Roles">
              {roles.map(ro => (
                <Form.Checkbox
                  key={ro}
                  label={ro}
                  name="roles"
                  value={ro}
                  checked={values.roles !== undefined ? values.roles.indexOf(ro) > -1 : false}
                  onChange={() => {
                    var valueRoles = values.roles || [];

                    if (valueRoles.includes(ro)) {
                      const nextValue = valueRoles.filter(value => value !== ro);
                      setFieldValue('roles', nextValue);
                    } else {
                      const nextValue = valueRoles.concat(ro);
                      setFieldValue('roles', nextValue);
                    }
                  }}
                />
              ))}
            </Form.Group>

            {StandardFormFooter(isSubmitting, () => this.onCancel())}
          </Form>
        )}
      />
    );

    return (
      <SiteWrapper>
        <Page.Content title="User">
          <Card>
            <Card.Body>{this.state.isLoading ? <Dimmer active loader /> : mainForm}</Card.Body>
            {this.state.error && (
              <Card.Alert color="danger">
                <strong>Whoops!</strong> {this.state.error}
              </Card.Alert>
            )}
          </Card>
          {!this.state.user?.inAppPayments ? (
            <div></div>
          ) : (
            <div>
              <Grid.Row>
                <Grid.Col>
                  {' '}
                  <StampCard
                    color="green"
                    icon="eye"
                    footer={`in app payments this month`}
                    header={
                      <a href="/users">
                        <AmountText amount={this.state.user.inAppPayments.totalPaymentsThisMonth}></AmountText>
                      </a>
                    }
                  />
                </Grid.Col>
                <Grid.Col>
                  {' '}
                  <StampCard
                    color="yellow"
                    icon="droplet"
                    footer={`in app payments today`}
                    header={
                      <a href="/users">
                        <AmountText amount={this.state.user.inAppPayments.totalPaymentsToday}></AmountText>
                      </a>
                    }
                  />
                </Grid.Col>
              </Grid.Row>
            </div>
          )}

          {this.state.isLoading || !this.state.user || !this.state.user.name ? (
            <span />
          ) : (
            <div>
              <Button
                className="float-right"
                outline={true}
                color="primary"
                onClick={() => this.props.history.push(`/user/${this.props.match.params.id}/balance`)}
              >
                View Balances
              </Button>
              {!this.state.groups || (
                <div>
                  <h3>Groups</h3>
                  <Route
                    render={({ history }) => (
                      <Grid.Row cards={true}>
                        {this.state.groups.map(group => (
                          <Grid.Col key={group.id} lg={4}>
                            <Card>
                              <Card.Body>
                                <a
                                  href={`/group/${group.id}`}
                                  onClick={e => {
                                    history.push(`/group/${group.id}`);
                                    e.preventDefault();
                                  }}
                                >
                                  <Avatar imageURL={group.image} />
                                  &nbsp;
                                  {group.name}
                                </a>
                              </Card.Body>
                            </Card>
                          </Grid.Col>
                        ))}
                      </Grid.Row>
                    )}
                  />
                </div>
              )}
              {!this.state.activity || (
                <div>
                  {this.state.activity.length < 5 ? (
                    <div />
                  ) : (
                    <Route
                      render={({ history }) => (
                        <Button
                          className="float-right"
                          outline
                          color="primary"
                          onClick={e => {
                            history.push(`/user/${this.state.user.id}/feed`);
                            e.preventDefault();
                          }}
                        >
                          More
                        </Button>
                      )}
                    />
                  )}
                  <h3>Activity</h3>

                  <UserActivityList activities={this.state.activity} />
                </div>
              )}
              {!this.state.user.connections || (
                <div>
                  <h3>Connections</h3>
                  <Grid.Row cards={true}>
                    {this.state.user.connections
                      .filter(x => x.status === 'Active')
                      .map(connection => (
                        <Grid.Col key={connection.id} lg={3}>
                          {this.renderConnection(connection)}
                        </Grid.Col>
                      ))}
                  </Grid.Row>
                </div>
              )}

              {!this.state.user.receivingAccounts || (
                <div>
                  <h3>Receiving accounts</h3>
                  <Grid.Row cards={true}>
                    {this.state.user.receivingAccounts.map(account => (
                      <Grid.Col key={account.id} lg={6}>
                        {this.renderAccount(account)}
                      </Grid.Col>
                    ))}
                  </Grid.Row>
                </div>
              )}

              {!this.state.user.cards || (
                <div>
                  <h3>Cards</h3>
                  <Grid.Row cards={true}>
                    {this.state.user.cards.map(card => (
                      <Grid.Col key={card.token} lg={6}>
                        {this.renderCard(card)}
                      </Grid.Col>
                    ))}
                  </Grid.Row>
                </div>
              )}
            </div>
          )}
        </Page.Content>
      </SiteWrapper>
    );
  }
}
