import * as React from 'react';
import SiteWrapper from '../../components/SiteWrapper';
import { Page, Dimmer, Alert, Header, Grid, Table, Card, Icon } from 'tabler-react';
import gql from '../../../node_modules/graphql-tag';
import ApiService from '../../core/ApiService';
import { ReactRouterProps } from '../../components/DefaultTypes';
import { Route } from 'react-router-dom';

interface Props extends ReactRouterProps {}

interface State {
  isLoading: boolean;
  reportinNames: string[];
  error: string;
}

const GET_REPORT_ALLNAMES = gql`
  query {
    reports {
      getAllNames
    }
  }
`;

const GET_REPORT_BYNAME = gql`
  query($name: String!, $parameters: [ReportInputParameters]) {
    reports {
      data: byName(name: $name, parameters: $parameters) {
        associatedReportName
        completedDate
        columns
        rows
      }
    }
  }
`;

export const getReportByName = (apiService: ApiService, name: string) => {
  return apiService.query(GET_REPORT_BYNAME, { name: name });
};

const getAllReportNames = (apiService: ApiService) => {
  return apiService.query(GET_REPORT_ALLNAMES);
};

export default class ReportsPage extends React.Component<Props, State> {
  state = {
    isLoading: false,
    error: undefined,
    reportinNames: [],
    csvData: undefined
  };
  componentDidMount() {
    this.refreshData();
  }
  refreshData(): any {
    this.setState({ isLoading: true });
    getAllReportNames(new ApiService())
      .then(result => {
        this.setState({ isLoading: false, reportinNames: result.data.reports.getAllNames.sort() });
      })
      .catch(exc => {
        console.log(exc);
        this.setState({ isLoading: false, error: 'Failed to get all report names' });
      });
  }
  downloadReport(name: string) {
    this.setState({ isLoading: true });
    getReportByName(new ApiService(), name)
      .then(result => {
        const csvData = [result.data.reports.data.columns, ...result.data.reports.data.rows];

        const csvContent = csvData.map(e => (e || '').join(',')).join('\n');
        var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        const fileName = `${name}.csv`;
        if (navigator.msSaveBlob) {
          // IE
          navigator.msSaveBlob(blob, fileName);
        } else {
          var link = document.createElement('a');
          if (link.download !== undefined) {
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }

        this.setState({ isLoading: false });
      })
      .catch(exc => {
        console.log(exc);
        this.setState({ isLoading: false, error: 'Failed to download' });
      });
  }

  reportList() {
    return (
      <Route
        render={({ history }) => (
          <Grid.Row cards deck>
            <Grid.Col width={12}>
              <Card>
                <Table responsive highlightRowOnHover hasOutline verticalAlign="center" cards className="text-nowrap">
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>Description</Table.ColHeader>
                      <Table.ColHeader alignContent="right" className="w-1" />
                      <Table.ColHeader alignContent="right" className="w-1" />
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {this.state.reportinNames.map(key => {
                      return (
                        <Table.Row key={key}>
                          <Table.Col>{key} </Table.Col>
                          <Table.Col>
                            <Icon
                              className="hoverable"
                              prefix="fe"
                              name="activity"
                              alt="Open"
                              onClick={e => {
                                history.push(`/report/${key}`);
                                e.preventDefault();
                              }}
                            />
                          </Table.Col>

                          <Table.Col>
                            <Icon
                              className="hoverable"
                              prefix="fe"
                              name="download"
                              onClick={() => {
                                this.downloadReport(key);
                              }}
                            />
                          </Table.Col>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </Card>
            </Grid.Col>
          </Grid.Row>
        )}
      />
    );
  }

  render() {
    return (
      <SiteWrapper>
        <Page.Content>
          <Header.H1>Reports</Header.H1>
          {this.state.isLoading ? <Dimmer active loader /> : this.reportList()}
          {this.state.error && <Alert type="danger">{this.state.error}</Alert>}
        </Page.Content>
      </SiteWrapper>
    );
  }
}
