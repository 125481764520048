import React from 'react';
import { Avatar } from 'tabler-react';
import { Route } from 'react-router-dom';
import truncateWithEllipses from './TruncateWithEllipses';

interface User {
  id: string
  image: string,
  name: string,
}

interface Member {
  userId: string
  image: string,
  name: string,
}

interface Props {
  user?: User,
  member?: Member,
  format?: UserAvatarFormat,
  size?: string
}

export enum UserAvatarFormat {
  ImageBreakName = 0,
  ImageOnly,
  Name,
  ImageName,
}

export default class UserAvatar extends React.Component<Props> {
  renderImageContent(image, name, size) {
    if (image) {
      return <Avatar size={size} className="d-block margin-middle" imageURL={image} />
    }

    const sizeClass = `${size}-avatar-img`
    const isFirstCharLetter = name ? /^[a-zA-Z]/.test(name) : false;

    let displayLetter = isFirstCharLetter ? name.substring(0, 1) : "?";
    return <div data-letters={displayLetter} className={sizeClass}>{displayLetter}</div>
  }

  renderAvatarAndName(context) {

    var image = context.image;
    var name = context.name;


    var size = this.props.size || 'md';
    if (this.props.format === UserAvatarFormat.ImageName) {
      //[Image - Name]
      return (<span><div style={{ float: "left" }}>{this.renderImageContent(image, name, size)}</div>
        <div style={{ float: "left" }}>&nbsp; {truncateWithEllipses(name)}</div></span>)
    }
    else if (this.props.format === UserAvatarFormat.Name) {
      //[Name]
      return (<React.Fragment>{name}</React.Fragment>);
    }
    else if (this.props.format === UserAvatarFormat.ImageOnly) {
      //[Image-Only]
      return  (<span>
      <div ><div style={{ float: "left" }}>{this.renderImageContent(image, name, size)}</div> {this.props.children}</div></span>);
    }
    else {
      //[Image \n Name]
      return (<span><Avatar size={size} className="d-block margin-middle" imageURL={image} /> <div>{name}</div></span>);
    }
  }
  render() {
    var context: any = this.props.user || this.props.member || {};
    var userid = this.props.user ? this.props.user.id : context.userId;
    var url = userid !==null ? `/user/${userid}` : `/users/`;

    return (
      <Route
        render={({ history }) => (
          <div >
            {userid?(<a
              href={url}
              onClick={e => {
                history.push(url);
                e.preventDefault();
              }}
            >
              {this.renderAvatarAndName(context)}
            </a>):this.renderAvatarAndName(context)}
            

          </div>
        )}
      />
    );
  }
}
