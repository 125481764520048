import * as React from 'react';
import { Card, Text, Table } from 'tabler-react';
import AmountText from '../../components/AmountText';
import TimeAgo from 'react-timeago';
import ShortId from '../../components/ShortId';
import { Route } from 'react-router-dom';
import { Transactions, Group, Portions, GroupMembers } from './GroupTypes';

interface Props {
  transactions: Array<Transactions>;
  group: Group;
}

interface State {}

export function buildTransactionType(transaction: Transactions) {
  return (
    <span>
      {transaction.type}
      {transaction.type === 'Settle' ? (
        transaction.isInAppSettlement ? (
          <i className="fe  fe-log-in" style={{ marginLeft: 5 }} title="Settle in app" />
        ) : (
          <i className="fe  fe-log-out" style={{ marginLeft: 5 }} title="Settle external" />
        )
      ) : (
        ''
      )}
    </span>
  );
}

export default class GroupTransactionRecordsCard extends React.Component<Props, State> {
  state = {};

  getMember(memberId: string): GroupMembers {
    const member = this.props.group.members.filter(item => {
      return item.id === memberId;
    });
    return member.length === 0 ? null : member[0];
  }

  getInvolvedString(portions: Portions[]) {
    const member = portions
      .filter(item => {
        return item.size > 0;
      })
      .map(x => this.getMember(x.memberId))
      .filter(x => x !== null)
      .map(x => x.name.split(' ')[0])
      .join(', ');
    return member;
  }

  buildDescription(transaction: Transactions) {
    const responsibleMemb = this.getMember(transaction.responsibleMemberId);
    if (responsibleMemb !== null) {
      var responsibleMember = responsibleMemb.name;
      const splitBy = this.getInvolvedString(transaction.split.portions);
      var combined = ' paid for ';
      switch (transaction.type.toLowerCase()) {
        case 'settle':
          combined = transaction.isInAppSettlement ? ' settled in app with ' : ' settled externaly with ';
          break;
        case 'planned':
          combined = ' planned with ';
          break;
      }
      let created = '';
      if (responsibleMemb.userId !== transaction.createdByUser.id) {
        created = ` (created by ${transaction.createdByUser.name})`;
      }
      return responsibleMember + combined + splitBy + created;
    }
    return '';
  }

  render() {
    var dateFormatter = new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    });
    var { transactions } = this.props;
    return (
      <Card>
        <Card.Body>
          <Table responsive highlightRowOnHover hasOutline verticalAlign="center" cards className="text-nowrap">
            <Table.Header>
              <Table.Row>
                <Table.ColHeader className="w-1">Id</Table.ColHeader>
                <Table.ColHeader className="w-1">Date</Table.ColHeader>
                <Table.ColHeader>Description</Table.ColHeader>
                <Table.ColHeader>Type</Table.ColHeader>
                <Table.ColHeader>Amount</Table.ColHeader>
                <Table.ColHeader>Updated</Table.ColHeader>
              </Table.Row>
            </Table.Header>
            <Route
              render={({ history }) => (
                <Table.Body>
                  {transactions
                    .slice()
                    .reverse()
                    .map(transaction => (
                      <Table.Row
                        key={transaction.id}
                        onClick={() => history.push(`/transactionRecord/${transaction.id}`)}
                      >
                        <Table.Col>
                          <Text.Small muted>
                            <ShortId id={transaction.id} />
                          </Text.Small>
                        </Table.Col>
                        <Table.Col>{dateFormatter.format(new Date(transaction.date))}</Table.Col>
                        <Table.Col>
                          <Text>{transaction.description}</Text>
                          <Text.Small muted>{this.buildDescription(transaction)}</Text.Small>
                        </Table.Col>
                        <Table.Col>{buildTransactionType(transaction)}</Table.Col>
                        <Table.Col>
                          <AmountText amount={transaction.amount} invert={true} />
                        </Table.Col>
                        <Table.Col>
                          <TimeAgo date={transaction.updateDate} />
                        </Table.Col>
                      </Table.Row>
                    ))}
                </Table.Body>
              )}
            />
          </Table>
        </Card.Body>
      </Card>
    );
  }
}
