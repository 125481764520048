import React from 'react';
import { Dimmer, Table, Button } from 'tabler-react';

const first = (array: Array<any>) => {
  if (array.length === 0) {
    throw array;
  }

  return array[0];
}

interface Props {
  data: Array<any>,
  onRowClick?: (evt: any, item: any) => any,
  onRowClickText?: string,
  isLoading?: boolean,
  onConfirmClick?: () => any,
  excludeFields?: Array<string>,
  emptyItemsMessage?: string
}

const List = ({ data, onRowClick, onRowClickText = "Process", isLoading = false, excludeFields = ["id"], emptyItemsMessage = "No items to display." }: Props) => {
  if (!!data === false || data.length === 0) {
    return <h3>{emptyItemsMessage}</h3>
  }

  const itemKeys = Object.keys(first(data));
  const keys = itemKeys.filter(key => !excludeFields.find(excludes => key === excludes));

  return (
    <Dimmer active={isLoading} loader>
      <Dimmer.Content>
        <Table>
          <Table.Header>
            <Table.Row>
              {keys.map((key) => <Table.ColHeader>{key}</Table.ColHeader>)}
              <Table.ColHeader></Table.ColHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map(item =>
              (<Table.Row>
                {keys.map((key) => <Table.Col>{item[key]}</Table.Col>)}
                {onRowClick &&
                  <Table.Col alignContent="right">
                    <Button
                      className="settlement-button"
                      onClick={(evt) => onRowClick(evt, item)}
                      color="warning"
                    >
                      {onRowClickText}
                    </Button>
                  </Table.Col>}
              </Table.Row>)
            )}
          </Table.Body>
        </Table>
      </Dimmer.Content>
    </Dimmer>)
}

export default List;