import React from 'react';
import { Avatar, Grid, Text } from 'tabler-react';
import AmountText, { Amount } from '../../components/AmountText';

export interface BalanceSummary {
  totalIAmOwed: Amount;
  totalOwe: Amount;
  balance: Amount;
}

interface Props {
  balanceSummary: BalanceSummary;
  title: string;
  image?: string;
  url?: string;
  excludeImage?: boolean;
}

type State = {};

export default class BalanceSummaryCard extends React.Component<Props, State> {
  state = {};

  renderImageContent(image, name, size) {
    if (image) {
      return <Avatar size={size} className="d-block margin-middle" imageURL={image} />;
    }

    const sizeClass = `${size}-avatar-img`;
    const isFirstCharLetter = name ? /^[a-zA-Z]/.test(name) : false;

    let displayLetter = isFirstCharLetter ? name.substring(0, 1) : '?';
    return (
      <div data-letters={displayLetter} className={sizeClass}>
        {displayLetter}
      </div>
    );
  }

  render() {
    const { balanceSummary, image, title, excludeImage, url } = this.props;

    return (
      <ul className="list-unstyled list-separated">
        <li className="list-separated-item">
          <Grid.Row className="align-items-center">
            {!!excludeImage === false && (
              <Grid.Col auto>
                <a href={url || '#'}>{this.renderImageContent(image, title, 'md')}</a>
              </Grid.Col>
            )}
            <Grid.Col>
              <h4>{title}</h4>
            </Grid.Col>
            <Grid.Col>
              {balanceSummary.totalIAmOwed.cents ? (
                <Text.Small muted className="d-block item-except h-1x">
                  I am owed {balanceSummary.totalIAmOwed.formatted}
                </Text.Small>
              ) : (
                <></>
              )}

              {balanceSummary.totalOwe.cents ? (
                <Text.Small muted className="d-block item-except h-1x">
                  I owe {balanceSummary.totalOwe.formatted}
                </Text.Small>
              ) : (
                <></>
              )}
            </Grid.Col>
            <Grid.Col>
              <div className="float-right">
                <AmountText amount={balanceSummary.balance} />
                <Text.Small muted className="d-block item-except h-1x">
                  Balance
                </Text.Small>
              </div>
            </Grid.Col>
          </Grid.Row>
        </li>
      </ul>
    );
  }
}
