import React from 'react';
import { Page, Card, Dimmer, Grid, Avatar, Button } from 'tabler-react';
import ApiService from '../../core/ApiService';
import SiteWrapper from '../../components/SiteWrapper';
import gql from '../../../node_modules/graphql-tag';
import TimeAgo from 'react-timeago';
import { Group } from './GroupTypes';
import GroupMemberCard from './GroupMemberCard';
import GroupMemberToMemberBalance from './GroupMemberToMemberBalance';
import GroupTransactionRecordsCard from './GroupTransactionRecordsCard';
import { ReactRouterProps } from '../../components/DefaultTypes';
import { Route } from 'react-router-dom';

const GET_GROUP = gql`
  fragment amountData on Amount {
    cents
    currencyIso
    formatted
  }
  query getGroup($id: String!) {
    transactionRecords {
      byGroupIds(groups: [$id]) {
        id
        date
        type
        description
        amount {
          ...amountData
        }
        plannedAmount {
          ...amountData
        }
        createdByUser {
          id
          name
        }
        isInAppSettlement
        responsibleMemberId
        updateDate
        split {
          type
          portions {
            memberId
            size
            amount {
              ...amountData
            }
            responsible {
              ...amountData
            }
          }
        }
      }
    }
    groups {
      byId(id: $id) {
        id
        name
        purpose
        image
        updateDate
        hasTransactions
        hasSimplifiedBalances
        summary {
          totalEstimate {
            ...amountData
          }
          totalSpent {
            ...amountData
          }
        }
        memberToMemberBalance {
          fromMemberId
          toMemberId
          amount {
            ...amountData
          }
        }
        members {
          id
          name
          userId
          status
          image
          hasReceivingAccount
          summary {
            balance {
              ...amountData
            }
            totalSpent {
              ...amountData
            }
            totalOwe {
              ...amountData
            }
            totalIAmOwed {
              ...amountData
            }
          }
        }
      }
    }
  }
`;

const INSERT_GROUP = gql`
  mutation insertGroup($purpose: String!, $description: String!) {
    groups {
      insert(group: { purpose: $purpose, description: $description }) {
        id
      }
    }
  }
`;

const UPDATE_GROUP = gql`
  mutation updateGroup($id: String!, $purpose: String!, $description: String!) {
    groups {
      update(id: $id, group: { purpose: $purpose, description: $description }) {
        id
      }
    }
  }
`;

const SET_NETSETTLE_GROUP = gql`
  mutation setSimplifiedBalances($id: String!, $enabled: Boolean!) {
    groups {
      setSimplifiedBalances(groupId: $id, isEnabled: $enabled) {
        id
      }
    }
  }
`;

interface Props extends ReactRouterProps {
  history: any;
  match: {
    params: any
  };
}

interface State {
  group: Group;
  isLoading: boolean;
  isLoadingNetSettle: boolean;
  error: string;
  transactions: Array<any>;
}

export default class GroupUpdatePage extends React.Component<Props, State> {
  routeId: string;
  apiService: ApiService;
  state = {
    group: new Group(),
    transactions: [],
    isLoading: false,
    isLoadingNetSettle: false,
    error: ''
  };

  constructor(props) {
    super(props);
    this.apiService = new ApiService();
  }

  componentDidMount() {
    this.routeId = this.props.match.params.id;
    if (!this.isAdd()) {
      this.setState({ isLoading: true });
      this.loadData(this.routeId);
    }
  }

  isAdd() {
    return this.routeId === 'add';
  }

  loadData(id: string): Promise<any> {
    return this.apiService
      .query(GET_GROUP, { id: id })
      .then(response =>
        this.setState({
          group: response.data.groups.byId,
          transactions: response.data.transactionRecords.byGroupIds,
          error: response.data.groups.byId === null ? `Failed could not load group with id '${id}'.` : '',
          isLoading: false
        })
      )
      .catch(() =>
        this.setState({
          isLoading: false,
          error: `Failed could not load group with id '${id}'.`
        })
      );
  }

  onCancel() {
    this.props.history.goBack();
  }

  onSave(props: any, result: any) {
    console.log({
      id: props.id,
      code: props.code,
      description: props.description
    });
    this.setState({ error: '' });

    this.apiService
      .mutate(this.isAdd() ? INSERT_GROUP : UPDATE_GROUP, {
        id: props.id,
        code: props.code,
        description: props.description
      })
      .then(() => {
        result.setSubmitting(false);
        this.setState({ error: '' });
        this.props.history.goBack();
      })
      .catch(ex => {
        result.setSubmitting(false);
        const message = this.apiService.cleanErrorMessage(ex);
        this.isAdd()
          ? this.setState({ error: `Failed to add group: ${message}` })
          : this.setState({ error: `Failed to update group. ${message}` });
      });
  }

  validate(values: any) {
    let errors: any = {};

    if (!values.name) {
      errors.name = 'Name is required.';
    }
    return errors;
  }

  SetNetSettle(enable: boolean) {
    this.setState({ isLoadingNetSettle: true });
    this.apiService
      .mutate(SET_NETSETTLE_GROUP, {
        id: this.state.group.id,
        enabled: enable
      })
      .then(_ => {
        this.loadData(this.state.group.id).then(() => {
          this.setState({ isLoadingNetSettle: false, error: '' });
        });
      })
      .catch(() => {
        this.setState({ isLoadingNetSettle: false, error: 'Failed to set net settle state.' });
      });
  }

  render() {
    var { group } = this.state;
    const mainForm = (
      <div>
        <h3>
          <Avatar imageURL={group.image} />
          <span style={{ marginLeft: '10px' }}>{group.name}</span>
        </h3>
        {!group.purpose || (
          <div>
            <strong>Purpose:</strong> {group.purpose}
          </div>
        )}
        <div>
          <strong>Last updated:</strong> <TimeAgo date={group.updateDate} />
        </div>
        <div>
          <strong>HasTransactions:</strong> {!group.hasTransactions || <i className="fe fe-check" />}
        </div>
      </div>
    );

    return (
      <SiteWrapper>
        <Page.Content title="Group">
          <Card>
            <Card.Body>{this.state.isLoading ? <Dimmer active loader /> : mainForm}</Card.Body>
            {this.state.error && (
              <Card.Alert color="danger">
                <strong>Whoops!</strong> {this.state.error}
              </Card.Alert>
            )}
          </Card>
          {this.state.isLoading || !this.state.group.members ? (
            <span />
          ) : (
            <div>
              <Route
                render={({ history }) => (
                  <Button
                    className="float-right"
                    outline
                    color="primary"
                    onClick={e => {
                      history.push(`/group/${this.state.group.id}/member-merge`);
                      e.preventDefault();
                    }}
                  >
                    Merge members
                  </Button>
                )}
              />
              <h3>Members</h3>
              <Grid.Row cards={true}>
                {this.state.group.members
                  .filter(m => m.status !== 'InActive')
                  .map((member, i) => (
                    <Grid.Col key={i} md={12} lg={6}>
                      <GroupMemberCard member={member} />
                    </Grid.Col>
                  ))}
              </Grid.Row>
              <div>
                <Button
                  className="float-right"
                  outline
                  loading={this.state.isLoadingNetSettle}
                  color="primary"
                  onClick={e => {
                    this.SetNetSettle(!this.state.group.hasSimplifiedBalances);
                    e.preventDefault();
                  }}
                >
                  {this.state.group.hasSimplifiedBalances ? 'Disable' : 'Enable'} net settlement
                </Button>
              </div>

              <h3>Member to member</h3>
              <Grid.Row cards={true}>
                {this.state.group.memberToMemberBalance
                  .filter(m => m.amount.cents !== 0)
                  .map((member2MemberBalance, i) => (
                    <Grid.Col key={i} lg={6}>
                      <GroupMemberToMemberBalance members={this.state.group.members} balance={member2MemberBalance} />
                    </Grid.Col>
                  ))}
              </Grid.Row>
              <h3>Transactions</h3>
              <Grid.Row cards={true}>
                <Grid.Col lg={12}>
                  <GroupTransactionRecordsCard group={this.state.group} transactions={this.state.transactions} />
                </Grid.Col>
              </Grid.Row>
            </div>
          )}
        </Page.Content>
      </SiteWrapper>
    );
  }
}
