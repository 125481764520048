import React from 'react';
import { Page, Card, Dimmer, Grid, Button, Form } from 'tabler-react';
import ApiService from '../../core/ApiService';
import SiteWrapper from '../../components/SiteWrapper';
import gql from '../../../node_modules/graphql-tag';
import GroupMemberCard from './GroupMemberCard';
import { ReactRouterProps } from '../../components/DefaultTypes';
import { Group } from './GroupTypes';

const GET_GROUP = gql`
  fragment amountData on Amount {
    cents
    currencyIso
    formatted
  }
  query getGroup($id: String!) {
    groups {
      byId(id: $id) {
        id
        name
        members {
          id
          name
          userId
          status
          image
          hasReceivingAccount
          summary {
            balance {
              ...amountData
            }
            totalSpent {
              ...amountData
            }
            totalOwe {
              ...amountData
            }
            totalIAmOwed {
              ...amountData
            }
          }
        }
      }
    }
  }
`;

const MERGE_MEMBERS = gql`
  mutation merge($groupId: String!, $removeMemberId: String!, $copyToMemberId: String!) {
    groups {
      mergeMembers(groupId: $groupId, removeMemberId: $removeMemberId, copyToMemberId: $copyToMemberId) {
        id
      }
    }
  }
`;

interface Props extends ReactRouterProps {
  history: any;
  match: {
    params: any
  };
}

interface State {
  group: Group;
  isLoading: boolean;
  error: string;
  fromMemberId: string;
  toMemberId: string;
  updateLoading: boolean;
}

export default class GroupMemberMergePage extends React.Component<Props, State> {
  routeId: string;
  apiService: ApiService;

  state = {
    group: new Group(),
    fromMemberId: null,
    toMemberId: null,
    transactions: [],
    isLoading: false,
    updateLoading: false,
    error: ''
  };

  constructor(props) {
    super(props);
    this.apiService = new ApiService();
  }

  componentDidMount() {
    this.routeId = this.props.match.params.id;
    this.loadData(this.routeId);
  }

  loadData(id: string) {
    this.setState({ isLoading: true });
    this.apiService
      .query(GET_GROUP, { id: id })
      .then(response =>
        this.setState({
          group: response.data.groups.byId,
          error: response.data.groups.byId === null ? `Failed could not load group with id '${id}'.` : '',
          isLoading: false
        })
      )
      .catch(() =>
        this.setState({
          isLoading: false,
          error: `Failed could not load group with id '${id}'.`
        })
      );
  }

  onSave() {
    this.setState({ updateLoading: true, error: '' });
    this.apiService
      .mutate(MERGE_MEMBERS, {
        groupId: this.state.group.id,
        removeMemberId: this.state.fromMemberId,
        copyToMemberId: this.state.toMemberId
      })
      .then(() => {
        this.setState({ updateLoading: false, error: '' });
        this.props.history.goBack();
      })
      .catch(ex => {
        const message = this.apiService.cleanErrorMessage(ex);
        this.setState({ updateLoading: false, error: `Failed to merge members. ${message}` });
      });
  }

  selectFrom(newValue: string) {
    this.setState({
      fromMemberId: newValue
    });
  }

  showMembers(selectedId, onSelect, filter) {
    var { group } = this.state;
    var members = group.members.filter(m => m.status !== 'InActive').filter(filter);
    return (
      <div>
        <Form.Group label="Select member">
          <Grid.Row gutters="xs">
            <Grid.Col>
              <Form.Select onChange={x => onSelect(x.target.value)}>
                <option selected={!selectedId} value={null}>
                  Select one
                </option>
                {members.map(member => (
                  <option selected={member.id === selectedId} value={member.id} key={member.id}>
                    {member.name}
                  </option>
                ))}
              </Form.Select>
            </Grid.Col>
            <Grid.Col auto className="align-self-center" />
          </Grid.Row>
        </Form.Group>
        <div style={{ minHeight: '100px' }}>
          {members
            .filter(m => m.id === selectedId)
            .map(member => (
              <GroupMemberCard key={member.id} member={member} />
            ))}
        </div>
      </div>
    );
  }

  render() {
    var { group, fromMemberId, toMemberId, updateLoading } = this.state;

    const mainForm = (
      <div>
        {group.members !== null && group.members.length > 0 ? (
          <Card.Body>
            <Grid.Row>
              <Grid.Col md={6}>
                <h3>Merge from</h3>
                {this.showMembers(
                  fromMemberId,
                  x => this.selectFrom(x),
                  f => f.status !== 'Active' && f.id !== toMemberId
                )}
              </Grid.Col>
              <Grid.Col md={6}>
                <h3>Merge to</h3>
                {this.showMembers(
                  toMemberId,
                  x => this.setState({ toMemberId: x }),
                  f => f.id !== fromMemberId
                )}
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col md={11} />
              <Grid.Col>
                <Button
                  className="float-right"
                  disabled={!fromMemberId || !toMemberId}
                  loading={updateLoading}
                  onClick={() => this.onSave()}
                  color="primary"
                >
                  Merge
                </Button>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        ) : (
          <Card.Body>
            <Grid.Row>
              <Grid.Col>No Members found</Grid.Col>
            </Grid.Row>
          </Card.Body>
        )}
      </div>
    );

    return (
      <SiteWrapper>
        <Page.Content title={'Group ' + group.name}>
          <Card>
            {this.state.isLoading ? <Dimmer active loader /> : mainForm}
            {this.state.error && (
              <Card.Alert color="danger">
                <strong>Whoops!</strong> {this.state.error}
              </Card.Alert>
            )}
          </Card>
        </Page.Content>
      </SiteWrapper>
    );
  }
}
