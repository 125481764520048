import React from 'react';
import './App.css';
import {
  LoginPage,
  DashboardPage,
  SortCodesPage,
  CampaignsPage,
  TransactionInAppSettlementsPage,
  GroupsPage,
  PeachPaymentCallLogsPage,
  UsersPage,
  SortCodeUpdatePage,
  CampaignUpdatePage,
  TransactionInAppSettlementUpdatePage,
  TransactionRecordUpdatePage,
  GroupUpdatePage,
  UserUpdatePage,
  UserFeedPage,
  Error404,
  Error500,
  CallbackPage,
  LoggedOutPage,
  ReportsPage,
  ReportTablePage,
  GroupMemberMergePage,
  UserBalancePage,
  UsersBlockedPage
} from './pages/';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import AuthService from './core/AuthService';
import 'tabler-react/dist/Tabler.css';
import SettingsPage from './pages/settings/SettingsPage';

class App extends React.Component {
  _authService /*: AuthService */ = new AuthService();

  isLoggedIn() {
    return this._authService.isLoggedIn();
  }

  render() {
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Route
            exact
            path="/"
            render={routeProps => (this.isLoggedIn() ? <DashboardPage {...routeProps} /> : <Redirect to="/login" />)}
          />
          <Route
            exact
            path="/dashboard"
            render={routeProps => (this.isLoggedIn() ? <DashboardPage {...routeProps} /> : <Redirect to="/login" />)}
          />
          <Route
            path="/sortCodes/"
            render={routeProps => (this.isLoggedIn() ? <SortCodesPage {...routeProps} /> : <Redirect to="/login" />)}
          />
          <Route
            path="/sortCode/:id"
            render={routeProps =>
              this.isLoggedIn() ? <SortCodeUpdatePage {...routeProps} /> : <Redirect to="/login" />
            }
          />

          <Route
            path="/users/"
            render={routeProps => (this.isLoggedIn() ? <UsersPage {...routeProps} /> : <Redirect to="/login" />)}
          />
          <Route
            path="/usersblocked"
            render={routeProps => (this.isLoggedIn() ? <UsersBlockedPage {...routeProps} /> : <Redirect to="/login" />)}
          />
          <Route
            path="/user/:id/balance"
            render={routeProps => (this.isLoggedIn() ? <UserBalancePage {...routeProps} /> : <Redirect to="/login" />)}
          />
          <Route
            path="/user/:id/feed"
            render={routeProps => (this.isLoggedIn() ? <UserFeedPage {...routeProps} /> : <Redirect to="/login" />)}
          />
          <Route
            path="/user/:id"
            render={routeProps => (this.isLoggedIn() ? <UserUpdatePage {...routeProps} /> : <Redirect to="/login" />)}
          />
          <Route
            path="/peachPaymentCallLogs/"
            render={routeProps =>
              this.isLoggedIn() ? <PeachPaymentCallLogsPage {...routeProps} /> : <Redirect to="/login" />
            }
          />

          <Route
            path="/logout"
            render={routeProps =>
              this.isLoggedIn() ? <Redirect to="/dashboard" /> : <LoggedOutPage {...routeProps} />
            }
          />

          <Route
            path="/groups/"
            render={routeProps => (this.isLoggedIn() ? <GroupsPage {...routeProps} /> : <Redirect to="/login" />)}
          />
          <Route
            path="/group/:id/member-merge"
            render={routeProps =>
              this.isLoggedIn() ? <GroupMemberMergePage {...routeProps} /> : <Redirect to="/login" />
            }
          />
          <Route
            path="/group/:id"
            render={routeProps => (this.isLoggedIn() ? <GroupUpdatePage {...routeProps} /> : <Redirect to="/login" />)}
          />

          <Route
            path="/transactionRecord/:id"
            render={routeProps =>
              this.isLoggedIn() ? <TransactionRecordUpdatePage {...routeProps} /> : <Redirect to="/login" />
            }
          />
          <Route
            path="/transactionInAppSettlements/"
            render={routeProps =>
              this.isLoggedIn() ? <TransactionInAppSettlementsPage {...routeProps} /> : <Redirect to="/login" />
            }
          />
          <Route
            path="/transactionInAppSettlement/:id"
            render={routeProps =>
              this.isLoggedIn() ? <TransactionInAppSettlementUpdatePage {...routeProps} /> : <Redirect to="/login" />
            }
          />
          <Route
            path="/reports"
            render={routeProps => (this.isLoggedIn() ? <ReportsPage {...routeProps} /> : <Redirect to="/login" />)}
          />
          <Route
            path="/report/:name"
            render={routeProps => (this.isLoggedIn() ? <ReportTablePage {...routeProps} /> : <Redirect to="/login" />)}
          />
          <Route path="/callback" render={routeProps => <CallbackPage {...routeProps} />} />
          <Route path="/500" render={routeProps => <Error500 {...routeProps} />} />
          <Route
            path="/campaigns/"
            render={routeProps => (this.isLoggedIn() ? <CampaignsPage {...routeProps} /> : <Redirect to="/login" />)}
          />
          <Route
            path="/campaign/:id"
            render={routeProps =>
              this.isLoggedIn() ? <CampaignUpdatePage {...routeProps} /> : <Redirect to="/login" />
            }
          />
          <Route
            path="/settings/"
            render={routeProps => (this.isLoggedIn() ? <SettingsPage {...routeProps} /> : <Redirect to="/login" />)}
          />
          <Route component={Error404} />
        </Switch>
      </Router>
    );
  }
}

export default App;
