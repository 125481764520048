import React from 'react';
import { History } from 'history';
const PARAM_INDEX = 'i';
const PARAM_SEARCH = 's';

export class VisibilityIndexHolder {
  loadCount: number = 30;

  getData: () => any[];
  currentIndex: number;
  history: History;

  constructor(loadCount: number, currentData: () => any[], history: History) {
    this.loadCount = loadCount;
    this.getData = currentData;
    this.history = history;
    const params = new URLSearchParams(document.location.search);
    this.currentIndex = params.has(PARAM_INDEX) ? parseInt(params.get(PARAM_INDEX)) : this.loadCount;
  }
  getIndex(): number {
    return this.currentIndex;
  }

  resetIndex(): number {
    this.currentIndex = this.loadCount;
    this.storeIndex();
    return this.currentIndex;
  }

  hasMore(): boolean {
    return this.getData().length > this.currentIndex;
  }

  isVisible(visible: boolean): void {
    if (visible && this.hasMore()) {
      this.currentIndex = this.currentIndex + this.loadCount;
      this.storeIndex();
    }
  }

  storeIndex() {
    if (this.history) {
      this.history.push({ search: '?' + new URLSearchParams({ i: `${this.currentIndex}` }).toString() });
    }
  }
}

export class GraphQlPageHolder {
  loadCount: number;
  currentIndex: number;
  history: History;
  refreshCallBack: () => void;
  endCursor: string;
  lastSearch: any;
  hasNextPage: boolean;

  constructor(loadCount: number, history: History, refreshCallBack: () => void) {
    this.loadCount = loadCount;
    this.endCursor = null;
    this.hasNextPage = false;
    this.history = history;
    this.refreshCallBack = refreshCallBack;
    const params = new URLSearchParams(document.location.search);
    this.currentIndex = params.has(PARAM_INDEX) ? parseInt(params.get(PARAM_INDEX)) : this.loadCount;
    this.lastSearch = params.has(PARAM_SEARCH) ? JSON.parse(params.get(PARAM_SEARCH)) : '';
  }

  getIndex(): number {
    return this.currentIndex;
  }

  getPageOptions(otherOptions: any = {}): any {
    var options = otherOptions;
    if (this.lastSearch) {
      options = { ...this.lastSearch, ...this.cleanObject(options) };
    }
    this.lastSearch = options;
    this.updateParams();
    return { ...options, first: this.getIndex() };
  }

  cleanObject(objectIn: any): any {
    let obj = { ...objectIn };
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }

  setPageResult(query: any) {
    this.endCursor = query.endCursor;
    this.hasNextPage = query.hasNextPage;
  }

  isVisible(visible: boolean): void {
    if (visible && this.hasNextPage) {
      this.currentIndex = this.currentIndex + this.loadCount;
      this.hasNextPage = false;
      this.refreshCallBack();
    }
  }

  updateParams() {
    if (this.history) {
      const params = {};
      if (this.loadCount !== this.currentIndex) params[PARAM_INDEX] = `${this.currentIndex}`;
      var search = this.cleanObject(this.lastSearch);
      if (Object.keys(search).length > 0) params[PARAM_SEARCH] = JSON.stringify(search);
      if (Object.keys(params).length > 0)
        this.history.replace({ search: '?' + new URLSearchParams(params).toString() });
    }
  }
}

interface Props {
  offset?: number;
  isVisible(visible: boolean): void;
}

export default class OnVisible extends React.Component<Props> {
  component: HTMLSpanElement;
  currentState: boolean = false;
  timer: NodeJS.Timeout;
  visibilityCheck = 100;

  componentDidMount() {
    this.timer = setTimeout(() => this.checkVisible(), this.visibilityCheck * 20);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  checkVisible() {
    var inView = this.isInViewport();
    if (inView !== this.currentState) {
      this.currentState = inView;
      this.props.isVisible(inView);
    }
    this.timer = setTimeout(() => this.checkVisible(), this.visibilityCheck);
  }

  isInViewport(): boolean {
    let offset = this.props.offset || 20;
    if (!this.component) return false;
    const top = this.component.getBoundingClientRect().top;
    return top + offset >= 0 && top - offset <= window.innerHeight;
  }

  render() {
    return <span ref={el => (this.component = el)}></span>;
  }
}
