import * as React from 'react';
import { CSVLink } from 'react-csv';
import SiteWrapper from '../../components/SiteWrapper';
import { Page, Dimmer, Alert, Header, Form, Grid, Icon } from 'tabler-react';
import TransactionInAppSettlementsList from './TransactionInAppSettlementsList';
import gql from 'graphql-tag';
import ApiService from '../../core/ApiService';
import DateText from '../../components/DateText';
import Dialog from '../../components/Dialog';
import List from '../../components/List';
import ViewStateHandler, { Reducer } from '../../core/ViewStateHandler';

//#region Typings

interface RangeFilter {
  name: string;
  fromDate: Date;
  toDate: Date;
}

interface StateFilter {
  name: string;
  checked: boolean;
}

interface StateFilteOptions {
  showFailed: StateFilter;
  showPending: StateFilter;
  showCompleted: StateFilter;
  showRefunded: StateFilter;
}

interface PaymentsBatch {
  id: string;
  toUserId: string;
}

interface Payment {
  visible: boolean;
  data: Array<PaymentsBatch>;
  isLoading: boolean;
  message?: string;
  messageType: string;
}

interface RefundRequest {
  visible: boolean;
  data: Array<any>;
  isLoading: boolean;
  message?: string;
  messageType: string;
}

interface EftPaymentQuery {
  id: string;
  ppReferenceId: any;
  description: any;
  createDate: any;
  status: {
    code: any,
    description: any,
    state: any,
    lastChange: any,
    failedRiskChecks: any
  };
  amount: {
    cents: any,
    currencyIso: any,
    formatted: any
  };
  payor: {
    id: any,
    name: any,
    email: any,
    image: any
  };
  payee: {
    id: any,
    name: any,
    email: any,
    image: any
  };
  payorCardUsed: {
    last4Digits: any,
    brand: any,
    holder: any
  };
  bankDetail: {
    accountName: any,
    accountNumber: any,
    sortCode: any,
    sortCodeDetail: {
      description: any
    }
  };
  eftSent: {
    batchId: string,
    byUser: {
      id: string,
      name: string
    },
    ourReference: string,
    theirReference: string,
    date: any
  };
}

interface EftPaymentQueryViewModel extends EftPaymentQuery {
  isAllowedInBatch: boolean;
  isAllowedInRefundBatch: boolean;
  isInBatch: boolean;
  isInRefundBatch: boolean;
}

interface Props { }

interface State {
  transactionInAppSettlements: Array<EftPaymentQueryViewModel>;
  selectedRange?: RangeFilter;
  ranges: Array<RangeFilter>;
  isLoading: boolean;
  error: string;
  payments: Payment;
  filters: StateFilteOptions;
  refundRequest: RefundRequest;
}
//#endregion

//#region Grapqhl Queries/Mutations
const GET_TRANSACTIONINAPPSETTLEMENT = gql`
  query($filterFrom: DateTime, $filterTo: DateTime) {
    transactionInAppSettlements {
      eftPaymentQuery(filterFrom: $filterFrom, filterTo: $filterTo) {
        id
        ppReferenceId
        description
        createDate
        status {
          code
          description
          state
          lastChange
          failedRiskChecks
        }
        amount {
          cents
          currencyIso
          formatted
        }
        payor {
          id
          name
          email
          image
        }
        payee {
          id
          name
          email
          image
        }
        payorCardUsed {
          last4Digits
          brand
          holder
        }
        bankDetail {
          accountNumber
          accountName
          sortCode
          sortCodeDetail {
            description
          }
        }
        eftSent {
          batchId
          byUser {
            id
            name
          }
          ourReference
          theirReference
          date
        }
      }
    }
  }
`;

const EFT_BATCH = gql`
  mutation($batch: [BatchItem]) {
    transactionInAppSettlements {
      eftBatch(batch: $batch) {
        id
      }
    }
  }
`;

const REFRESH_PENDING = gql`
  mutation($id: String!) {
    transactionInAppSettlements {
      refreshPending(id: $id) {
        correlationId
        parameters {
          key
          value
        }
      }
    }
  }
`;

const REFUND_PAYMENT = gql`
  mutation($id: String!) {
    transactionInAppSettlements {
      refundPayment(id: $id) {
        correlationId
        parameters {
          key
          value
        }
      }
    }
  }
`;
//#endregion

function DateSet(add = 0) {
  let d = new Date();
  d.setDate(d.getDate() + add);
  d.setHours(16);
  d.setMinutes(0);
  d.setSeconds(0);
  return d;
}
class TransactionInAppSettlementsPage extends React.Component<Props, State> {
  apiService: ApiService;
  viewService: ViewStateHandler<State>;

  state = {
    transactionInAppSettlements: [],
    isLoading: true,
    ranges: [
      { name: 'Today', fromDate: DateSet(-1), toDate: DateSet() },
      { name: 'Yesterday', fromDate: DateSet(-2), toDate: DateSet(-1) },
      { name: '7 days', fromDate: DateSet(-7), toDate: new Date() },
      { name: '30 days', fromDate: DateSet(-30), toDate: new Date() },
      { name: 'All time', fromDate: new Date('0001-01-01T00:00:00Z'), toDate: new Date() }
    ],
    selectedRange: { name: 'Today', fromDate: DateSet(-1), toDate: DateSet() },
    filters: {
      showFailed: { name: 'Show failed', checked: false },
      showPending: { name: 'Show pending', checked: false },
      showCompleted: { name: 'Show completed', checked: true },
      showRefunded: { name: 'Show refunded', checked: false }
    },
    error: '',
    payments: {
      visible: false,
      data: [],
      isLoading: false,
      message: undefined,
      messageType: 'danger' // typescript literals can't infer here
    },
    refundRequest: {
      visible: false,
      data: [],
      isLoading: false,
      message: undefined,
      messageType: 'danger'
    }
  };

  constructor(props) {
    super(props);
    this.apiService = new ApiService();
    this.viewService = new ViewStateHandler<State>(this, new TransactionInAppSettlementsPageReducer());
  }

  componentDidMount() {
    this.loadViewModelAsync(this.state.selectedRange);
  }

  createViewModel(eftPaymentQueryCollection: Array<EftPaymentQuery>): Array<EftPaymentQueryViewModel> {
    const data = eftPaymentQueryCollection.map((element: EftPaymentQuery) => {
      const canBatchForPayment = element.status.state === 'SUCCESS' && !element.eftSent.batchId;
      const canBatchForRefund = element.status.state === 'SUCCESS';

      const batchItem: EftPaymentQueryViewModel = {
        ...element,
        isAllowedInBatch: canBatchForPayment,
        isAllowedInRefundBatch: canBatchForRefund,
        isInBatch: false,
        isInRefundBatch: false
      };

      return batchItem;
    });

    return data;
  }

  loadViewModelAsync(selectedRange: RangeFilter) {
    this.viewService.dispatch(PageActionNames.GET_TRANSACTIONSETTLEMENTS_BEGIN);

    return this.apiService
      .query(GET_TRANSACTIONINAPPSETTLEMENT, { filterFrom: selectedRange.fromDate, filterTo: selectedRange.toDate })
      .then(response => {
        this.viewService.dispatch(PageActionNames.GET_TRANSACTIONSETTLEMENTS_COMPLETE, {
          data: this.createViewModel(response.data.transactionInAppSettlements.eftPaymentQuery)
        });
      })
      .catch(err => {
        this.viewService.dispatch(PageActionNames.GET_TRANSACTIONSETTLEMENTS_FAILURE, {
          message: 'Failed to read transactionInAppSettlements from service.'
        });
      });
  }

  createPaymentsBatch(vm: Array<EftPaymentQueryViewModel>): Array<PaymentsBatch> {
    return vm
      .filter(x => x.isInBatch && x.isAllowedInBatch)
      .map(y => {
        return {
          id: y.id,
          toUserId: y.payee.id
        };
      });
  }

  requestPayment(transactionInAppSettlements: EftPaymentQueryViewModel[]) {
    const batch = this.createPaymentsBatch(transactionInAppSettlements);

    this.viewService.dispatch(PageActionNames.REQUEST_PAYMENT_SHOW, {
      data: batch
    });
  }

  completePayment(paymentsBatch: Array<PaymentsBatch>) {
    this.viewService.dispatch(PageActionNames.COMPLETE_PAYMENTS_BEGIN);

    this.apiService
      .mutate(EFT_BATCH, { batch: paymentsBatch })
      .then(response => {
        this.viewService.dispatch(PageActionNames.COMPLETE_PAYMENTS_SUCCESS, {
          message: 'Payment processed successfully'
        });

        window.setTimeout(() => this.closePayment(), 1500);
      })
      .catch(response =>
        this.viewService.dispatch(PageActionNames.COMPLETE_PAYMENTS_FAILURE, {
          message: 'Payment processing failed'
        })
      );
  }

  closePayment() {
    this.viewService.dispatch(PageActionNames.REQUEST_PAYMENT_HIDE);
    this.loadViewModelAsync(this.state.selectedRange);
  }

  getRefundRequestBatch(transactionInAppSettlements: Array<EftPaymentQueryViewModel>) {
    return transactionInAppSettlements
      .filter(x => x.isInRefundBatch && x.isAllowedInRefundBatch)
      .map(settlement => {
        return {
          id: settlement.id,
          ['short id']: `#${settlement.id.substr(0, 4)}`, // eslint-disable-line
          amount: settlement.amount.formatted,
          ['sort code']: settlement.bankDetail.sortCode, // eslint-disable-line
          account: settlement.bankDetail.accountNumber
        };
      });
  }

  requestRefund(data: EftPaymentQueryViewModel) {
    const vm = this.addRefundToBatch(data, this.state.transactionInAppSettlements);
    const batch = this.getRefundRequestBatch(vm);
    this.showRefundRequest(batch);
  }

  setRefundRequestVisibleState(visible: boolean) {
    return function (state: State, props?: Props) {
      return {
        ...state,
        refundRequest: {
          ...state.refundRequest,
          visible: visible
        }
      };
    };
  }

  showRefundRequest(data: Array<any>) {
    this.viewService.dispatch(PageActionNames.REQUEST_REFUND_SHOW, {
      data: data
    });
  }

  closeRefundRequest() {
    this.viewService.dispatch(PageActionNames.REQUEST_REFUND_HIDE);
    this.loadViewModelAsync(this.state.selectedRange);
  }

  completeRefundRequest(refund: any, state: State) {
    this.viewService.dispatch(PageActionNames.COMPLETE_REFUND_BEGIN);

    this.apiService
      .mutate(REFUND_PAYMENT, { id: refund.id })
      .then(response => {
        const remainingItems = state.refundRequest.data.filter(element => element.id !== refund.id);

        this.viewService.dispatch(PageActionNames.COMPLETE_REFUND_SUCCESS, {
          remainingItems,
          message: 'Refund processed successfully'
        });

        if (remainingItems.length === 0) {
          window.setTimeout(() => this.closeRefundRequest(), 1500);
        }
      })
      .catch(response =>
        this.viewService.dispatch(PageActionNames.COMPLETE_REFUND_FAILURE, {
          message: 'Failed to process refund'
        })
      );
  }

  addRefundToBatch(
    transactionInAppSettlement: EftPaymentQueryViewModel,
    transactionInAppSettlements: EftPaymentQueryViewModel[]
  ) {
    const data = transactionInAppSettlements;
    const index = data.findIndex(element => element.id === transactionInAppSettlement.id);
    const originalItem = data[index];

    const updatedItem: EftPaymentQueryViewModel = {
      ...originalItem,
      isInRefundBatch: true
    };

    const updatedVm = Object.assign([], data, { [index]: updatedItem });

    this.viewService.dispatch(PageActionNames.GET_TRANSACTIONSETTLEMENTS_COMPLETE, {
      data: updatedVm
    });

    return updatedVm;
  }

  toggle(
    transactionInAppSettlement: EftPaymentQueryViewModel,
    transactionInAppSettlements: EftPaymentQueryViewModel[]
  ) {
    const data = transactionInAppSettlements;
    const index = data.findIndex(element => element.id === transactionInAppSettlement.id);
    const originalItem = data[index];

    const updatedItem: EftPaymentQueryViewModel = {
      ...originalItem,
      isInBatch: !originalItem.isInBatch
    };

    const updatedVm = Object.assign([], data, { [index]: updatedItem });

    this.viewService.dispatch(PageActionNames.GET_TRANSACTIONSETTLEMENTS_COMPLETE, {
      data: updatedVm
    });
  }

  refreshOne(transactionInAppSettlement: EftPaymentQueryViewModel) {
    this.viewService.dispatch(PageActionNames.REFRESH_PENDING_BEGIN);

    this.apiService
      .mutate(REFRESH_PENDING, { id: transactionInAppSettlement.id })
      .then(response => {
        this.loadViewModelAsync(this.state.selectedRange);
        this.viewService.dispatch(PageActionNames.REFRESH_PENDING_SUCCESS, {
          data: response
        });
      })
      .catch(response =>
        this.viewService.dispatch(PageActionNames.REFRESH_PENDING_FAILURE, {
          message: 'failed to refresh pending item.'
        })
      );
  }

  filterAll(transactionInAppSettlement: EftPaymentQueryViewModel, filters: StateFilteOptions) {
    if (!filters.showCompleted.checked && transactionInAppSettlement.eftSent.batchId !== null) return false;

    return (
      (filters.showPending.checked || transactionInAppSettlement.status.state !== 'PENDING') &&
      (filters.showPending.checked || transactionInAppSettlement.status.state !== 'INITIALIZING') &&
      (filters.showFailed.checked || transactionInAppSettlement.status.state !== 'FAILED') &&
      (filters.showRefunded.checked || transactionInAppSettlement.status.state !== 'REFUNDED')
    );
  }

  findRangeFilterByName(name: string, rangeFilters: Array<RangeFilter>) {
    return rangeFilters.find(item => {
      return item.name === name;
    });
  }

  handleChange(event) {
    if (event.target.name === 'rangeFilter') {
      // date ranges
      var newRange = this.findRangeFilterByName(event.target.value, this.state.ranges);

      this.viewService.dispatch('SET_DATERANGE_FILTER', { selectedRangeFilter: newRange });
      this.loadViewModelAsync(newRange);
    } else if (event.target.name === event.target.value) {
      // filters

      const stateFilters = this.state.filters;
      const filterKeyName = event.target.name;
      const currentState = stateFilters[filterKeyName].checked;
      const newCheckedState = !currentState;

      this.viewService.dispatch('SET_STATUS_FILTER', { filterKeyName, newCheckedState });
    }
  }

  getCsvData(transactionInAppSettlements: Array<EftPaymentQueryViewModel>) {
    return transactionInAppSettlements.map(r => {
      const buildLink = id => window.location.origin + '/user/' + id;
      const result = {
        id: r.id,
        ppReferenceId: r.ppReferenceId,
        createDate: r.createDate,
        description: r.description,
        'status code': r.status.code,
        'status description': r.status.description,
        'status state': r.status.state,
        'status failed risk check': r.status.failedRiskChecks ? 'true' : 'false',
        'status lastChange': r.status.lastChange,
        'amount ': r.amount.cents / 100,
        'payor link': r.payor && buildLink(r.payor.id),
        'payor email': r.payor && r.payor.email,
        'payor card brand': r.payorCardUsed && r.payorCardUsed.brand,
        'payor card last digits': r.payorCardUsed && r.payorCardUsed.last4Digits,
        'payor name on card': r.payorCardUsed && r.payorCardUsed.holder,
        'payee link': r.payee && buildLink(r.payee.id),
        'payee email': r.payee && r.payee.email,
        'payee beneficiary name': r.payee && r.payee.id,
        currencyIso: r.amount.currencyIso,
        'bankDetail accountNumber': r.bankDetail && r.bankDetail.accountNumber,
        'bankDetail holder name': r.bankDetail && r.bankDetail.accountName,
        'bankDetail sortCode': r.bankDetail && r.bankDetail.sortCode,
        'bankDetail sortCodeDetail description':
          r.bankDetail && r.bankDetail.sortCodeDetail && r.bankDetail.sortCodeDetail.description,
        'eftSent batchId': r.eftSent && r.eftSent.batchId,
        'eftSent ourReference': r.eftSent && r.eftSent.ourReference,
        'eftSent theirReference': r.eftSent && r.eftSent.theirReference,
        'eftSent date': r.eftSent && r.eftSent.date
      };
      return result;
    });
  }

  getEftOutFile(transactionInAppSettlements: Array<EftPaymentQueryViewModel>) {
    return transactionInAppSettlements.map(r => {
      const result = {
        CustNo: r.payee?.id,
        Name: (r.bankDetail && r.bankDetail.accountName) || (r.payee && r.payee.name + '- USERNAME'),
        'Bank Account No': r.bankDetail && r.bankDetail.accountNumber,
        'Branch Code': r.bankDetail && r.bankDetail.sortCode,
        'Statement Reference': r.eftSent && r.eftSent.theirReference,
        'Amount ': r.amount.cents / 100
      };
      return result;
    });
  }

  getFilteredSettlementData(filters: StateFilteOptions, transactionInAppSettlements: EftPaymentQueryViewModel[]) {
    return transactionInAppSettlements.filter(y => this.filterAll(y, filters));
  }

  render() {
    const transactionInAppSettlements = this.getFilteredSettlementData(
      this.state.filters,
      this.state.transactionInAppSettlements
    );
    const csvData = this.getCsvData(transactionInAppSettlements);
    const eftOut = this.getEftOutFile(transactionInAppSettlements);

    return (
      <SiteWrapper>
        <Page.Content>
          <Header.H1>In app settlement</Header.H1>
          <div>
            <Grid.Row cards deck>
              <Grid.Col sm={12} lg={6}>
                {this.renderDateRanges()}
              </Grid.Col>
              <Grid.Col sm={12} lg={6}>
                {this.renderFilters()}
              </Grid.Col>
            </Grid.Row>
            <Grid.Row cards deck>
              <Grid.Col sm={6} lg={4}>
                <div>
                  <strong>From</strong>
                </div>
                <DateText date={this.state.selectedRange.fromDate} addTime={true} />
              </Grid.Col>
              <Grid.Col sm={6} lg={4}>
                <div>
                  <strong>To</strong>
                </div>
                <DateText date={this.state.selectedRange.toDate} addTime={true} />
              </Grid.Col>
            </Grid.Row>
          </div>
          {this.state.isLoading ? (
            <Dimmer active loader />
          ) : (
            <div style={{ marginTop: '15px' }}>
              <Grid.Row cards deck>
                <Grid.Col width={12}>{this.renderListOfSettlements(transactionInAppSettlements)}</Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={12}>
                  {transactionInAppSettlements.length > 0 ? (
                    this.renderCsvDownloadLink(csvData, 'eftbatch', 'Download', 'download', 'btn-outline-primary')
                  ) : (
                    <span />
                  )}
                  {transactionInAppSettlements.length > 0 ? (
                    this.renderCsvDownloadLink(eftOut, 'payout', 'Export Payout', 'radio', 'btn-outline-secondary')
                  ) : (
                    <span />
                  )}
                </Grid.Col>
              </Grid.Row>
            </div>
          )}
          {this.state.error && <Alert type="danger">{this.state.error}</Alert>}
        </Page.Content>
        {this.renderRequestRefundsDialog()}
        {this.renderPaymentsDialog()}
      </SiteWrapper>
    );
  }

  renderRequestRefundsDialog() {
    return (
      <Dialog
        visible={this.state.refundRequest.visible}
        title="Process Refunds"
        onCloseClick={() => this.closeRefundRequest()}
        isEnabled={!this.state.refundRequest.isLoading}
      >
        {this.state.refundRequest.message && (
          <Alert type={this.state.refundRequest.messageType || 'danger'}>{this.state.refundRequest.message}</Alert>
        )}
        <List
          data={this.state.refundRequest.data}
          onRowClick={(_evt, item) => this.completeRefundRequest(item, this.state)}
          isLoading={this.state.refundRequest.isLoading}
          emptyItemsMessage="All settled up. Give us a moment while we refresh."
        />
      </Dialog>
    );
  }

  renderPaymentsDialog() {
    return (
      <Dialog
        visible={this.state.payments.visible}
        title="Process Payments"
        onCloseClick={() => this.closePayment()}
        onConfirmClick={() => this.completePayment(this.state.payments.data)}
        ConfirmText="Pay"
        isEnabled={!this.state.payments.isLoading && this.state.payments.data.length > 0}
      >
        {this.state.payments.message && (
          <Alert type={this.state.payments.messageType || 'danger'}>{this.state.payments.message}</Alert>
        )}
        <h3>
          {this.state.payments.data.length > 0
            ? `Are you sure you want to process ${this.state.payments.data.length} payments?`
            : 'All settled up. Give us a moment while we refresh.'}
        </h3>
      </Dialog>
    );
  }

  renderCsvDownloadLink(csvData: any, fileName: string, buttonName: string, icon: string, className: string) {
    var newLocal: any = {
      year: 'numeric',
      month: 'numeric',
      day: '2-digit'
    };
    var dateFormatter = new Intl.DateTimeFormat('en-GB', newLocal);

    return (
      <CSVLink
        className={'float-right btn ' + className + '  btn-icon ml-sm'}
        data={csvData}
        style={{ marginLeft: 10 }}
        filename={fileName + '-' + dateFormatter.format(new Date()).replace(' ', '-') + '.csv'}
        target="_blank"
      >
        <Icon name={icon} />
        <span style={{ marginLeft: '5px' }}>{buttonName}</span>
      </CSVLink>
    );
  }

  renderDateRanges() {
    return (
      <Form.Group label="Range">
        <Form.SelectGroup name="rangeFilter">
          {this.state.ranges.map(r => (
            <Form.SelectGroupItem
              name="rangeFilter"
              key={r.name}
              label={r.name}
              value={r.name}
              checked={this.state.selectedRange.name === r.name}
              onChange={this.handleChange.bind(this)}
            />
          ))}
        </Form.SelectGroup>
      </Form.Group>
    );
  }

  renderFilters() {
    return (
      <Form.Group label="Filters">
        {Object.keys(this.state.filters).map(k => (
          <Form.Checkbox
            isInline
            label={this.state.filters[k].name}
            key={k}
            name={k}
            value={k}
            checked={this.state.filters[k].checked}
            onChange={this.handleChange.bind(this)}
          />
        ))}
      </Form.Group>
    );
  }

  renderListOfSettlements(transactionInAppSettlements: Array<any>) {
    return (
      <TransactionInAppSettlementsList
        transactionInAppSettlements={transactionInAppSettlements}
        toggle={m => this.toggle(m, this.state.transactionInAppSettlements)}
        refreshOne={m => this.refreshOne(m)}
        pay={() => this.requestPayment(this.state.transactionInAppSettlements)}
        requestRefund={settlement => this.requestRefund(settlement)}
      />
    );
  }
}

const PageActionNames = {
  GET_TRANSACTIONSETTLEMENTS_BEGIN: 'GET_TRANSACTIONSETTLEMENTS_BEGIN',
  GET_TRANSACTIONSETTLEMENTS_COMPLETE: 'GET_TRANSACTIONSETTLEMENTS_COMPLETE',
  GET_TRANSACTIONSETTLEMENTS_FAILURE: 'GET_TRANSACTIONSETTLEMENTS_FAILURE',

  REQUEST_PAYMENT_SHOW: 'REQUEST_PAYMENT_SHOW',
  REQUEST_PAYMENT_HIDE: 'REQUEST_PAYMENT_HIDE',

  COMPLETE_PAYMENTS_BEGIN: 'COMPLETE_PAYMENTS_BEGIN',
  COMPLETE_PAYMENTS_SUCCESS: 'COMPLETE_PAYMENTS_SUCCESS',
  COMPLETE_PAYMENTS_FAILURE: 'COMPLETE_PAYMENTS_FAILURE',

  REQUEST_REFUND_SHOW: 'REQUEST_REFUND_SHOW',
  REQUEST_REFUND_HIDE: 'REQUEST_REFUND_HIDE',

  COMPLETE_REFUND_BEGIN: 'COMPLETE_REFUND_BEGIN',
  COMPLETE_REFUND_SUCCESS: 'COMPLETE_REFUND_SUCCESS',
  COMPLETE_REFUND_FAILURE: 'COMPLETE_REFUND_FAILURE',

  REFRESH_PENDING_BEGIN: 'REFRESH_PENDING_BEGIN',
  REFRESH_PENDING_SUCCESS: 'REFRESH_PENDING_SUCCESS',
  REFRESH_PENDING_FAILURE: 'REFRESH_PENDING_FAILURE',

  SET_DATERANGE_FILTER: 'SET_DATERANGE_FILTER',
  SET_STATUS_FILTER: 'SET_STATUS_FILTER'
};

class TransactionInAppSettlementsPageReducer implements Reducer<State> {
  reduce(action, state: State): State {
    switch (action.type) {
      case PageActionNames.GET_TRANSACTIONSETTLEMENTS_BEGIN:
      case PageActionNames.REFRESH_PENDING_BEGIN:
        return {
          ...state,
          isLoading: true,
          error: ''
        };
      case PageActionNames.GET_TRANSACTIONSETTLEMENTS_COMPLETE:
        return {
          ...state,
          isLoading: false,
          transactionInAppSettlements: action.payload.data
        };

      case PageActionNames.GET_TRANSACTIONSETTLEMENTS_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload.message
        };
      case PageActionNames.REQUEST_PAYMENT_SHOW:
        return {
          ...state,
          payments: {
            ...state.payments,
            data: action.payload.data,
            visible: true
          }
        };
      case PageActionNames.REQUEST_PAYMENT_HIDE:
        return {
          ...state,
          payments: {
            ...state.payments,
            data: [],
            visible: false,
            message: ''
          }
        };
      case PageActionNames.COMPLETE_PAYMENTS_BEGIN:
        return {
          ...state,
          payments: {
            ...state.payments,
            isLoading: true
          }
        };
      case PageActionNames.COMPLETE_PAYMENTS_SUCCESS:
        return {
          ...state,
          payments: {
            ...state.payments,
            isLoading: false,
            data: [],
            message: action.payload.message,
            messageType: 'success'
          }
        };
      case PageActionNames.COMPLETE_PAYMENTS_FAILURE:
        return {
          ...state,
          payments: {
            ...state.payments,
            isLoading: false,
            message: action.payload.message,
            messageType: 'danger'
          }
        };
      case PageActionNames.REQUEST_REFUND_SHOW:
        return {
          ...state,
          refundRequest: {
            ...state.refundRequest,
            visible: true,
            data: action.payload.data
          }
        };
      case PageActionNames.REQUEST_REFUND_HIDE:
        return {
          ...state,
          refundRequest: {
            ...state.refundRequest,
            visible: false,
            isLoading: false,
            data: [],
            message: ''
          },
          filters: {
            ...state.filters,
            // eslint-disable-next-line
            ['showRefunded']: { ...state.filters['showRefunded'], checked: true }
          }
        };
      case PageActionNames.COMPLETE_REFUND_BEGIN:
        return {
          ...state,
          refundRequest: {
            ...state.refundRequest,
            isLoading: true
          }
        };
      case PageActionNames.COMPLETE_REFUND_SUCCESS:
        return {
          ...state,
          refundRequest: {
            ...state.refundRequest,
            isLoading: false,
            data: action.payload.remainingItems,
            message: action.payload.message,
            messageType: 'success'
          }
        };
      case PageActionNames.COMPLETE_REFUND_FAILURE:
        return {
          ...state,
          refundRequest: {
            ...state.refundRequest,
            isLoading: false,
            message: action.payload.message,
            messageType: 'danger'
          }
        };

      case PageActionNames.REFRESH_PENDING_SUCCESS:
        return {
          ...state,
          isLoading: false,
          error: ''
        };
      case PageActionNames.REFRESH_PENDING_FAILURE:
        return {
          ...state,
          isLoading: true,
          error: action.payload.message
        };
      case PageActionNames.SET_DATERANGE_FILTER:
        return {
          ...state,
          selectedRange: action.payload.selectedRangeFilter
        };
      case PageActionNames.SET_STATUS_FILTER:
        return {
          ...state,
          filters: {
            ...state.filters,
            [action.payload.filterKeyName]: {
              ...state.filters[action.payload.filterKeyName],
              checked: action.payload.newCheckedState
            }
          }
        };
      default:
        return state;
    }
  }
}

export default TransactionInAppSettlementsPage;
