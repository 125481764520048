import * as React from "react";

import { Container } from "tabler-react";
import { Page } from "tabler-react";
import { Header } from "tabler-react";
import { Icon } from "tabler-react";
import { Button } from "tabler-react";

export default (props: any) => {
  return (
    <Page className="text-center">
      <Container>
        <Header.H1 className="display-1 text-muted mb-5">Bye</Header.H1>
        <Header.H2>You have been successfully logged out.</Header.H2>
        <Header.H4 className="text-muted font-weight-normal mb-7">
          It is recommened to close all browser windows.
          </Header.H4>
        <Button onClick={() => window.location.href = "/"} className="btn-primary">
          <Icon className="mr-2" name="arrow-left" />
          Sign in again
        </Button>
      </Container>
    </Page>
  );
}
