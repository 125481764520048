import * as React from 'react';
import SiteWrapper from '../../components/SiteWrapper';
import { Page, Dimmer, Alert, Header } from 'tabler-react';
import PeachPaymentCallLogsList from './PeachPaymentCallLogsList';
import gql from '../../../node_modules/graphql-tag';
import ApiService from '../../core/ApiService';
import { PeachPaymentCallLog } from './PeachPaymentCallLogTypes';
import { ReactRouterProps } from '../../components/DefaultTypes';

interface Props extends ReactRouterProps {}

interface State {
  peachPaymentCallLogs: Array<any>;
  isLoading: boolean;
  error: string;
}

const GET_CALL_LOGS = gql`
  {
    peachPaymentCallLogs {
      query {
        items {
          id
          statusCode
          durationSeconds
          referenceId
          localPath
          result
          updateDate
        }
      }
    }
  }
`;

const DELETE_CALL_LOGS = gql`
  mutation peachPaymentCallLogsDelete($peachPaymentCallLogId: String!) {
    peachPaymentCallLogs {
      delete(id: $peachPaymentCallLogId) {
        id
      }
    }
  }
`;

class PeachPaymentCallLogsPage extends React.Component<Props, State> {
  apiService: ApiService;
  state = {
    peachPaymentCallLogs: [],
    isLoading: true,
    error: ''
  };
  queryResult: ZenObservable.Subscription;

  constructor(props) {
    super(props);
    this.apiService = new ApiService();
  }

  componentDidMount() {
    this.refreshData();
  }

  componentWillUnmount() {
    this.unSubscribeFromQuery();
  }

  unSubscribeFromQuery() {
    if (this.queryResult) {
      this.queryResult.unsubscribe();
      this.queryResult = null;
    }
  }

  refreshData() {
    this.unSubscribeFromQuery();
    this.queryResult = this.apiService.watchQuery(GET_CALL_LOGS).subscribe((response: any) => {
      if (response.errors) {
        this.setState({
          isLoading: false,
          error: 'Failed to read sortCodes from service.'
        });
      } else {
        if (response.data) {
          this.setState({
            peachPaymentCallLogs: response.data.peachPaymentCallLogs.query.items,
            isLoading: false,
            error: ''
          });
        }
      }
    });
  }

  add() {
    this.props.history.push('/peachPaymentCallLog/add');
  }

  update(peachPaymentCallLog: PeachPaymentCallLog) {
    this.props.history.push(`/peachPaymentCallLog/${peachPaymentCallLog.id}`);
  }

  remove(peachPaymentCallLog: PeachPaymentCallLog, callback: any) {
    this.apiService
      .mutate(DELETE_CALL_LOGS, { peachPaymentCallLogId: peachPaymentCallLog.id })
      .then(response => {
        this.refreshData();
        callback();
      })
      .catch(response =>
        this.setState({
          error: `Failed to remove peachPaymentCallLog '${peachPaymentCallLog.description}' from service.`
        })
      );
  }

  render() {
    var { peachPaymentCallLogs } = this.state;
    return (
      <SiteWrapper>
        <Page.Content>
          <Header.H1>Peach api call logs</Header.H1>
          {this.state.isLoading ? (
            <Dimmer active loader />
          ) : (
            <PeachPaymentCallLogsList
              peachPaymentCallLogs={peachPaymentCallLogs}
              update={(m, c) => this.update(m)}
              remove={(m, c) => this.remove(m, c)}
            />
          )}
          {this.state.error && <Alert type="danger">{this.state.error}</Alert>}
        </Page.Content>
      </SiteWrapper>
    );
  }
}

export default PeachPaymentCallLogsPage;
