// @flow
import ApolloClient, { ApolloQueryResult, ObservableQuery } from 'apollo-client';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import AppSettings from './AppSettings';
import { loadToken } from './AuthService';
import { getMainDefinition } from '../../node_modules/apollo-utilities';

class ErrorFetch extends Error {
  full: any;
}

export function jsonFetch(fetch: Promise<any>): Promise<any> {
  return fetch
    .then(response => {
      if (response.ok) {
        return response;
      } else {
        return response.json().then(errorMessage => {
          let error = new ErrorFetch(errorMessage.error);
          error.full = errorMessage;
          console.error(`Error in fetch: ${response.status} ${errorMessage.error} ${errorMessage.error_description}`);
          throw error;
        });
      }
    })
    .then(response => {
      return response.json();
    })
    .then(response => {
      return response;
    });
}

const httpLink = createHttpLink({
  uri: AppSettings.ApiEndPoint() + '/graphql'
});

const wsLink = () => {
  return new WebSocketLink({
    uri:
      AppSettings.ApiEndPoint()
        .replace('https://', 'wss://')
        .replace('http://', 'ws://') + '/graphql',
    options: {
      reconnect: true,
      connectionParams: {
        authToken: 'loadToken().access_token'
      }
    }
  });
};

const authLink = setContext((_, { headers }) => {
  let token = loadToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${String(token.access_token)}` : ''
    }
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all'
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
  }
};

const buildLink = () => {
  if (AppSettings.ApiEndPoint().indexOf('https') === 0) {
    console.log('Connect https only.');
    return authLink.concat(httpLink);
  }
  console.log('Connect to Websockets.');
  return split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation }: any = getMainDefinition(query);

      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink(),
    authLink.concat(httpLink)
  );
};

let _graphql: ApolloClient<NormalizedCacheObject> = null;

const loadClient = () =>
  new ApolloClient({
    link: buildLink(),
    cache: new InMemoryCache(),
    // @ts-ignore
    defaultOptions: defaultOptions
  });

class ApiService {
  lazyLoadClient(): ApolloClient<NormalizedCacheObject> {
    if (!_graphql) {
      _graphql = loadClient();
    }
    return _graphql;
  }

  query(query: any, variables?: any): Promise<ApolloQueryResult<any>> {
    return this.lazyLoadClient().query({
      query: query,
      variables: variables
    });
  }

  async queryWithCatch(query: any, variables?: any): Promise<ApolloQueryResult<any>> {
    const result = await this.lazyLoadClient().query({
      query: query,
      variables: variables
    });
    if (result.errors) {
      throw new Error(result.errors[0].message);
    }
    return result;
  }

  watchQuery(query: any, variables?: any): ObservableQuery<{}, any> {
    return this.lazyLoadClient().watchQuery({
      query: query,
      variables: variables
    });
  }

  mutate(mutation: any, variables: any) {
    return this.lazyLoadClient().mutate({
      mutation: mutation,
      variables: variables
    });
  }

  subscribe(subscribe: any, variables?: any) {
    return this.lazyLoadClient().subscribe({
      query: subscribe,
      variables: variables
    });
  }

  cleanErrorMessage(error: any) {
    if (error.networkError) {
      var err = error.networkError.result.errors.map(x => x.message);
      return err.find(() => true);
    }
    if (error.message !== null) return error.message;
  }
}

export default ApiService;
