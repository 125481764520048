import * as React from 'react';
import SiteWrapper from '../../components/SiteWrapper';
import { Page, Button, Dimmer, Alert, Header } from 'tabler-react';
import SortCodesList from './SortCodesList';
import gql from '../../../node_modules/graphql-tag';
import ApiService from '../../core/ApiService';
import { SortCode } from './SortCodeTypes';
import { ReactRouterProps } from '../../components/DefaultTypes';

interface Props extends ReactRouterProps {}

type State = {
  sortCodes: Array<any>,
  isLoading: boolean,
  error: string
};

const GET_SORTCODE = gql`
  {
    sortCodes {
      query {
        items {
          id
          code
          description
          updateDate
        }
      }
    }
  }
`;

const DELETE_SORTCODE = gql`
  mutation sortCodesDelete($sortCodeId: String!) {
    sortCodes {
      delete(id: $sortCodeId) {
        id
      }
    }
  }
`;

// const SUBSCRIPTION_SORTCODE = gql`
//   subscription {
//     sortCodeNotifications {
//       type
//       id
//     }
//   }
// `;

class SortCodesPage extends React.Component<Props, State> {
  apiService: ApiService;
  state = {
    sortCodes: [],
    isLoading: true,
    error: ''
  };
  queryResult: ZenObservable.Subscription;
  subscription: ZenObservable.Subscription;

  constructor(props) {
    super(props);
    this.apiService = new ApiService();
  }

  componentDidMount() {
    this.refreshData();
    // @ts-ignore
    this.subscription = this.subscribeToDataChanges();
  }

  componentWillUnmount() {
    // @ts-ignore
    this.subscription?.unsubscribe();
    this.unSubscribeFromQuery();
  }

  unSubscribeFromQuery() {
    if (this.queryResult) {
      this.queryResult.unsubscribe();
      this.queryResult = null;
    }
  }

  refreshData() {
    this.unSubscribeFromQuery();
    this.queryResult = this.apiService.watchQuery(GET_SORTCODE).subscribe((response: any) => {
      if (response.errors) {
        this.setState({
          isLoading: false,
          error: 'Failed to read sortCodes from service.'
        });
      } else {
        if (response.data) {
          this.setState({
            sortCodes: response.data.sortCodes.query.items,
            isLoading: false,
            error: ''
          });
        }
      }
    });
  }

  subscribeToDataChanges() {
    // return this.apiService.subscribe(SUBSCRIPTION_SORTCODE).subscribe(response => {
    //   if (response.errors) {
    //     console.error('error', response.errors);
    //   } else {
    //     if (!this.state.isLoading) this.refreshData();
    //   }
    // });
  }

  add() {
    this.props.history.push('/sortCode/add');
  }

  update(sortCode: SortCode) {
    this.props.history.push(`/sortCode/${sortCode.id}`);
  }

  remove(sortCode: SortCode, callback: any) {
    this.apiService
      .mutate(DELETE_SORTCODE, { sortCodeId: sortCode.id })
      .then(response => {
        this.refreshData();
        callback();
      })
      .catch(response =>
        this.setState({
          error: `Failed to remove sortCode '${sortCode.description}' from service.`
        })
      );
  }

  render() {
    var { sortCodes } = this.state;
    return (
      <SiteWrapper>
        <Page.Content>
          <Header.H1>
            Banks
            <span style={{ marginLeft: '15px' }}>
              {' '}
              <Button onClick={() => this.add()} color="secondary" icon="plus" />
            </span>
          </Header.H1>
          {this.state.isLoading ? (
            <Dimmer active loader />
          ) : (
            <SortCodesList
              sortCodes={sortCodes}
              update={(m, c) => this.update(m)}
              remove={(m, c) => this.remove(m, c)}
            />
          )}
          {this.state.error && <Alert type="danger">{this.state.error}</Alert>}
        </Page.Content>
      </SiteWrapper>
    );
  }
}

export default SortCodesPage;

/* scaffolding [
    {
      "FileName": "src\\App.tsx",
      "Indexline": "<Route component={Error404} />",
      "InsertAbove": true,
      "InsertInline": false,
      "Lines": [
        "<Route path=\"/sortCodes/\"  render={(routeProps) => ( this.isLoggedIn() ? (<SortCodesPage  {...routeProps}/>):(<Redirect to=\"/login\"/>) )}/>"
      ]
    },
    {
      "FileName": "src\\App.tsx",
      "Indexline": "SortCodesPage,",
      "InsertAbove": false,
      "InsertInline": false,
      "Lines": [
        "SortCodesPage,"
      ]
    },
    {
      "FileName": "pages\\index.js",
      "Indexline": "import SortCodesPage from",
      "InsertAbove": false,
      "InsertInline": false,
      "Lines": [
        "import SortCodesPage from \"./sortCodes/SortCodesPage\";"
      ]
    },
    {
      "FileName": "pages\\index.js",
      "Indexline": "SortCodesPage,",
      "InsertAbove": false,
      "InsertInline": false,
      "Lines": [
        "SortCodesPage,"
      ]
    },
    {
      "FileName": "SiteWrapper.tsx",
      "Indexline": "to: '/sortCodes'",
      "InsertAbove": false,
      "InsertInline": false,
      "Lines": [
        "{ value: 'SortCodes', to: '/sortCodes', LinkComponent: withRouter(NavLink) },"
      ]
    },
    {
      "FileName": "DashboardPage.tsx",
      "Indexline": "GET_COUNTS_SORTCODES",
      "InsertAbove": true,
      "InsertInline": false,
      "Lines": [
        "",
        "const GET_COUNTS_SORTCODES = `",
        " sortCodes { query { count } }",
        "`;"
      ]
    },
    {
      "FileName": "DashboardPage.tsx",
      "Indexline": "${this.token.hasAccess('ReadSortCode') ? GET_COUNTS_SORTCODES : ''}",
      "InsertAbove": true,
      "InsertInline": false,
      "Lines": [
        "${this.token.hasAccess('ReadSortCode') ? GET_COUNTS_SORTCODES : ''}"
      ]
    },
    {
      "FileName": "DashboardPage.tsx",
      "Indexline": "sortCodesCount: response.data.sortCodes ? response.data.sortCodes.query.count : 0,",
      "InsertAbove": true,
      "InsertInline": false,
      "Lines": [
        "sortCodesCount: response.data.sortCodes ? response.data.sortCodes.query.count : 0,"
      ]
    },
    {
      "FileName": "DashboardPage.tsx",
      "Indexline": "sortCodesCount: number;",
      "InsertAbove": false,
      "InsertInline": false,
      "Lines": [
        "sortCodesCount: number;"
      ]
    },
    {
      "FileName": "DashboardPage.tsx",
      "Indexline": "sortCodesCount: 0,",
      "InsertAbove": false,
      "InsertInline": false,
      "Lines": [
        "sortCodesCount: 0,"
      ]
    },
    {
      "FileName": "DashboardPage.tsx",
      "Indexline": "{this.token.hasAccess('ReadUsers') && (",
      "InsertAbove": true,
      "InsertInline": false,
      "Lines": [
        "{this.token.hasAccess(\"ReadSortCode\") &&",
        "(<Grid.Col sm={6} lg={3}>",
        "  <StampCard color=\"blue\" icon=\"dollar-sign\"",
        "    header={",
        "      <a href=\"/sortCodes\" >",
        "        {this.state.sortCodesCount} <small>SortCodes</small>",
        "      </a>",
        "    }",
        "  />",
        "</Grid.Col>)",
        "}",
      ]
    },
] scaffolding */
