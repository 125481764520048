import React from 'react';
import { Page, Card, Dimmer, Grid } from 'tabler-react';
import SiteWrapper from '../../components/SiteWrapper';
import { ReactRouterProps } from '../../components/DefaultTypes';
import ApiService from '../../core/ApiService';
import gql from 'graphql-tag';
import BalanceSummaryCard from './BalanceSummaryCard';
import { BalanceSummary } from './BalanceSummaryCard';
import UserAvatar, { UserAvatarFormat } from '../../components/UserAvatar';

const GET_BALANCE = gql`
  fragment amountData on Amount {
    cents
    currencyIso
    formatted
  }
  fragment userBalanceData on UserCurrencyBalancesSummary {
    balances {
      currencyIso
      summary {
        balance {
          ...amountData
        }
        totalOwe {
          ...amountData
        }
        totalIAmOwed {
          ...amountData
        }
      }
    }
    connectionBalances {
      userId
      connectionId
      name
      image
      status
      updateDate
      balances {
        currencyIso
        summary {
          balance {
            ...amountData
          }
          totalOwe {
            ...amountData
          }
          totalIAmOwed {
            ...amountData
          }
        }
      }
      groups {
        group {
          id
          name
          image
        }
        member {
          id
          name
          image
        }
        myMemberId
        balanceSummary {
          balance {
            ...amountData
          }
          totalOwe {
            ...amountData
          }
          totalIAmOwed {
            ...amountData
          }
        }
      }
    }
  }
  query getBalance($userId: String!) {
    balances {
      byUserId(userId: $userId) {
        ...userBalanceData
      }
    }
  }
`;

interface Props extends ReactRouterProps {}

type State = {
  balanceData: UserBalance,
  isLoading: boolean,
  error: string
};

export default class UserBalancePage extends React.Component<Props, State> {
  apiService: ApiService;
  state = {
    balanceData: null,
    isLoading: true,
    error: ''
  };

  constructor(props) {
    super(props);
    this.apiService = new ApiService();
  }

  componentDidMount() {
    var routeId = this.props.match.params.id;
    this.loadBalances(routeId);
  }

  loadBalances(id: string) {
    this.setState({ isLoading: true });
    this.apiService
      .query(GET_BALANCE, { userId: id })
      .then(response => {
        this.setState({
          balanceData: response.data.balances.byUserId,
          error: response.data.balances === null ? `Failed could not load balances with id '${id}'.` : '',
          isLoading: false
        });
      })
      .catch(() =>
        this.setState({
          isLoading: false,
          error: `Failed could not load balances with id '${id}'.`
        })
      );
  }

  render() {
    return (
      <SiteWrapper>
        <Page.Content title="User Balances">
          {this.state.isLoading && this.state.balanceData == null ? (
            <Grid.Row>
              <Grid.Col md={8}>
                <Card>
                  <Card.Body>
                    <Dimmer active loader />
                  </Card.Body>
                </Card>
              </Grid.Col>
            </Grid.Row>
          ) : (
            <>
              <Grid.Row>{this.renderTotals(this.state.balanceData.balances)}</Grid.Row>
              {this.renderConnections(this.state.balanceData)}
            </>
          )}
          {/* {this.state.balanceData &&
            this.state.balanceData.connectionBalances.map(connectionBalance => (
              <React.Fragment>
                <Grid.Row>
                  <Grid.Col md={8}>
                    <Card>
                      <Card.Body>
                        <BalanceSummaryCard
                          balanceSummary={connectionBalance.groupBalanceSummary}
                          title={connectionBalance.name}
                          image={connectionBalance.image}
                          url={`/user/${connectionBalance.id}`}
                        />
                      </Card.Body>
                    </Card>
                  </Grid.Col>
                  <Grid.Col md={4}>
                    <Card>
                      <Card.Body>
                        <BalanceSummaryCard
                          balanceSummary={connectionBalance.adHocBalanceSummary}
                          title={'Adhoc Balance'}
                          excludeImage={true}
                          url={`/user/${connectionBalance.id}`}
                        />
                      </Card.Body>
                    </Card>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  {connectionBalance.groupBalances.map(connectionGroupBalance => (
                    <Grid.Col key={connectionBalance} md={12} lg={6}>
                      {' '}
                      <Card>
                        <Card.Body>
                          <BalanceSummaryCard
                            balanceSummary={connectionGroupBalance.balanceSummary}
                            title={connectionGroupBalance.group.name}
                            image={connectionGroupBalance.group.image}
                            url={`/group/${connectionGroupBalance.group.id}`}
                          />
                        </Card.Body>
                      </Card>
                    </Grid.Col>
                  ))}
                </Grid.Row>
              </React.Fragment>
            ))} */}
          {this.state.error && (
            <Card.Alert color="danger">
              <strong>Whoops!</strong> {this.state.error}
            </Card.Alert>
          )}
        </Page.Content>
      </SiteWrapper>
    );
  }
  renderTotals(balances: BalancePerCurrency[]) {
    return balances.map((balance, i) => (
      <Grid.Col md={4} key={i}>
        <Card>
          <Card.Body>
            <BalanceSummaryCard balanceSummary={balance.summary} title={balance.currencyIso} excludeImage={true} />
          </Card.Body>
        </Card>
      </Grid.Col>
    ));
  }
  renderConnections(balance: UserBalance) {
    return balance.connectionBalances.map((connection, i) => (
      <>
        <Grid.Row>
          <Grid.Col md={12} key={i}>
            <div>
              <UserAvatar member={connection} format={UserAvatarFormat.ImageOnly}>
                <h2>{connection.name}</h2>
              </UserAvatar>
            </div>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          {connection.balances.map((balance, i) => (
            <Grid.Col md={4} key={i}>
              <Card>
                <Card.Body>
                  <BalanceSummaryCard
                    balanceSummary={balance.summary}
                    title={balance.currencyIso}
                    excludeImage={true}
                  />
                </Card.Body>
              </Card>
            </Grid.Col>
          ))}
        </Grid.Row>
        <Grid.Row>
          {connection.groups.map((group, i) => (
            <Grid.Col md={6} key={i}>
              <Card>
                <Card.Body>
                  <BalanceSummaryCard
                    balanceSummary={group.balanceSummary}
                    title={group.group.name}
                    image={group.group.image}
                  />
                </Card.Body>
              </Card>
            </Grid.Col>
          ))}
        </Grid.Row>
      </>
    ));
    //return ;
  }
}

export interface BalancePerCurrency {
  currencyIso: string;
  summary: BalanceSummary;
}

export interface Group {
  id: string;
  name: string;
  image: string;
}

export interface Member {
  id: string;
  name: string;
  image: string;
}

export interface GroupData {
  group: Group;
  member: Member;
  myMemberId: string;
  balanceSummary: BalanceSummary;
}

export interface ConnectionBalance {
  userId: string;
  connectionId: string;
  name: string;
  image: string;
  status: string;
  updateDate: Date;
  balances: BalancePerCurrency[];
  groups: GroupData[];
}

export interface UserBalance {
  balances: BalancePerCurrency[];
  connectionBalances: ConnectionBalance[];
}
