import * as React from 'react';
import { Card, Grid } from 'tabler-react';
import AmountText from '../../components/AmountText';
import UserAvatar, { UserAvatarFormat } from '../../components/UserAvatar';

interface Props {
  members: Array<any>;
  balance: any;
}

type State = {};

export default class GroupMemberToMemberBalance extends React.Component<Props, State> {
  state = {};

  renderMember(memberId) {
    var { members } = this.props;
    var member =
      members.filter(item => {
        return item.id === memberId;
      })[0] || null;
    return (
      <span className="text-center ">
        <UserAvatar member={member} format={UserAvatarFormat.ImageOnly} />
        <div>{member.name}</div>
      </span>
    );
  }
  render() {
    var { balance } = this.props;

    return (
      <Card>
        <Card.Body>
          <ul className="list-unstyled list-separated">
            <li className="list-separated-item">
              <Grid.Row>
                <Grid.Col>{this.renderMember(balance.fromMemberId)}</Grid.Col>
                <Grid.Col className="align-items-center text-center">
                  <div>
                    <AmountText amount={balance.amount} />
                  </div>
                  <div>Owes to</div>
                </Grid.Col>
                <Grid.Col className="align-items-right">{this.renderMember(balance.toMemberId)}</Grid.Col>
              </Grid.Row>
            </li>
          </ul>
        </Card.Body>
      </Card>
    );
  }
}
