import React from 'react';
import CurrencyService from '../core/CurrencyService';

export interface Amount {
  cents: number;
  currencyIso: string;
  formatted?: string;
}

interface Props {
  amount: Amount;
  invert?: boolean;
}

export default class AmountText extends React.Component<Props> {
  pad(cents: number) {
    var str = '' + cents;
    var pad = '00';
    return pad.substring(0, pad.length - str.length) + str;
  }
  render() {
    if (!this.props.amount) return <span />;
    var { cents, currencyIso } = this.props.amount;
    if (this.props.invert) {
      cents = cents * -1;
    }
    var symbol = CurrencyService.getInstance().getSymbol(currencyIso);
    return (
      <span className="amount">
        <span className="amount_currency">{symbol}</span>
        <span className="amount_amount">{Math.floor(Math.abs(cents) / 100) * (cents > 0 ? 1 : -1)}</span>
        <span className="amount_cents">{this.pad(Math.abs(cents % 100))}</span>
      </span>
    );
  }
}
