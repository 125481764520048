import React from 'react';
import TimeAgo from 'react-timeago';
import { Route } from 'react-router-dom';
import { Grid, Card, Text, Table, Form } from 'tabler-react';
import EditDelete from '../../components/EditDelete';
import { User } from './UserTypes';
import ShortId from '../../components/ShortId';
import UserAvatar from '../../components/UserAvatar';
import truncateWithEllipses from '../../components/TruncateWithEllipses';

type OnFilterChange = (msg: string) => void;

interface Props {
  users: User[];
  history?: any;
  onFilterChange?: OnFilterChange;

  update(user: User, callback?: any): void;
  remove(user: User, callback: any): void;
}

interface State {
  filterStr: string;
}

export default class UsersList extends React.Component<Props, State> {
  bufferTimeout: NodeJS.Timeout;
  constructor(props) {
    super(props);
    this.state = {
      filterStr: ''
    };
  }

  setFilter(searchString: string) {
    const hasChange = this.props.onFilterChange !== null;
    if (this.bufferTimeout) clearTimeout(this.bufferTimeout);
    this.bufferTimeout = setTimeout(() => {
      if (hasChange) {
        this.props.onFilterChange(searchString.trim());
      } else {
        this.setState({ filterStr: searchString });
      }
    }, 500);
  }

  applyFilter(item) {
    const filter = this.state.filterStr.toLowerCase();
    return (
      item.name.toLowerCase().indexOf(filter) >= 0 ||
      item.email.toLowerCase().indexOf(filter) >= 0 ||
      item.id.toLowerCase().indexOf(filter) >= 0
    );
  }

  render() {
    const { users } = this.props;

    return (
      <Grid.Row cards deck>
        <Grid.Col width={12}>
          <Form.Group>
            <Form.InputGroup>
              <Form.Input placeholder="Search for..." onChange={e => this.setFilter(e.target.value)} />
            </Form.InputGroup>
          </Form.Group>
          <Card>
            <Table responsive highlightRowOnHover hasOutline verticalAlign="center" cards className="text-nowrap">
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader className="w-1">Id</Table.ColHeader>
                  <Table.ColHeader className="w-1" />
                  <Table.ColHeader>Name</Table.ColHeader>
                  <Table.ColHeader>Email</Table.ColHeader>
                  <Table.ColHeader>Roles</Table.ColHeader>
                  <Table.ColHeader>Updated</Table.ColHeader>
                  <Table.ColHeader alignContent="center" className="w-1" />
                </Table.Row>
              </Table.Header>
              <Route
                render={({ history }) => (
                  <Table.Body>
                    {users
                      .filter(el => this.applyFilter(el))
                      .map(user => (
                        <Table.Row key={user.id}>
                          <Table.Col>
                            <Text.Small muted>
                              <ShortId id={user.id} />
                            </Text.Small>
                          </Table.Col>
                          <Table.Col>
                            <UserAvatar user={user} format={1} />
                          </Table.Col>
                          <Table.Col>{truncateWithEllipses(user.name, 25)}</Table.Col>
                          <Table.Col>{truncateWithEllipses(user.email, 30)}</Table.Col>
                          <Table.Col>
                            {user.roles.map(role => (
                              <span key={user.id + ' ' + role}>
                                <span className={'status-icon ' + (role === 'Admin' ? 'bg-success' : 'bg-warning')} />
                                {role}
                              </span>
                            ))}
                          </Table.Col>
                          <Table.Col>
                            <TimeAgo date={user.updateDate} />
                          </Table.Col>
                          <Table.Col alignContent="center">
                            <EditDelete model={user} update={this.props.update} remove={this.props.remove} />
                          </Table.Col>
                        </Table.Row>
                      ))}
                  </Table.Body>
                )}
              />
            </Table>
          </Card>
        </Grid.Col>
      </Grid.Row>
    );
  }
}
